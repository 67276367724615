<template>
  <v-container>
    <v-dialog persistent max-width="600" min-width="360" v-model="modalUpdate">
      <v-card class="px-4">
        <v-card-text>
          <div class="text-end pt-3">
            <v-btn text @click="closeModal()"><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <v-form ref="registerForm">
            <div>
              <p class="display-1 mb-10 text-center">Modificar perfil</p>
            </div>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  class="mb-n3"
                  filled
                  rounded
                  single-line
                  v-model="formEditar.name"
                  :rules="[rules.required]"
                  label="Nombre"
                  maxlength="50"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="mb-n3"
                  filled
                  rounded
                  single-line
                  v-model="formEditar.email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="mb-n3"
                  filled
                  rounded
                  single-line
                  v-model="formEditar.phone_number"
                  counter="12"
                  label="Numero Telefonico"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  filled
                  rounded
                  single-line
                  chips
                  multiple
                  item-text="name"
                  item-value="id"
                  label="Especialidad"
                  :items="especialidad"
                  v-model="formSpeciality.speciality"
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="formEditar.roles"
                  :items="items"
                  filled
                  required
                  rounded
                  single-line
                  chips
                  item-text="name"
                  item-value="id"
                  label="Rol"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  filled
                  rounded
                  single-line
                  v-model="formEditar.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Contraseña"
                  hint="At least 8 characters"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-snackbar :color="color" v-model="msn">
                  <p class="text-center mt-2 subtitle-1">{{ message }}</p>
                </v-snackbar>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="mx-auto" cols="12" sm="3" xsm="12">
                <v-btn
                  x-large
                  block
                  rounded
                  :disabled="!valid"
                  text
                  style="color: #81b322; border: solid 1px #81b322"
                  @click="update()"
                  >Modificar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  updateUser,
  speciality,
  AddSpeciality,
  rol,
} from "../../../../helpers/api/calls";
export default {
  data() {
    return {
      id: null,
      msn: false,
      message: null,
      color: null,
      editar: false,

      valid: true,
      show1: false,
      items: [],
      especialidad: [],
      formSpeciality: {
        speciality: null,
      },

      formEditar: {
        roles: null,
        name: null,
        email: null,
        password: null,
        phone_number: null,
        company_id:null,
        // verify: "",
      },

      emailRules: [
        (v) => !!v || "Requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
      ],
      rules: {
        required: (value) => !!value || "Requerido.",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
      },
    };
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Contraseña debe coincidir";
    },
    ...mapState("CrudCuentas", ["modalUpdate", "datosUpdate"]),
  },
  watch: {
    datosUpdate(newValue, oldValue) {
      // console.log("watch", newValue);
      if (newValue) {
        this.id = newValue.id;
        this.formSpeciality = {
          speciality: newValue.specialities,
        };

        this.formEditar = {
          roles: newValue.roles[0],
          name: newValue.name,
          email: newValue.email,
          password: null,
          phone_number: newValue.phone_number,
          company_id: newValue.company_id
        };
      }
    },
  },
  mounted() {
    this.speciality();
    this.listrol();
  },
  methods: {
    ...mapActions("CrudCuentas", ["pushUsers"]),
    closeModal() {
      this.$store.commit("CrudCuentas/setModalUpdate", false);
    },

    async listrol() {
      const roles = await rol();
      this.items = roles;
      // console.log("Roles: ",this.items);
    },
    async speciality() {
      const espe = await speciality();
      this.especialidad = espe.data;
      // console.log("especialidades:", espe);
    },
    async update() {
      updateUser({
        _method: "PATCH",
        id: this.id,
        name: this.formEditar.name,
        email: this.formEditar.email,
        password: this.formEditar.password,
        phone_number: this.formEditar.phone_number,
        roles: this.formEditar.roles,
        company_id: this.formEditar.company_id
      })
        .then((resp) => {
          if (resp) {
            this.msn = true;
            this.message = "Perfil modificado, exitosamente";
            this.color = "green";
            this.AddSpeciality();

            setTimeout(() => {
              this.msn = false;
              this.pushUsers();
              this.$refs.registerForm.reset()
              this.closeModal();
            }, 4000);
          }
        })
        .catch(function (err) {
          console.log("No Funciono", err);
        });
    },

    async AddSpeciality() {
      const data = this.formSpeciality;
      console.log(data)
      AddSpeciality({ id: this.id, data })
        .then((resp) => {
          console.log("funciono addSpeciality", resp);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
};
</script>

<style></style>
