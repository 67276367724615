<template>
  <v-container>
    <v-dialog persistent width="800" v-model="this.$store.state.modalVideos">
      <v-container style="background: white">
        <div>
          <v-btn color="#3364d0" icon @click="closeModalVideo()">
            <v-icon size="30"> mdi-close </v-icon>
          </v-btn>
        </div>
        <div v-if="opciones == null">
          <p style="color: #3364d0" class="display-1 text-center">
            ¿Que video desea reproducir?
          </p>
          <v-row class="mt-10">
            <v-col class="text-center" cols="6">
              <div v-if="videoCliente !== null">
                <v-btn @click="opciones = 2" elevation="4" large icon>
                  <v-icon color="#3364d0" size="40"> mdi-account-tie </v-icon>
                </v-btn>
                <p style="color: #3364d0" class="title mt-2">Operador</p>
              </div>
              <v-container v-else class="text-center">
                <div>
                  <v-icon size="70">mdi-emoticon-sad-outline</v-icon>
                  <p style="color: #757575" class="title">
                    Lo sentimos, no es posible reproducir la grabación del
                    Operador.
                  </p>
                </div>
              </v-container>
            </v-col>
            <v-col cols="6" class="text-center">
              <div v-if="videoOperador != null">
                <v-btn @click="opciones = 1" elevation="4" large icon>
                  <v-icon color="#3364d0" size="40"> mdi-account </v-icon>
                </v-btn>
                <p class="mt-2 title" style="color: #3364d0">Cliente</p>
              </div>
              <v-container v-else class="text-center">
                <div>
                  <v-icon size="70">mdi-emoticon-sad-outline</v-icon>
                  <p style="color: #757575" class="title">
                    Lo sentimos, no es posible reproducir la grabación del
                    Cliente.
                  </p>
                </div>
              </v-container>
            </v-col>
          </v-row>
          <!-- <div
            v-if="videoCliente == null && videoOperador == null"
            class="text-center"
          >
            <v-icon size="70">mdi-emoticon-sad-outline</v-icon>
            <p style="color: #757575" class="title">
              Lo sentimos, no es posible reproducir las grabaciones.
            </p>
          </div> -->
        </div>

        <div class="mb-10" v-if="opciones == 1">
          <p style="color: #3364d0" class="display-1 text-center">
            Grabacion del cliente
          </p>
          <div class="rounded-lg divVideo">
            <my-video ref="videoCliente" :sources="videoOperador.sources" :options="videoOperador.options"></my-video>
          </div>
        </div>

        <div class="mb-10" v-if="opciones == 2">
          <p style="color: #3364d0" class="display-1 text-center">
            Grabacion del Operador
          </p>
          <div class="rounded-lg divVideo">
            <my-video ref="videoOperador" :sources="videoCliente.sources" :options="videoCliente.options"></my-video>
          </div>
        </div>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import myVideo from "vue-video";
export default {
  props: {
    videoUrl: {
      type: Array,
      default: null,
    },
  },
  components: {
    myVideo,
  },
  data() {
    return {
      opciones: null,
      informationVideo: null,
      videoCliente: null,
      videoOperador: null,
      video: null,
    };
  },
  watch: {
    "$store.state.srcVideoRecording"(newValue) {
			this.videoCliente = null
			this.videoOperador = null
      this.video = true;
      const a = newValue.find((a) => a.recording_side_id == 2);
      console.log("a", a);

      const b = newValue.find((b) => b.recording_side_id == 1);
      console.log("b", b);
      if (a && a.url) {
        this.videoCliente = {
          sources: [
            {
              src: a.url,
              type: "video/webm",
            },
          ],
          options: {
            autoplay: false,
            volume: 0.4,
          },
        };
			}
      if (b && b.url) {
        console.log("this.videoCliente", this.videoCliente);
        this.videoOperador = {
          sources: [
            {
              src: b.url,
              type: "video/webm",
            },
          ],
          options: {
            autoplay: false,
            volume: 0.4,
          },
        };
        console.log("this.videoCliente", this.videoOperador);
      }
    },
  },
  methods: {
    closeModalVideo() {
      this.$store.commit("setModalVideos", false);
      this.opciones = null;
      if (this.video != null) {
        console.log("Video esta en null");
        // this.$refs.videoCliente.stop();
        // this.$refs.videoOperador.stop();
        (this.videoCliente = null), (this.videoOperador = null);
      }
    },
  },
};
</script>

<style>
</style>