import Vue from "vue";
import Vuex from "vuex";
import { getVideoEmail, userData, limit } from "../helpers/api/calls";
import dashboard from "./Dashboard/dashboard";
import CrudCuentas from "./CrudCuentas/CrudCuentas";
import vigilancia from "./Vigilancia/vigilancia";
import payPlan from "@/store/payPlan/payPlan";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cardWallet: false,
    cardState: null,
    cardFormState: false,
    cardFormStateVideoCall: false,
    cardAlertModal: false,
    infoUserEmail: "",
    urlVideoEmail: null,
    cardBandeja: 1,
    InfoListMail: [],
    idRoomForm: null,
    userData: null,
    newUserData: null,
    infoAtenciones: null,
    countMes: null,
    countRecibidoMes: null,
    dateModalCallback: null,
    dateMensaje: null,

    //Administracion
    modalAddEsp: false,
    // modalDeleteEsp:false,

    //Cuadros dashboard
    modalHoy: false,
    modalMes: false,
    modalEmailRecibido: false,
    modalEmailDerivado: false,
    modalWhatsapp: false,
    modalContac: false,
    modalCallbackContact: false,
    modalMensaje: false,
    //----------

    //Administracion
    getCompanyInfo: null,
    //-----------

    //Grabaciones
    modalVideos: false,
    srcVideoRecording: null,
    //------

    //Limit
    infoLimit: [],
    checks: [
      "available_callbacks_time",
      "available_callbacks_data",
      "available_videocalls_time",
      "available_videocalls_data",
      "available_videomails_data",
      "available_whatsapp_messages",
    ],
    modalAviso: false,

    /** Payments */
    paymentWidget: false,
    paymentItems: [],
  },
  mutations: {
    setDateMensaje(state, val) {
      state.dateMensaje = val;
      console.log(val);
    },
    setModalMensaje(state, val) {
      state.modalMensaje = val;
    },
    setDateModalCallback(state, val) {
      state.dateModalCallback = val;
      // console.log("setDate",val)
    },
    setModalCallbackContact(state, status) {
      state.modalCallbackContact = status;
    },
    setModalContac(state, status) {
      state.modalContac = status;
      // console.log(status)
    },
    setModalAddEsp(state, status) {
      state.modalAddEsp = status;
    },
    setModalWhatsapp(state, val) {
      state.modalWhatsapp = val;
      // console.log(val)
    },
    setNewUserData(state, val) {
      state.newUserData = val;
      // console.log("newUserData",val)
    },
    setPaymentItems(state, value) {
      state.paymentItems.push(value);
    },
    setPaymentWidget(state, value) {
      state.paymentWidget = value;
    },
    setModalAviso(state, status) {
      state.modalAviso = status;
      // console.log(status);
    },
    setInfoLimit(state, val) {
      state.infoLimit = val;
      // console.log(val);
    },
    setSrcVideoRecording(state, val) {
      state.srcVideoRecording = val;
      console.log(val);
    },
    setModalVideos(state, status) {
      state.modalVideos = status;
      // console.log(status);
    },
    setGetCompanyInfo(state, val) {
      state.getCompanyInfo = val;
      // console.log(val);
    },
    setCountRecibidoMes(state, val) {
      // console.log("valormes",val);
      state.countRecibidoMes = val;
    },
    setCountmes(state, val) {
      // console.log("valormes",val);
      state.countMes = val;
    },
    setInfoAtenciones(state, val) {
      state.infoAtenciones = val;
      // console.log("AtencionesHoy:",val)
    },
    setEmailRecibido(state, status) {
      state.modalEmailRecibido = status;
      // console.log("Estado: ",status)
    },
    setModalEmailDerivado(state, status) {
      state.modalEmailDerivado = status;
      // console.log("Estado: ",status)
    },
    setModalHoy(state, status) {
      // console.log("Estado: ",status)
      state.modalHoy = status;
    },
    setModalMes(state, status) {
      // console.log("Estado: ",status)
      state.modalMes = status;
    },
    setCardWallet(state, status) {
      state.cardWallet = status;
    },

    setIdRoomForm(state, val) {
      state.idRoomForm = val;
      // console.log("setIdRoomForm: ",val)
    },
    setUserData(state, val) {
      state.userData = val;
      // console.log("FUNCIONO DATOS PERSONALES : ",val)
    },
    setCardFormState(state, status) {
      state.cardFormState = status;
    },
    setCardFormStateVideoCall(state, status) {
      state.cardFormStateVideoCall = status;
      // console.log("Estado", status);
    },
    setCardAlertModal(state, status) {
      state.cardAlertModal = status;
    },
    setCardBandeja(state, val) {
      state.cardBandeja = val;
    },
    setCardState(state, val) {
      state.cardState = val;
    },
    setInfoUserEmail(state, val) {
      state.infoUserEmail = val;
      // console.log("valores informacion correo:", val);
    },
    setUrlVideoEmail(state, val) {
      state.urlVideoEmail = val;
    },
    setInfoListMail(state, val) {
      state.InfoListMail = val;
    },
  },
  getters: {
    urlVideoEmail: (state) => {
      return state.urlVideoEmail;
    },
  },
  actions: {
    async getUserData({ commit }) {
      try {
        const data = await userData();
        commit("setNewUserData", data.data);
      } catch (err) {
        console.log("El Error", err);
      }
    },

    async getVideoEmaill({ commit }) {
      try {
        const data = await getVideoEmail();
        commit("setInfoListMail", data.data);
      } catch (error) {
        console.log("elError", error);
      }
    },

    async limit({ commit }) {
      try {
        const data = await limit();
        const { active_plan, stats } = data.data;
				const object = Object.assign({}, active_plan, stats)
				commit('setInfoLimit', object)
        this.state.checks.forEach((check) => {
          if (stats[check] <= 0) commit("setModalAviso", true);
        });
      } catch (err) {
        console.log("Fallo", err);
      }
    },
  },

  modules: {
    dashboard,
    CrudCuentas,
    vigilancia,
    payPlan,
  },
});
