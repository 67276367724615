<template>
  <v-container>
    <v-dialog persistent max-width="600" min-width="360" v-model="register">
      <v-card class="px-4">
        <v-card-text>
          <div class="text-end pt-3">
            <v-btn text @click="register = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
          <v-form ref="registerForm" v-model="valid" lazy-validation>
            <div class="mt-n3">
              <p class="display-1 text-center">Creación de perfil</p>
            </div>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  class="mb-n3"
                  filled
                  rounded
                  single-line
                  v-model="formRegistro.name"
                  :rules="[rules.required]"
                  label="Nombre"
                  maxlength="20"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="mb-n3"
                  filled
                  rounded
                  single-line
                  v-model="formRegistro.email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="mb-n3"
                  filled
                  rounded
                  single-line
                  v-model="formRegistro.phone_number"
                  label="Numero Telefonico"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  class="mb-n3"
                  filled
                  rounded
                  single-line
                  v-model="formRegistro.roles"
                  :items="items"
                  item-text="name"
                  item-value="id"
                  label="Rol"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  class="mb-n3"
                  filled
                  rounded
                  single-line
                  chips
                  multiple
                  v-model="formRegistro.speciality"
                  :items="itemSpeciality"
                  item-text="name"
                  item-value="id"
                  label="Especialidad"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="mb-n3"
                  filled
                  rounded
                  single-line
                  v-model="formRegistro.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Contraseña"
                  hint="At least 8 characters"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="mb-n3"
                  filled
                  rounded
                  single-line
                  block
                  v-model="formRegistro.verify"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, passwordMatch]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Confirmar Contraseña"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="mx-auto" cols="12" sm="3" xsm="12">
                <v-btn
                  x-large
                  block
                  rounded
                  :disabled="!valid"
                  text
                  style="color: #81b322; border: solid 1px #81b322"
                  @click="registro()"
                  >Registrar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="">
      <v-btn class="px-3" @click="register = true">
        <v-icon class="mr-4">mdi-account-plus</v-icon>
        <p class="mb-0 subtitle-1 text-capitalize">Crear Cuenta</p>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { registerUser, speciality, rol } from "../../../helpers/api/calls";
export default {
  props: {},
  data() {
    return {
      register: false,
      valid: true,
      show1: false,
      items: [],
      itemSpeciality: [],

      formRegistro: {
        name: "",
        email: "",
        phone_number: `+569`,
        password: "",
        verify: "",
        roles: "",
        company_id: null,
        speciality: [],
      },

      emailRules: [
        (v) => !!v || "Requerido",
        (v) => /.+@.+\..+/.test(v) || "El email debe ser válido",
      ],
      rules: {
        required: (value) => !!value || "Requerido.",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
      },
    };
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Contraseña debe coincidir";
    },
    ...mapState(["userData"]),
  },
  watch: {
    userData(newValue, oldValue) {
      // console.log(newValue)
      if (newValue) {
        this.formRegistro.company_id = newValue.company_id;
      }
    },
  },
  mounted() {
    this.speciality();
    this.listrol();
  },
  methods: {
    ...mapActions("CrudCuentas", ["pushUsers"]),
    async listrol() {
      const roles = await rol();
      this.items = roles;
    },

    async speciality() {
      const espe = await speciality();
      this.itemSpeciality = espe.data;
    },
    async registro() {
      if (this.$refs.registerForm.validate()) {
        const self = this;
        const data = this.formRegistro;

        registerUser(data)
          .then((resp) => {
            this.pushUsers();
            this.register = false;
          })
          .catch((err) => {
            console.log("No Funciono", err);
          });
      }
    },
  },
};
</script>

<style>
</style>