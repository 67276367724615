<template>
  <v-container fluid>
		<v-card
			style="border: solid 1px #2250b3"
			elevation="0"
		>
			<v-card-title class="my-0 py-1 d-flex justify-space-between" style="background-color: #2250b3">
				<p class="white--text mb-0 body-2 font-weight-bold">Horarios de Atención</p>
				<v-btn @click="showContent = !showContent" icon x-small color="white">
					<v-icon>{{showContent ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="py-2 px-3" v-show="showContent">
				<v-row v-if="loading">
					<v-col md="12" lg="6" cols="12">
						<v-skeleton-loader
							loading
							type="list-item-avatar"
						></v-skeleton-loader>
					</v-col>
					<v-col md="12" lg="6" cols="12">
						<v-skeleton-loader
							loading
							type="list-item-avatar"
						></v-skeleton-loader>
					</v-col>
				</v-row>
				<v-row align="center" v-else>
					<v-col md="12" lg="5" cols="12">
						<v-menu
							ref="menu"
							v-model="startTimeMenu"
							:close-on-content-click="false"
							:nudge-right="40"
							:return-value.sync="r1"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="r1"
									label="Horario Inicio Atención"
									prepend-icon="mdi-clock-time-four-outline"
									readonly
									hide-details
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-time-picker
								v-if="startTimeMenu"
								v-model="r1"
								full-width
            		format="24hr"
								@click:minute="$refs.menu.save(r1)"
							></v-time-picker>
						</v-menu>
					</v-col>
					<v-col md="12" lg="5" cols="12">
						<v-menu
							ref="menu2"
							v-model="endTimeMenu"
							:close-on-content-click="false"
							:nudge-right="40"
							:return-value.sync="r2"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="290px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="r2"
									label="Horario Fin Atención"
									prepend-icon="mdi-clock-time-four-outline"
									readonly
									hide-details
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-time-picker
								v-if="endTimeMenu"
								v-model="r2"
								full-width
            		format="24hr"
								@click:minute="$refs.menu2.save(r2)"
							></v-time-picker>
						</v-menu>
					</v-col>
					<v-col md="12" lg="2" cols="12">
						<v-btn
							elevation="0"
							style="color: white"
							color="#2250b3"
							small
							block
							@click="setHora()"
						>
							Enviar
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
    <v-snackbar v-model="alert" border="left" color="#2250b3" type="success">
      <div class="d-flex justify-center align-center">
        Horario de atención modificado
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import { setHours } from "@/helpers/api/calls";
export default {
  data() {
    return {
			startTimeMenu: false,
			endTimeMenu: false,
      alert: false,
      loading: true,
      id:null,
      r1: null,
      r2: null,
			showContent: false
    };
  },
  watch: {
    "$store.state.userData"(newValue) {
      if (newValue) {
        // console.log("watch",newValue)
        this.id = newValue.company_id
        this.changeHours(newValue);
      }
    },
  },

  methods: {
    changeHours(newValue) {
      this.r1 = newValue.company.working_hours.slice(0, 5);
      this.r2 = newValue.company.working_hours.slice(6);
			this.loading = false
    },

    async setHora() {
      (this.loading = true), console.log("setHora");
      setHours({
        id:this.id,
        inicio: this.r1,
        termino: this.r2,
      })
        .then((resp) => {
          if (resp) {
            (this.loading = false),
              (this.alert = true),
              setTimeout(() => {
                this.alert = false;
              }, 3000);
            console.log(resp);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
  },
};
</script>

<style>
</style>