<template>
  <v-container>
    <v-dialog width="750" v-model="vdo">
      <v-container style="background:white">
        <div>
          <v-btn icon @click="vdo = false"
            ><v-icon  size="30" color="#2250b3">mdi-close</v-icon></v-btn
          >
        </div>
        <div class="text-center">
          <p style="color:#2250b3" class="display-1">Video Llamada privada</p>
        </div>
        <v-container>
          <div
            style="background:rgb(194, 192, 192)"
            class="rounded-lg text-center"
          >
            <p style="color:white" class="display-1">{{code}}</p>
          </div>
        </v-container>
        <v-container class="text-center">
      <ShareNetwork
          style="text-decoration: none"
          
          v-for="network in networks"
          :key="network.network"
          :network="network.network"
          :url="code"
          title="Visita Click2Call con mi codigo de referrido"
        >
        <v-icon class="mr-5" size="50" :color="network.color" >{{network.icon}}</v-icon>
      </ShareNetwork>          
        </v-container>
      </v-container>
    </v-dialog>
    <div>
      <div class="mb-n8 mt-2 ml-n3">
        <v-btn
          text
          color="white"
          block
          large
          class="px-3 d-flex flex-row justify-start align-center"
          @click="(vdo = true),userData()"
        >
          <v-icon color="#2250b3" class="mr-4">mdi-video-outline</v-icon>
          <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
            Botón embebido
          </p>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import {
  getDatabase,
  ref,
  onValue,
  set,
  onChildChanged,
  remove
} from "firebase/database";
export default {
  data() {
    return {
        info: '',
        vdo: false,
        code:'',
        networks:[
        {network:'facebook', name:'facebook', icon:'mdi-facebook', color: '#1877f2'},
        {network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', color: '#1da1f2' },
        { network: 'messenger', name: 'messenger', icon: 'mdi-facebook-messenger', color: '#0084ff' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: '#25d366' },
        { network: 'email', name: 'Email', icon: 'mdi-email', color: '#333333' },
      ]        


    };
  },
  watch: {
    '$store.state.userData'(newValue) {
      if(newValue){
        this.userData();
      }
    }
  },
  methods: {
     userData() {
      this.info = this.$store.state.userData;
      this.code = `https://www.click2call.cl/?ShareLink=${this.info.id}`
    //   console.log("private",this.info);
    },   
  },
};
</script>

<style scoped>

</style>