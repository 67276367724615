<template>
  <v-container
    class="fondoLogin d-flex align-center"
    style="height: 100%"
    fluid
  >
    <v-row justify="center">
      <v-col cols="10" sm="4">
        <v-card style="background: #f7f6f4">
          <div class="pt-5 pb-5 d-flex justify-center">
            <v-img
              max-width="250"
              eager
              :src="require('../../assets/Click2Call.png')"
            />
          </div>
          <v-form ref="registerForm" v-model="valid" lazy-validation>
            <div class="mt-n3">
              <p
                style="color: #2250b3"
                class="display-1 text-center pt-4 font-weight-regular"
              >
                Registro de Compañia
              </p>
            </div>
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">
                  Datos de empresa</v-stepper-step
                >

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">
                  Seleccion de plan</v-stepper-step
                >
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          class="mb-n3"
                          filled
                          rounded
                          single-line
                          v-model="name"
                          :rules="[rules.required]"
                          label="Nombre"
                          maxlength="50"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          class="mb-n3"
                          filled
                          rounded
                          single-line
                          v-model="email"
                          :rules="emailRules"
                          label="E-mail"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <div>
                          <v-alert
                            class="text-center"
                            color="#2250b3"
                            style="color: white"
                            :value="alert"
                          >
                            Email se encuentra en uso, favor de utilizar otro.
                          </v-alert>
                        </div>
                      </v-col>
                      <v-spacer></v-spacer>

                      <v-col class="mx-auto" cols="6" sm="3" xsm="6">
                        <v-btn
                          x-large
                          block
                          rounded
                          :disabled="!disnableE1"
                          text
                          style="color: #2250b3; border: solid 1px #2250b3"
                          @click="e1 = 2"
                          >Siguiente</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <div class="mb-10">
                    <v-row justify="center">
                      <v-col v-for="(pla, index) in plan" :key="index" cols="3">
                        <div class="text-center">
                          <p class="font-weight-bold">{{ pla.name }}</p>
                          <input
                            type="radio"
                            v-model="plan_id"
                            :value="pla.id"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row justify="center">
                    <v-col cols="4" sm="4" xsm="4">
                      <v-btn
                        x-large
                        block
                        rounded
                        text
                        style="color: #2250b3; border: solid 1px #2250b3"
                        @click="e1 = 1"
                        >Volver</v-btn
                      >
                    </v-col>
                    <v-col cols="4" sm="4" xsm="4">
                      <v-btn
                        x-large
                        block
                        rounded
                        :disabled="!disnableBtn"
                        text
                        style="color: #2250b3; border: solid 1px #2250b3"
                        @click="registerCompany()"
                        >Registrar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { postCompany, getPlan } from "../../helpers/api/calls";
export default {
  name: "Login",
  data: () => ({
    e1: 1,
    alert: false,
    login: false,
    valid: true,
    showMessage: false,
    messageColor: "error",
    show1: false,
    message: "",

    email: "",
    name: "",
    plan_id: null,

    plan: [],

    emailRules: [
      (v) => !!v || "Email es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Email debe ser válido",
    ],
    rules: {
      required: (value) => !!value || "Requerido.",
      min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
      minPhone: (v) =>
        (v && v.length >= 9) ||
        "Debe contener el codigo del país y Mínimo 9 digitos",
    },
  }),

  computed: {
    disnableE1() {
      return this.email != "" && this.name != "";
    },

    disnableBtn() {
      return this.email != "" && this.name != "" && this.plan_id != null;
    },
  },
  mounted() {
    this.planes();
  },

  methods: {
    async registerCompany() {
      postCompany({
        name: this.name,
        email: this.email,
        plan_id: this.plan_id,
      })
        .then((resp) => {
          console.log("respuesta", resp);
          if (resp) {
            this.$router.push({ name: "DashBoard" });
          }
        })
        .catch((err) => {
          console.log("Error Creacion empresa", err);
        });
    },
    async planes() {
      const plan = await getPlan();
      this.plan = plan.data;
      console.log("testing", this.plan);
    },
  },
};
</script>

<style scoped>
.fondoLogin {
  background-image: url("../../assets/FondoLinea.svg");
  background-color: #fec12d;
  background-position: center;
  background-size: cover;
  height: auto;
}
</style>
