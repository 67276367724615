<template>
  <v-container class="py-0">
    <v-card>
      <v-container>
        <p class="display-1">Estado de Usuarios</p>
      </v-container>
      <v-row>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Rol</th>
                  <th class="text-left">Correo</th>
                  <th class="text-start">Estado</th>
                  <th class="text-center">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :class="info.id == user.id_user ? 'd-none' : ''"
                  v-for="(user, index) in userFirebase"
                  :key="index"
                >
                  <td>
                    {{ user.name | mayus }}
                  </td>
                  <td>
                    {{ user.ocupacion }}
                  </td>
                  <td>
                    {{ user.email }}
                  </td>
                  <td
                    class="subtitle-1 font-weight-bold"
                    :style="user.status == true ? 'color:#81b322' : 'color:red'"
                  >
                    {{ user.status == true ? "En Linea" : "Desconectado" }}
                  </td>
                  <td>
                    <v-row>
                      <v-col cols="6">
                        <div>
                          <v-btn
                            elevation="1"
                            style="background: #2250b3"
                            @click="openModalInfo(user)"
                            icon
                            class="mt-2"
                            ><v-icon color="white"
                              >mdi-information-outline</v-icon
                            ></v-btn
                          >
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div
                          v-if="
                            user.status == false &&
                            $store.state.infoLimit.available_callbacks_time > 0
                          "
                        >
                          <Llamada :user="user" />
                        </div>
                        <div
                          cols="6"
                          v-if="
                            user.status == true &&
                            $store.state.infoLimit.available_videocalls_time > 0
                          "
                        >
                          <v-btn
                            elevation="1"
                            style="background: #2250b3"
                            @click="CallOperator(user)"
                            rounded
                            icon
                            class="mt-2"
                            ><v-icon color="white">
                              mdi-video-account</v-icon
                            ></v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
    <informacion class="d-none" />
  </v-container>
</template>

<script>
import { getUsers } from "../../../../helpers/api/calls";
import {
  getDatabase,
  ref,
  onValue,
  set,
  onChildChanged,
} from "firebase/database";
import Informacion from "./Informacion.vue";
import Llamada from "./Llamada.vue";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    Informacion,
    Llamada,
  },
  filters: {
    mayus: function (data) {
      return data.charAt(0).toUpperCase() + data.slice(1);
    },
  },
  data() {
    return {
      users: [],
      userFirebase: [],
      info: "",
    };
  },
  mounted() {
    this.getUsers();
  },
  watch: {
    "$store.state.userData"(newValue) {
      if (newValue) {
        this.userData();
      }
    },
  },
  computed: {
    ...mapState("vigilancia", ["modalInfo"]),
  },

  methods: {
    ...mapMutations("vigilancia", ["setModalInfo"]),
    openModalInfo(user) {
      this.setModalInfo({
        status: true,
        information: user,
      });
      // console.log(user)
    },
    async getUsers() {
      const users = await getUsers();
      this.users = users.data;
    },
    CallOperator(user) {
      this.insertDb(user);
      this.$router.push({
        name: "VideoLlamadaOperador",
        params: { sala_id: user.id_user, id: this.info.id },
      });
    },
    insertDb(user) {
      const db = getDatabase();
      set(
        ref(
          db,
          "connections/" +
            "PrivateOperators/" +
            this.info.company_id +
            "/" +
            user.id_user
        ),
        {
          sala_id: user.id_user,
        }
      );
    },

    Observador() {
      const db = getDatabase();
      const starCountRef = ref(db, "connections/" + this.info.company_id);
      // console.log("llegaAqui?");

      onChildChanged(starCountRef, (snapshot) => {
        const data = snapshot.val();
        // console.log("dataObservador", data);
        if (data) {
          this.getUsers();
          // console.log("Encontro Datos");
        }
      });
    },

    ListUser() {
      const db = getDatabase();
      const starCountRef = ref(db, "connections/" + this.info.company_id);
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          this.userFirebase = Object.values(data);
          console.log("Informacion que viene: ",data)
          // console.log("SetInfo: ", this.userFirebase)
        }
      });
    },

    userData() {
      this.info = this.$store.state.userData;
      // console.log("info DAta", this.info);
      this.Observador();
      this.ListUser();
    },
  },
};
</script>

<style></style>
