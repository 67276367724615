<template>
    <!-- <file-browser storage="s3" :axiosConfig="{baseURL: 'http://localhost:8001/api'}" /> -->
    <v-container>
        
    </v-container>
</template>

<script>
// import FileBrowser from "vuetify-file-browser";

export default {
    components: {
        // FileBrowser
    }
};
</script>