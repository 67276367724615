import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login/Login.vue'
import Panel from '../components/Panel/Panel.vue'
import DrawerRight from '../components/Panel/DrawerLateralDerecho/DrawerRight.vue'
import SocketVideoPanel from '../components/Panel/SocketVideoPanel.vue'
import VideoLlamadaOperador from '../components/Panel/StaticPages/Vigilancia/VideoLlamadaOperador.vue'
import VideoEmail from '../components/Panel/StaticPages/VideoEmail.vue'
import DashBoard from '../components/Panel/StaticPages/DashBoard.vue'
import Cuentas from '../components/Panel/StaticPages/Cuentas.vue'
import Vigilancia from '../components/Panel/StaticPages/Vigilancia.vue'
import ValidateServices from '../components/Login/ValidateServices.vue'
import Administracion from '../components/Panel/StaticPages/Administracion.vue'
import NuevoSocketVideo from '@/components/Panel/NuevoSocketVideo.vue'
import Plans from '@/components/Panel/StaticPages/Planes.vue'
import Bolsas from '@/components/Panel/StaticPages/Bolsas.vue'
import FlowReturn from '../components/Panel/StaticPages/Pagos/FlowReturn.vue'
import InformacionGeneral from '../components/Panel/StaticPages/InformacionGeneral/Index.vue'
import MisPagos from '../components/Panel/MisPagos/Index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Panel',
    name: 'panel',
    component: Panel
  },
  {
    path: '/SocketVideoPanel',
    name: 'SocketVideoPanel',
    component: SocketVideoPanel
  },
  {
    path: '/VideoLlamadaOperador',
    name: 'VideoLlamadaOperador',
    component: VideoLlamadaOperador
  },
  {
    path: '/VideoEmail',
    name: 'VideoEmail',
    component: VideoEmail
  },
  {
    path: '/DashBoard',
    name: 'DashBoard',
    component: DashBoard
  },
  {
    path: '/Cuentas',
    name: 'Cuentas',
    component: Cuentas
  },
  {
    path: '/Vigilancia',
    name: 'Vigilancia',
    component: Vigilancia
  },
  {
    path: '/DrawerRight',
    name: 'DrawerRight',
    component: DrawerRight
  },
  {
    path: '/ValidateServices',
    name: 'ValidateServices',
    component: ValidateServices
  },
  {
    path: '/Administracion',
    name: 'Administracion',
    component: Administracion
  },
  {
    path: '/NuevoSocketVideo',
    name: 'NuevoSocketVideo',
    component: NuevoSocketVideo
  },
  {
    path: '/Planes',
    name: 'Planes',
    component: Plans
  },
  {
    path: '/Bolsas',
    name: 'Bolsas',
    component: Bolsas
  },
  {
    path: '/Pagos/retorno-flow',
    name: 'FlowReturn',
    component: FlowReturn
  },
  {
    path:'/informacionGeneral',
    name:'informacionGeneral',
    component: InformacionGeneral
  },
  {
    path:'/misPagos',
    name:'misPagos',
    component: MisPagos
  },



  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
