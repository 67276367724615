<template>
  <v-container fluid style="background:#EAEEF2;height:100%"  class="pa-0 pb-">
    <div class="d-flex flex-row">
      <div class="d-flex flex-nowrap ma-5">
        <Drawer />
      </div>
      <v-row class="ma-5">
        <v-col cols="8">
          <Banner />
          <Cuadro />
          <v-row>
            <v-col md="12" xl="6" cols="6">
              <PersonasAtendidasDia :id="datos.id" />
            </v-col>
            <v-col md="12" xl="6" cols="6">
              <PersonasAtendidasMes />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <DrawerRight />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>


import Drawer from "./Drawer.vue";
import Banner from "../Panel/BannerSuperior/Banner.vue"
import Cuadro from "./CuadroInformatico/Cuadro.vue"
import DrawerRight from "./DrawerLateralDerecho/DrawerRight.vue"
import PersonasAtendidasDia from "./TablasInformativas/PersonasAtendidasDia.vue"
import PersonasAtendidasMes from "./TablasInformativas/PersonasAtendidasMes.vue"
// import ConexionEntrante from "./SocketVideoPanel/ConexionEntrante.vue";
import {userData} from '../../helpers/api/calls'
export default {
  data() {
    return {
      datos: '',
    };
  },
  components: {
    Drawer,
    Banner,
    Cuadro,
    DrawerRight,
    PersonasAtendidasDia,
    PersonasAtendidasMes
    // SocketVideoPanel,
    // ConexionEntrante,
  },
  mounted() {
    // this.userData()
  },
  watch: {
    '$store.state.userData'(newValue) {
      if(newValue){
        this.userData();
      }
      
    }
  },
  methods: {
       userData() {
      this.datos = this.$store.state.userData;
      // console.log("Datos Panel",this.datos);

    },

  },
};
</script>
