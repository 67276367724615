var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"rounded-lg",attrs:{"temporary":"","fluid":""}},[_c('v-card',{attrs:{"height":"90vh","width":"100%","floating":""}},[_c('v-list-item',[_c('v-row',{staticClass:"mb-5 mt-5",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"hidden-md-and-down",attrs:{"lg":"3","xl":"3","cols":"3"}},[_c('v-container',{staticClass:"text-center mt-10"},[_c('v-icon',{attrs:{"color":"#81B322","size":"45"}},[_vm._v("mdi-bell")])],1)],1),_c('v-col',{attrs:{"sm":"12","md":"12","lg":"6","cols":"6"}},[_c('div',{staticClass:"mx-auto",style:(_vm.operador == 1
                ? 'background: #feb62d;border-radius: 100%;width: 150px;height: 150px;'
                : 'background: #eaeef2;border-radius: 100%;width: 150px;height: 150px;')},[_c('v-img',{staticClass:"mx-auto",style:(_vm.operador == 1
                  ? 'border-radius: 100%;'
                  : 'border-radius: 100%;filter: opacity(0.3) drop-shadow(0 0 0 #eaeef2);'),attrs:{"eager":"","src":require('../../../assets/WomanChico.png'),"max-width":"150"}})],1)]),_c('v-col',{class:!_vm.$vuetify.breakpoint.md.lg ? 'd-flex justify-center' : '',attrs:{"md":"12","lg":"3","xl":"3","cols":"3"}},[_c('div',[_c('v-switch',{class:!_vm.$vuetify.breakpoint.md ? 'mt-14' : '',attrs:{"value":_vm.operador ? 1 : 3,"loading":_vm.loading,"color":"#81B322","hide-details":""},model:{value:(_vm.operador),callback:function ($$v) {_vm.operador=$$v},expression:"operador"}}),_c('p',{staticClass:"caption",style:(_vm.operador == 1 ? 'color:#81B322' : 'color:#E20D4E')},[_vm._v(" "+_vm._s(_vm.operador == 1 ? "En línea" : "Ausente")+" ")])],1)])],1)],1),_c('v-container',{staticClass:"text-center mb-n2"},[_c('p',{staticClass:"title font-weight-regular",staticStyle:{"color":"#2250b3"}},[_vm._v(" Solicitud de atenciones ")])]),_c('v-divider'),_vm._l((Object.keys(_vm.alert)),function(sala,index){return _c('v-container',{key:index,staticClass:"pa-1"},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('div',[_c('v-img',{staticClass:"rounded-lg",attrs:{"width":"100%","height":"70","src":require('../../../assets/imgPerfil.png')}})],1)]),_c('v-col',[_c('div',{class:_vm.alert[sala].operator_id == _vm.info.id ? 'paraUsuario' : '',style:(_vm.alert[sala].speciality ? 'color:#81B322' : 'color: #2250b3')},[_c('p',{class:_vm.alert[sala].operator_id == _vm.info.id
                    ? 'subtitle-2 mb-1'
                    : 'caption mb-1'},[_vm._v(" "+_vm._s(_vm._f("toHour")(_vm.alert[sala].created_at))+" ")]),_c('p',{class:_vm.alert[sala].operator_id == _vm.info.id
                    ? 'subtitle-2 mb-1'
                    : 'caption mb-1'},[_vm._v(" "+_vm._s(_vm.alert[sala].speciality ? _vm.alert[sala].speciality : "General")+" ")]),_c('p',{class:_vm.alert[sala].operator_id == _vm.info.id
                    ? 'subtitle-2'
                    : 'caption mb-1'},[_vm._v(" Video llamada ")])])]),_c('v-col',[_c('div',{class:_vm.$vuetify.breakpoint.md
                  ? 'mt-n4 d-flex justify-center mb-3'
                  : 'mt-4'},[_c('v-btn',{staticClass:"rounded-lg",style:(_vm.alert[sala].operator_id == _vm.info.id
                    ? 'border: solid 1px #feb62d;color:#feb62d'
                    : 'border: solid 1px #81b322'),attrs:{"color":"#81B322","text":""},on:{"click":function($event){_vm.stopSong(_vm.alert[sala]), _vm.DeleteGeneral(_vm.alert[sala])}}},[_vm._v("Atender")])],1)])],1),_c('v-divider')],1)])})],2),_c('DrawerMobile',{staticClass:"d-flex d-sm-none",staticStyle:{"position":"absolute","top":"0px","left":"0px"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }