<template>
  <v-container class="py-0 px-0" fluid>
    <v-card height="100%">
      <v-container class="px-0">
        <div class="rounded-lg divVideo">
          <my-video
            v-if="video != null"
            :sources="video.sources"
            :options="video.options"
          ></my-video>
        </div>
      </v-container>

      <v-container>
        <v-row>
          <v-col
            :class="!$vuetify.breakpoint.mobile ? 'mb-n5' : ''"
            style="color: #7e7e7e"
            lg="12"
            xl="8"
            cols="8"
          >
            <p class="mb-1 title">{{ infoUserEmail.client.name }}</p>
            <p class="mb-1 subtitle-1">
              Numero: {{ infoUserEmail.client.phone_number }}
            </p>
            <p class="mb-1 subtitle-1">
              Correo: {{ infoUserEmail.client.email }}
            </p>
            <p class="caption">
              Enviado: {{ infoUserEmail.created_at | toDate }}
            </p>
          </v-col>
          <v-col
            :class="!$vuetify.breakpoint.xl ? 'text-start' : 'text-end'"
            lg="12"
            xl="4"
            cols="4"
          >
            <CallBack v-if="this.$store.state.infoLimit.available_callbacks_time > 0" />
            <SendMsn />
            <div v-if="this.$store.state.infoLimit.available_whatsapp_messages > 0">
              <div>
                <v-btn
                @click="sendWsp()"
                :loading="loading"
                  color="#81B322"
                  width="100%"
                  class="rounded-lg caption font-weight-bold"
                  text
                  style="border: solid 1px"
                  >Mandar WhatsApp</v-btn
                >
              </div>
            </div>
<!--ESTO ES MLB-->
            <p class="mb-1 title"></p>
            <p class="mb-1 title"></p>
            <div>
            <v-btn
              :loading="loadingCheck"
              @click="updateStatusEmail()"
              color="#81B322"
              width="100%"
              class="rounded-lg caption font-weight-bold"
              text style="border: solid 1px"
            >
              Cambiar estado a Respondido <v-icon>mdi-check</v-icon>
            </v-btn>
            </div>
  <!--ESTO ES MLB-->
            <FormFinalizacion />
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <p class="title" style="color: #7e7e7e">Mensaje</p>
        <p style="color: #7e7e7e">
          {{ infoUserEmail.message == "Test" ? loremp : infoUserEmail.message }}
        </p>
      </v-container>
    </v-card>
    <div class="d-flex justify-center">

    <!-- <v-alert  transition="scale-transition" style="position:fixed;bottom:0" v-model="aler" class="text-center" :color="color" >
      <p style="color:white" class="title">{{message}}</p>
    </v-alert> -->
    </div>
  </v-container>
</template>

<script>
import {
  getDatabase,
  ref,
  onValue,
  set,
  onChildChanged,
  remove
} from "firebase/database";
import moment from "moment";
import CallBack from "../CallBack/CallBack.vue";
import SendMsn from "../VideoEmail/SendMsn.vue";
import { mapGetters, mapState } from "vuex";
import myVideo from "vue-video";
import {postWsp} from '@/helpers/api/calls'
import FormFinalizacion from "./FormFinalizacion.vue";
////ESTO ES MLB
import {  updateStatusEmail } from "../../../../helpers/api/calls";
////ESTO ES MLB

export default {
  filters: {
    toDate: function(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  data() {
    return {
////ESTO ES MLB
            loadingCheck: false,
////ESTO ES MLB
      loading: false,
      info: "",
      aler:false,
      color:null,
      message:'',

      video: null,
      loremp:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet",
    name:this.$store.state.infoUserEmail.client.name,
    phone: this.$store.state.infoUserEmail.client.phone_number
    };
  },
  components: {
    myVideo,
    CallBack,
    SendMsn,
    FormFinalizacion,
  },

  computed: {
    ...mapState(["infoUserEmail", "urlVideoEmail"]),
  },
  watch: {
    '$store.state.userData'(newValue) {
      if(newValue){
        this.userData()

      }
    },

    "$store.state.urlVideoEmail"(newValue) {
      if (newValue) {
        this.video = {
          sources: [
            {
              src: newValue,
              type: "video/webm",
            },
          ],
          options: {
            autoplay: false,
            volume: 0.4,
          },
        };
        // console.log("testing2", this.video);
      } else {
        this.video = null;
      }
    },
  },
  methods: {
     userData() {
      this.info = this.$store.state.userData;


    },
  CallPrivate() {
      this.phone = this.phone.slice(1)
      console.log("Dato:",this.phone)
      this.insertDb();
      this.$router.push({
        name: "NuevoSocketVideo",
        params: { sala_id: this.phone, id: this.$store.state.userData.id },
      });
    },
////ESTO ES MLB
    async updateStatusEmail() {
      const self = this
			this.loadingCheck = true
      updateStatusEmail({ id: this.$store.state.infoUserEmail.id, videomail_status_id: 3 })
        .then(function (resp) {
          console.log("Funciono status email", resp);
					this.loadingCheck = false
          console.log(resp);
        })
        .catch(function (err) {
          console.log("error", err);
					this.loadingCheck = false
        })
    },
    clearData() {


    },
////ESTO ES MLB
    insertDb() {
      const db = getDatabase();
      set(
        ref(
          db,
          "connections/" +
            "PrivateWsp/" +
            this.info.company_id +
            "/" +
            this.phone
        ),
        {
          sala_id: this.phone,
        }
      );
    },
   delereRoom() {
      const db = getDatabase();
      remove(
        ref(
          db,
          "connections/" +
            "PrivateWsp/" +
            this.info.company_id +
            "/" +
            this.phone
        ),
      );

      console.log("funciona?");
    },

    async sendWsp(){
      this.loading = true
      postWsp({name: this.name, phone: this.phone })
      .then((resp) => {
        console.log("Funciono: ",resp)
        this.loading = false,
        this.aler = true
        this.color = "#4caf50"
        this.message = 'WhatsApp Enviado Con Exito'
        setTimeout(() => {
          this.aler = false
        }, 3000);
        this.CallPrivate()
        this.delereRoom()

      })
      .catch((err) => {
        console.log("error whasapp: ",err)
        this.aler = true
        this.color = "#f44336"
        this.message = 'Error al enviar un WhatsApp, Verificar Datos.'
        setTimeout(() => {
          this.aler = false
        }, 4000);
      });
    }

  },
};
</script>

<style>
.divVideo {
  width: 100%;
  height: 350px;
  /* background: black; */
}
.divVideoXL {
  width: 100%;
  height: 250px;
  /* background: black; */
}
.__cov-video-container {
  height: 350px;
  width: 100%;
}
.__cov-contrl-content {
  width: 100%;
  z-index: 1;
  position: absolute;
}
.__cov-contrl-vol-box {
  width: 100px;
}
</style>
