<template>
  <v-container class="pa-0">
    <v-dialog width="600" v-model="updateModal">
      <v-container style="background: white">
        <div>
          <v-btn color="#2250b3" icon @click="updateModal = false">
            <v-icon size="30"> mdi-close </v-icon>
          </v-btn>
        </div>
        <div class="text-center">
          <p style="color: #2250b3" class="display-1">
            Actualizar la siguiente especialidad
          </p>
        </div>

        <v-container>
          <v-form ref="addEspecialidad" v-model="valid">
            <v-text-field
              v-model="nameEsp"
              label="Nombre de la especialidad"
              outlined
              single-line
              elevation="0"
            >
            </v-text-field>
            <v-text-field
              v-model="desc"
              label="Descripción"
              outlined
              single-line
              elevation="0"
            >
            </v-text-field>
            <!-- <v-select
              class="mb-n3"
              filled
              rounded
              single-line
              v-model="infoSelect"
              label="Nombre de la especialidad"
              return-object
            ></v-select> -->

            <div class="text-center">
              <v-btn
                style="color: white"
                class="rounded-lg text-capitalize"
                color="#2250b3"
                @click="updateEsp()"
                :disabled="!disnable"
                >Actualizar Especialidad</v-btn
              >
            </div>
          </v-form>
        </v-container>
      </v-container>
    </v-dialog>
    <!-- <div>
      <v-snackbar color="#2250b3" timeout="2000" v-model="msn">
        <p
          style="color: white"
          class="text-center subtitle-1 font-weight-normal"
        >
          Numero Añadido
        </p>
      </v-snackbar>
    </div> -->
    <div>
      <v-btn @click="updateModal = true" class="mt-n2" icon color="#2250b3"
        ><v-icon>mdi-file-document-outline</v-icon></v-btn
      >
    </div>
  </v-container>
</template>

<script>
// import { mapState } from "vuex";
import { updateAdminEsp } from "../../../../../helpers/api/calls";
export default {
  props: {
    reload: {
      type: Function,
      default: null,
    },
    nombre: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
    desciption: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      updateModal: false,
      valid: null,
      nameEsp: this.nombre,
      desc: this.desciption,
    };
  },
  computed: {
    // ...mapState(["modalAddEsp"]),
    disnable() {
      return this.nameEsp != "" && this.desc != "";
    },
  },
  methods: {
    async updateEsp() {
      updateAdminEsp({
        name: this.nameEsp,
        description: this.desc,
        id: this.id,
      })
        .then((resp) => {
          if (resp) {
            console.log(resp);
            (this.updateModal = false),
             this.reload();
            this.nameEsp = ''
            this.desc = ''
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>