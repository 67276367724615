import request from '@/helpers/request'
// import store from '../../store/index.js'
import {endpoints} from './endpoints'

export function getPackages(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getPackages,
      method: 'get',
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}