<template>
  <div v-if="modal">
    <v-dialog v-model="payment" persistent max-width="600px" content-class="custom-dialog">
      <v-card>
        <v-card-title class="justify-center">
          <span class="headline" style="color: #707070;">
            {{ payment.payment ? "Pago realizado" : "Error en el pago" }}
          </span>
        </v-card-title>
        <v-card-text v-if="payment.payment">
          <v-container>
            <v-row>
              <v-col cols="12" style="color: #707070" class="text-center">
                <h3 class="headline">
                  {{ payment ? "El pago ha sido procesado correctamente" : "Hubo un error en el pago" }}
                </h3>
                <div v-if="payment">
                  <h4 class="mb-3">ID de Pago: {{ payment ? payment.payment.order : "Ha ocurrido un error" }}</h4>
                  <p class="body-1 mb-0"><b>Monto:</b> {{ toPrice(payment.payment.amount) }}</p>
                  <p class="body-1 mb-0"><b>Forma Pago:</b> {{ payment.payment.method }}</p>
                  <p class="body-1 mb-0"><b>Fecha Pago:</b> {{ payment.payment.payment_date }}</p>
                </div>
                <div v-else>
                  <p class="body-1 mb-0">No se ha podido procesar el pago correctamente</p>
                  <p class="body-2 mb-0">No se han realizado cargos a tu cuenta</p>
                  <p class="body-1 mb-0 mt-3 font-weight-bold">Por favor intenta nuevamente</p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex justify-center align-center mb-3" style="width: 100%;">
            <v-btn
              color="#707070"
              text
              rounded
              outlined
              style="width: 200px;"
              class="mx-2"
              :block="$vuetify.breakpoint.mobile"
              @click="goDashboard"
            >
              <p class="body-2 mb-0">Volver al panel</p>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["payment"],
  data() {
    return {
      modal: false,
    };
  },
  watch: {
    payment(value) {
      this.modal = true;
    },
  },
  methods: {
    goDashboard() {
      this.$router.replace("/Dashboard");
    },
    toPrice(price) {
      var formatter = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "CLP",
        currencyDisplay: "symbol",
      });
      return formatter.format(price);
    },
  },
};
</script>

<style></style>
