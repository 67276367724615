<template>
  <v-dialog width="600" v-model="$store.state.cardAlertModal">
    <v-container style="background:white">
      <div class="text-center">
        <h1 style="color:#2250B3">Video Llamada Entrante</h1>
      </div>
      <div
        class="mx-auto mt-5"
        style="
                background: #feb62d;
                border-radius: 100%;
                width: 150px;
                height: 150px;
              "
      >
        <v-img
          eager
          class="mx-auto"
          :src="require('../../../../assets/WomanChico.png')"
          max-width="150"
          style="border-radius: 100%"
        />
      </div>
      <div class="text-center mt-10 mb-5">
        <v-btn
          @click="$store.commit('setCardAlertModal', false), CallOperator(), deleteRoom()"
          height="60"
          width="200"
          style="color:white"
          class="rounded-xl"
          color="#2250B3"
          >Atender</v-btn
        >
      </div>
    </v-container>
  </v-dialog>
</template>

<script>
import { getDatabase, ref, remove } from "firebase/database";
export default {
  props: {
    room: {
      type: Object,
    },
    info: {
      type: Number,
    },
    datos: {
      type: Object,
    }
  },
  data() {
    return {
      aviso: false,
    };
  },
  methods: {
    CallOperator() {
      console.log("Testing", this.room, this.info);
      this.$router.push({
        name: "VideoLlamadaOperador",
        params: { sala_id: this.room.sala_id, id: this.info },
      });
    },
  deleteRoom() {
    console.log("se apreto")
    const db = getDatabase();
    remove(
      ref(
        db,"connections/" + "PrivateOperators/" + this.datos.company.id + "/" + this.datos.id
      )
    );

    // console.log("funciona?");
  },
  },
};
</script>

<style></style>
