<template>
  <v-container class="rounded-lg" fluid style="background: white">
    <v-navigation-drawer
      floating
      touchless
      permanent
      hide-overlay
      style="background-color: transparent"
    >
      <v-list-item>
        <v-row class="mb-5 mt-5">
          <v-col>
            <div class="">
              <v-img
                eager
                width="200px"
                :src="require('../../assets/Banner.png')"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-list-item>
      <v-container
        v-if="loading == true"
        class="d-flex align-center justify-center"
      >
        <v-progress-circular indeterminate color="amber"></v-progress-circular>
      </v-container>
      <v-container class="px-1" v-if="!loading == true">
        <v-list v-if="menu.includes(1)">
          <div>
            <v-btn
              text
              color="white"
              block
              large
              class="px-3 d-flex flex-row justify-start align-center"
              to="/DashBoard"
            >
              <v-icon color="#2250b3" class="mr-4">mdi-monitor</v-icon>
              <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
                Menu
              </p>
            </v-btn>
          </div>
        </v-list>

        <v-list v-if="menu.includes(2)">
          <div>
            <v-btn
              text
              color="white"
              block
              large
              class="
                px-3
                mt-n2
                mb-n2
                d-flex
                flex-row
                justify-start
                align-center
              "
              to="/Administracion"
            >
              <v-icon color="#2250b3" class="mr-4"
                >mdi-file-document-edit</v-icon
              >
              <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
                Administracion
              </p>
            </v-btn>
          </div>
        </v-list>

        <v-list>
          <div>
            <v-btn
              text
              color="white"
              block
              large
              class="
                px-3
                mt-n2
                mb-n2
                d-flex
                flex-row
                justify-start
                align-center
              "
              to="/informacionGeneral"
            >
              <v-icon color="#2250b3" class="mr-4"
                >mdi-information-outline</v-icon
              >
              <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
                Informacion General
              </p>
            </v-btn>
          </div>
        </v-list>

        <v-list v-if="menu.includes(3)" class="text-center mb-3 mt-n4">
          <div>
            <Billetera />
          </div>
        </v-list>

        <v-list v-if="userData.id === 4">
          <div>
            <v-btn
              @click="goMisPagos"
              text
              color="#2250b3"
              block
              large
              class="px-3 d-flex flex-row justify-start align-center"
            >
              <v-icon class="mr-4">mdi-credit-card-outline</v-icon>
              <p class="mt-4 subtitle-1 text-capitalize">Mis Pagos</p>
            </v-btn>
          </div>
        </v-list>

        <!-- <v-list v-if="videoEmail && $store.state.infoLimit.available_videomails_data > 0"> -->
        <v-list>
          <div>
            <v-btn
              text
              color="white"
              block
              large
              class="px-3 d-flex flex-row justify-start align-center"
              to="/VideoEmail"
            >
              <v-icon color="#2250b3" class="mr-4">mdi-gmail</v-icon>
              <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
                VideoEmails
              </p>
            </v-btn>
          </div>
        </v-list>

        <v-list v-if="menu.includes(5)" class="mb-n2">
          <div>
            <v-btn
              text
              color="white"
              block
              large
              class="px-3 d-flex flex-row justify-start align-center"
              to="/Cuentas"
            >
              <v-icon color="#2250b3" class="mr-4">mdi-account-multiple</v-icon>
              <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
                Cuentas
              </p>
            </v-btn>
          </div>
        </v-list>

        <v-list v-if="menu.includes(6)" class="mb-n2">
          <div>
            <v-btn
              text
              color="white"
              block
              large
              class="px-3 d-flex flex-row justify-start align-center"
              to="/Vigilancia"
            >
              <v-icon color="#2250b3" class="mr-4">mdi-eye-outline</v-icon>
              <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
                Vigilancia
              </p>
            </v-btn>
          </div>
        </v-list>

        <v-list v-if="menu.includes(7)" class="text-center mb-3 mt-n6">
          <div>
            <CodigoReferido />
          </div>
        </v-list>
        <v-list
          v-if="
            menu.includes(8) &&
            $store.state.infoLimit.available_videocalls_time > 0
          "
          class="text-center mb-3 mt-n6"
        >
          <div>
            <PrivateVideoCall />
          </div>
        </v-list>

        <v-list
          v-if="
            menu.includes(8)
          "
          class="text-center mb-3 mt-n6"
        >
          <div>
            <ShareLink />
          </div>
        </v-list>
        <v-list
          v-if="
            menu.includes(9) &&
            $store.state.infoLimit.available_whatsapp_messages > 0
          "
          class="text-center mb-2 mt-n4"
        >
          <div>
            <WhatsappForm />
          </div>
        </v-list>
        <v-list v-if="menu.includes(10)" class="text-center mb-2 mt-n3">
          <div>
            <MyDate />
          </div>
        </v-list>

        <v-list class="mt-3 mb-0">
          <div>
            <v-btn
             v-if="user == 1"
              href="https://devpanelc2c.web.app/files/ManualOperador.pdf"
              download
              text
              color="white"
              block
              large
              class="px-3 d-flex flex-row justify-start align-center"
            >
              <v-icon color="#2250b3" class="mr-4"
                >mdi-book-open-variant</v-icon
              >
              <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
                Manual Operador
              </p>
            </v-btn>
            <v-btn
               v-if="user == 4 || user == 3"
              href="https://devpanelc2c.web.app/files/ManualAdministracion.pdf"
              download
              text
              color="white"
              block
              large
              class="px-3 d-flex flex-row justify-start align-center"
            >
              <v-icon color="#2250b3" class="mr-4"
                >mdi-book-open-variant</v-icon
              >
              <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
                Manual Administración
              </p>
            </v-btn>
          </div>
        </v-list>

        <v-list class="mt-n2">
          <div>
            <v-btn
              text
              color="white"
              block
              @click="statusUser(), Disconnect()"
              large
              class="px-3 d-flex flex-row justify-start align-center"
            >
              <v-icon color="#2250b3" class="mr-4">mdi-logout</v-icon>
              <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
                Cerrar Sesion
              </p>
            </v-btn>
          </div>
        </v-list>
      </v-container>
    </v-navigation-drawer>
    <AvisoVideoLlamada
      style="display: none"
      :room="room"
      :datos="datos"
      :info="datos.id"
    />
    <AvisoLimites v-if="vigilancia" style="display: none" />
  </v-container>
</template>

<script>
import {
  getDatabase,
  ref,
  onValue,
  set,
  remove,
  onDisconnect,
} from "firebase/database";

// import Register from './RegisterUser/Register.vue'
import { userData, statusUser } from "../../helpers/api/calls";
// import VideoEmail from "./StaticPages/VideoEmail/VideoMail.vue";
import AvisoVideoLlamada from "./StaticPages/Vigilancia/AvisoVideoLlamada.vue";
import Billetera from "./StaticPages/Billetera.vue";
import CodigoReferido from "./StaticPages/CodigoReferido.vue";
import WhatsappForm from "./StaticPages/WhatsappForm/WhatsappForm.vue";
import PrivateVideoCall from "./StaticPages/privateVideoCall/PrivateVideoCall.vue";
import MyDate from "./StaticPages/MyDate/MyDate.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import AvisoLimites from "./Avisos/AvisoLimites.vue";
import ShareLink from "./StaticPages/privateVideoCall/ShareLink.vue";
export default {
  components: {
    // Register,
    // VideoEmail,
    AvisoVideoLlamada,
    Billetera,
    CodigoReferido,
    WhatsappForm,
    PrivateVideoCall,
    MyDate,
    AvisoLimites,
    ShareLink
  },
  data() {
    return {
      loading: true,
      datos: {},
      room: null,
      rol: " ",
      menu: null,
      items: [],
      right: null,
      menu: [],
      dashboard: null,
      administracion: null,
      billetera: null,
      videoEmail: null,
      cuentas: null,
      vigilancia: null,
      codigoReferido: null,
      videoLlamada: null,
      enviarWsp: null,
      misDatos: null,
      idUsuario:null
    };
  },
  computed: {
    ...mapState(["userData"]),
    user(){
      return this.idUsuario = this.userData.id
    }
  },
  created() {
    this.companyData();
  },
  mounted() {
    this.$store.commit("setCardState", null);
  },
  watch: {
    room(newValue) {
      if (newValue) {
        this.$store.commit("setCardAlertModal", true);
      }
    },
  },

  methods: {
    ...mapActions(["limit"]),
    openModalDocument() {
      this.$router.push({name:'DocOperador'})
    },

    goMisPagos() {
      this.$router.push({ name: "misPagos" });
    },

    async statusUser() {
      const self = this;
      statusUser({ id: this.datos.id, status_id: 3 })
        .then((resp) => {
          if (resp) {
            // console.log("funciono status")
            this.$router.push({ name: "Login" });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    Disconnect() {
      const db = getDatabase();
      set(
        ref(db, "connections/" + this.datos.company_id + "/" + this.datos.id),
        {
          id_user: this.datos.id,
          name: this.datos.name,
          phone_number: this.datos.phone_number,
          email: this.datos.email,
          status: false,
          ocupacion: this.datos.roles[0].name,
          specialities: this.datos.specialities,
        }
      );
    },

    goTo(item) {
      if (item.route) {
        this.$router.push({ name: item.route });
      } else if (item.external) {
        window.open(item.external, "_blank");
      }
    },

    async companyData() {
      const data = await userData();
      // console.log(data);
      (this.loading = false), (this.datos = data.data);

      this.alertCall();
      this.rol = data.data.roles[0].id;
      this.menu = data.data.roles[0].permissions.map((obj) => obj.id);
    },

    validador() {
      this.menu = this.$store.state.userData.roles[0].permissions.map(
        (obj) => obj.id
      );
    },

    alertCall() {
      const db = getDatabase();
      const starCountRef = ref(
        db,
        "connections/" +
          "PrivateOperators/" +
          this.datos.company_id +
          "/" +
          this.datos.id
      );
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          this.room = data;
        }
      });
    },
  },
};
</script>

<style>
</style>
