<template>
  <v-container>
    <v-card>
      <v-card-title style="color: #3364d0">
        Información General
        <v-spacer></v-spacer>
      </v-card-title>
      <v-container class="d-flex row">
        <v-select
          outlined
          clearable
          class="px-5"
          style="width: 25%"
          label="tipo de atención"
          :items="['Videocall', 'Callback', 'Videomail', 'Contact']"
          v-model="typeAtention"
        />
        <v-text-field
          class="px-5"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar Operador"
          single-line
          outlined
          hide-details
        ></v-text-field>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6" xl="5">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="dateRangeFilter"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateRangeText"
                  label="Seleccione una fecha"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  clearable
                  full-width
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="dateRangeFilter = []"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateRangeFilter"
                :max="new Date().toJSON()"
                reactive
                range
                no-title
                locale="es-cl"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="changeDate"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
        :headers="headers"
        :items="filtroAtencion"
        :items-per-page="10"
        :search="search"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | toDate }} {{ item.created_at | Hour }}
        </template>
        <template v-slot:[`item.tiempo`]="{ item }">
          <div v-if="item.attentionable == null">S/info</div>
          <div v-else-if="item.attentionable.CallDuration">
            {{ item.attentionable.CallDuration }} s
          </div>
          <div v-else>
            {{ item.attentionable.connected_at | toHour }} a
            {{ item.attentionable.ended_at | toHour }}
          </div>
        </template>
        <template v-slot:[`item.attentionable_type`]="{ item }">
          {{
            item.attentionable_type.slice(11) == "Contact"
              ? "Contacto"
              : item.attentionable_type.slice(11)
          }}
        </template>
        <template v-slot:[`item.profesional`]="{ item }">
          <div v-if="item.user != null">
            <p>{{ item.user.name }}</p>
          </div>
          <div v-else-if="[item.response != null]">
            <p>Sin información</p>
          </div>
          <div v-else>Sin Información</div>
        </template>

        <template v-slot:[`item.observacion`]="{ item }">
          <v-btn
            v-if="item.attentionable_type.slice(11) !== 'Contact'"
            color="#3364d0"
            @click="setInfo(item)"
            class="ml-3"
            icon
            ><v-icon>mdi-information-outline</v-icon></v-btn
          >
          <v-btn
            v-if="item.attentionable_type.slice(11) == 'Callback'"
            color="#3364d0"
            @click="getAudioOrVideo(item)"
            class="ml-3"
            icon
            ><v-icon>mdi-play-circle-outline</v-icon></v-btn
          >
          <v-btn
            v-else-if="item.attentionable_type.slice(11) == 'Videocall'"
            color="#3364d0"
            @click="getAudioOrVideo(item)"
            class="ml-3"
            icon
            ><v-icon>mdi-play-circle-outline</v-icon></v-btn
          >
          <v-btn
            v-else-if="item.attentionable_type.slice(11) == 'Contact'"
            color="#feb62d"
            @click="getInfoContact(item)"
            class="ml-3"
            icon
            ><v-icon>mdi-information-outline</v-icon></v-btn
          >
        </template>
      </v-data-table>

      <v-dialog hide-overlay :retain-focus="false" width="800" v-model="obs">
        <v-container style="background: white">
          <div class="mb-16">
            <v-btn color="#3364d0" icon @click.stop="obs = false"
              ><v-icon size="30">mdi-close</v-icon></v-btn
            >
            <p style="color: #3364d0" class="text-center display-2">
              Observación
            </p>
          </div>
          <div class="text-center">
            <p style="color: #3364d0" class="display-1 text-center">
              ¿Que solicitaba el cliente?
            </p>
            <p class="title">{{ request }}</p>
          </div>

          <div class="text-center">
            <p style="color: #3364d0" class="display-1 text-center">
              ¿Que hizo el Operador?
            </p>
            <p class="title">{{ action }}</p>
          </div>
          <div class="text-center">
            <p style="color: #3364d0" class="display-1 text-center">
              ¿Cual fue la solución?
            </p>
            <p class="title">{{ solution }}</p>
          </div>
        </v-container>
      </v-dialog>

      <v-dialog
        persistent
        hide-overlay
        :retain-focus="false"
        width="600"
        v-model="audio"
      >
        <v-container style="background: white">
          <div class="mb-5">
            <v-btn color="#3364d0" icon @click.stop="closeStop()"
              ><v-icon size="30">mdi-close</v-icon></v-btn
            >
            <p style="color: #3364d0" class="text-center display-2">
              Grabación
            </p>
          </div>
          <div class="d-flex justify-center mb-10">
            <mini-audio ref="audiio" :audio-source="recording"></mini-audio>
          </div>
        </v-container>
      </v-dialog>
      <v-dialog width="800" v-model="cont">
        <v-card>
          <v-btn color="black" icon @click="cont = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-container>
            <p class="display-1 text-center">Información Enviada</p>
            <div>
              <p class="title">Asunto:</p>
              <p v-if="solutionContact == null" class="subtitle-1">
                Sin información
              </p>
              <p v-else>{{ solutionContact.subject }}</p>
            </div>
            <div>
              <p class="title">Mensaje:</p>
              <p v-if="solutionContact == null" class="subtitle-1">
                Sin información
              </p>
              <p v-else>{{ solutionContact.message }}</p>
            </div>
          </v-container>
        </v-card>
      </v-dialog>
      <GrabacionesVideo />
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import { getAudioOrVideo, getAllInformation } from "@/helpers/api/calls";
import GrabacionesVideo from "../../../TablasInformativas/GrabacionesVideo.vue";
// import { registroUser, getVideoCall } from "../../../helpers/api/calls";
export default {
  filters: {
    toDate: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },
    nameFilter: function (value) {
      return value.slice(11);
    },
    toHour: function (date) {
      if (!date) {
        return "-";
      }
      return moment(date).format("HH:mm");
    },
    Hour: function (date) {
      if (!date) {
        return "-";
      }
      return moment(date).format("HH:mm A");
    },
  },
  data() {
    return {
      cont: false,
      solutionContact: [],
      dateRangeFilter: [],
      menu2: false,
      modal: false,
      between: null,

      typeAtention: null,
      typeOperador: null,
      search: "",
      dateId: [],
      info: [],
      obs: false,
      audio: false,
      recording: null,
      detener: null,
      request: null,
      action: null,
      solution: null,
    };
  },
  computed: {
    dateRangeText() {
      return this.dateRangeFilter.join(" - ");
    },
    filtroAtencion() {
      return this.dateId.filter((i) => {
        const typeFilter =
          !this.typeAtention ||
          i.attentionable_type.includes(this.typeAtention);
        const operatorFilter =
          !this.search ||
          (i.user &&
            i.user.name.toLowerCase().includes(this.search.toLowerCase()));

        let dateFilter = true;
        if (this.dateRangeFilter.length === 2) {
          dateFilter = moment(i.created_at).isBetween(
            this.dateRangeFilter[0],
            this.dateRangeFilter[1]
          );
        }
        return typeFilter && operatorFilter && dateFilter;
      });
    },

    headers() {
      return [
        {
          text: "Fecha y Hora",
          value: "created_at",
        },
        { text: "Tiempo", value: "tiempo" },
        { text: "Tipo de Atención", value: `attentionable_type` },
        { text: "Profesional", value: "profesional" },
        { text: "Observacion", value: "observacion" },
      ];
    },
  },

  mounted() {
    this.getAll();
  },
  methods: {
    getInfoContact(item) {
      console.log(item);
      this.solutionContact = item.response;
      this.cont = true;
    },
    async getAll() {
      const data = await getAllInformation();
      console.log("InformacionGeneral", data);
      const array1 = data.data.detailed_attentions;
      const array2 = data.data.full_attended_contacts;
      this.dateId = array1.concat(array2);
    },
    async getAudioOrVideo(item) {
      // console.log("Information item",item.id);
      const information = await getAudioOrVideo({ id: item.id });
      // console.log(information.data);
      if (item.attentionable_type.slice(11) == "Callback") {
        this.recording = information.data.attentionable.recordings[0].url;
        this.audio = true;
      } else if (item.attentionable_type.slice(11) == "Videocall") {
        this.$store.commit(
          "setSrcVideoRecording",
          information.data.attentionable.recordings
        );
        this.$store.commit("setModalVideos", true);
      }
    },
    setInfo(item) {
      this.obs = true;
      this.request = item.request;
      this.action = item.action;
      this.solution = item.solution;
      // console.log("Dialog",item);
    },
    closeStop() {
      this.audio = false;
      this.$refs.audiio.stop();
      // console.log("Probando");
    },
    changeDate() {
      this.$refs.menu2.save(this.dateRangeFilter);
    },
  },
  components: { GrabacionesVideo },
};
</script>

<style>
.iconfont icon-speed-1 rate {
  display: none;
}
</style>
