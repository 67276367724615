<template>
  <v-navigation-drawer
    right
    
    absolute
    temporary
    v-model="drawer"
    :width="$vuetify.breakpoint.mobile ? '100%' : '40%'"
  >
    <v-container>
      <v-list-item>
        <v-list-item-content>
          <v-container class="mb-n5 text-end">
            <v-btn color="black" icon @click="drawer = false">
              <v-icon size="30">mdi-close</v-icon>
            </v-btn>
          </v-container>
          <v-list-item-title class="text-h4 my-10">
            Su compra actual: ${{ total }}
          </v-list-item-title>
          <v-btn @click="goToPayment" depressed large outlined color="success">
            Comprar
          </v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-container md="12" lg="12">
        <v-row dense>
          <v-col v-for="(item, index) in items" :key="index" cols="12">
            <v-card>
              <v-card-title class="text-h5 pb-0">
                <p>{{ item.name }}</p>
              </v-card-title>

              <v-card-actions>
                <v-card-subtitle class="text-h6"> Precio: </v-card-subtitle>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-btn text class="text-h6 success">
                        ${{ item.price }}
                      </v-btn>
                    </v-col>
                    <v-col>
                      <div class="text-end">
                        <v-btn outlined @click="deleteItem(index)" color="red" icon>
                          <v-icon> mdi-delete </v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import store from "@/store/index";
import { startPayment } from "../../../helpers/api/payments/payment";
import { mapState } from 'vuex';

export default {
  components: {},
  watch: {
    "$store.state.paymentWidget"() {
      this.drawer = true;
    },
    "$store.state.paymentItems"(newValue) {
      this.items = newValue;
    },

    items(newValue) {
      console.log(newValue);
      if (newValue) {
        let sumaTotal = newValue
          .map((item) => item.price)
          .reduce((prev, curr) => prev + curr, 0);
        this.total = sumaTotal;
        // console.log(sumaTotal);
      }
    },
  },
  data() {
    return {
      items: store.state.paymentItems,
      drawer: store.state.paymentWidget,
      total: 0,
    };
  },
  computed: {
    // ...mapState('payPlan',['modalCart'])
  },
  methods: {
    async goToPayment() {
      const response = await startPayment(this.items);
      const { flowData } = await response;
      if (["url", "token"].every((value) => value in flowData))
        window.location.href = `${flowData.url}?token=${flowData.token}`;
    },
    deleteItem(index) {
      this.$delete(this.items, index);
    },
  },
  mounted() {},
};
</script>
