<template>
  <v-container fluid style="background: #eaeef2; height: 100%">
    <div>
      <v-row :class="$vuetify.breakpoint.mobile ? 'ma-5':'ma-5 d-flex justify-space-between'">
        <v-col class="py-0" md="8" xl="8" lg="8" cols="12">
            <BodyInformacionGeneral/>
        </v-col>

        <v-col class="hidden-sm-and-down"  cols="4">
          <DrawerRight class="d-none d-sm-flex"/>
        </v-col>
      </v-row>      
    </div>
  </v-container>
</template>

<script>
import BodyInformacionGeneral from './components/Body.vue'
import DrawerRight from '../../DrawerLateralDerecho/DrawerRight.vue'
export default {
    components: {
        DrawerRight,
        BodyInformacionGeneral,
    },
};
</script>

<style>
</style>