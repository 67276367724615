var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"width":"1000","persistent":""},model:{value:(this.$store.state.modalEmailRecibido),callback:function ($$v) {_vm.$set(this.$store.state, "modalEmailRecibido", $$v)},expression:"this.$store.state.modalEmailRecibido"}},[_c('v-card',[_c('v-container',{staticClass:"d-flex flex-columns"},[_c('div',[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"#2250b3","icon":""},on:{"click":function($event){return _vm.$store.commit('setEmailRecibido', false)}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v(" mdi-close ")])],1)],1),_c('div',{staticClass:"ml-5"},[_c('p',{staticClass:"mt-3 subtitle-1 font-weight-regular",staticStyle:{"color":"#2250b3"}},[_vm._v(" VideoEmails Recibidos ")])]),_c('div',{staticClass:"mx-auto"},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"label":"Buscar VideoEmail","outlined":""}})],1)]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"disable-sort":"","items-per-page":5},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.created_at))+" "+_vm._s(_vm._f("toHour")(item.created_at))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.email)+" ")]}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client.phone_number)+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-n2",attrs:{"color":"#2250b3","elevation":"1","outlined":"","icon":""},on:{"click":function($event){return _vm.irVideoEmail(item)}}},[_c('v-icon',[_vm._v("mdi-email-fast")])],1)]}}],null,true)},[void 0],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }