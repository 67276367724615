<template>
  <v-container fluid style="background: #eaeef2; height: 100%" class="pa-0 ">
    <div :class="$vuetify.breakpoint.mobile ? '' : 'd-flex flex-row'">
      <div hidden>
        <Drawer class="d-none d-sm-flex" />
        <DrawerMobile style="position:absolute;top:0px;left:0px" class="d-flex d-sm-none" />
      </div>
      <v-row class="ma-5 d-flex justify-space-between">
        <v-col cols="12">
          <payment-status-modal :payment="payment" />
        </v-col>
        <v-col hidden cols="0">
          <DrawerRight class="d-none d-sm-flex" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Drawer from "../../Drawer.vue";
import DrawerMobile from "../../DrawerMobile.vue";
import DrawerRight from "../../DrawerLateralDerecho/DrawerRight.vue";
import PaymentStatusModal from "./PaymentStatusModal.vue";

import { getPayment } from "../../../../helpers/api/payments/payment";

export default {
  name: "FlowReturn",
  components: {
    DrawerMobile,
    Drawer,
    DrawerRight,
    PaymentStatusModal,
  },
  data() {
    return {
      payment: false,
      modal: false,
      settings: {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        baseUrl: "http://localhost:8000/file-manager/",
        windowsConfig: 2,
        lang: "es",
      },
    };
  },
  methods: {
    getPaymentData(token) {
      const response = getPayment(token)
        .then((res) => {
          this.payment = res;
        })
        .catch((err) => {
          this.payment = true;
        });
    },
  },
  mounted() {
    console.log(`Bearer ${window.localStorage.getItem("userToken")}`);
    if (this.$route.query && this.$route.query.token) {
      this.getPaymentData(this.$route.query.token);
    }
  },
};
</script>

<style></style>
