<template>
    <v-container>
        <v-dialog persistent width="800" v-model="this.$store.state.modalAviso">
            <v-container style="background:white">
                <div class="mt-5">
                    <p style="color:#2250b3" class="display-1 text-center">¡Se te han agotado los creditos de tu
                        servicio!</p>
                </div>
                <v-container class="title  mt-10">
                    <p class="text-center" style="color:#2250b3">{{
                            !unlimited
                                ? "Si quieres seguir usando nuestros servicios, favor de recargar tu saldo o contratar un mejor plan con mas saldo."
                                : "Pero no te preocupes, tu plan es ilimitado y solo se te cobrarán los recursos adicionales que uses sobre tu plan actual"
                    }}</p>
                    <p style="color:#2250b3" class="display-1 text-center mt-10">Detalles</p>
                    <div>
                        <p>Llamadas</p>
                        <v-progress-linear height="10" :buffer-value="comparacion.callback_time"
                            :value="information.available_callbacks_time"></v-progress-linear>
                    </div>
                    <div class="mt-5">
                        <p>VideoMails</p>
                        <v-progress-linear height="10" :buffer-value="comparacion.videomails_data"
                            :value="information.available_videomails_data"></v-progress-linear>
                    </div>
                    <div class="mt-5">
                        <p>Mensajes Whatsapp</p>
                        <v-progress-linear height="10" :buffer-value="comparacion.whatsapp_messages"
                            :value="information.available_whatsapp_messages"></v-progress-linear>
                    </div>
                    <div class="mt-5">
                        <p>VideoLlamadas</p>
                        <v-progress-linear height="10" :buffer-value="comparacion.videocall_time"
                            :value="information.available_videocalls_time"></v-progress-linear>
                    </div>
                    <div class="text-center">
                        <v-btn style="color:white" color="#2250b3" @click="$store.commit('setModalAviso', false)"
                            width="150" height="50" class="mt-10 rounded-xl">
                            Entiendo
                        </v-btn>

                    </div>
                </v-container>

            </v-container>
        </v-dialog>
    </v-container>
</template>

<script>
import { getLimitCompany } from '@/helpers/api/calls.js'
export default {
    data() {
        return {
            unlimited: this.$store.state.infoLimit.unlimited,
            information: this.$store.state.infoLimit,
            comparacion: 0,
        }
    },
    mounted() {
        // console.log("Information",this.information);
        // this.getLimit();
    },
    methods: {
        async getLimit() {
            const data = await getLimitCompany();
            const info = data.data
            this.comparacion = info
            // console.log("comparacion",this.comparacion);


        }
    },


}
</script>

<style>
</style>