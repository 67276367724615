<template>
  <v-container style="background:#eaeef2;height:100%" fluid>
    <div>
      <div
        :class="$vuetify.breakpoint.mobile ? '' : 'ma-5'"
      >
        <Drawer class="d-none d-md-flex" />
        <DrawerMobile v-if="$vuetify.breakpoint.mobile" class="d-flex d-md-none" />
      </div>
    </div>
  </v-container>
</template>

<script>
import Drawer from "../components/Panel/Drawer.vue";
import DrawerMobile from "../components/Panel/DrawerMobile.vue";
export default { components: { Drawer, DrawerMobile } };
</script>

<style>
</style>