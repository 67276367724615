<template>
  <v-container>
    <v-dialog max-width="700" v-model="wsp">
      <v-card class="px-4">
        <v-card-text>
          <div class="text-end pt-3">
            <v-btn text @click="wsp = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
          <div class="text-center pb-5">
            <p class="display-1">WhatsApp</p>
          </div>
          <v-form ref="whtasappform" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  filled
                  rounded
                  v-model="name"
                  label="Nombre"
                  :rules="[rules.name]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  filled
                  :rules="[rules.phone]"
                  rounded
                  v-model="phone"
                  label="Numero Telefonico"
                  required
                ></v-text-field>
              </v-col>

              <v-spacer></v-spacer>
              <v-col class="mx-auto" cols="12" sm="3" xsm="12">
                <v-btn
                  x-large
                  block
                  rounded
                  :disabled="!disnableBtn"
                  text
                  style="color: #81b322; border: solid 1px #81b322"
                  @click="sendWsp()"
                  >Enviar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-snackbar timeout="4000" :color="color"  v-model="msn">
        <p style="color:white" class="title text-center">{{message}}</p>
      </v-snackbar>
    </v-dialog>

    <div>
      <div class="mb-n8 ml-n3">
        <v-btn
          text
          color="white"
          block
          
          large
          class="px-3 d-flex flex-row justify-start align-center"
          @click="wsp = true"
        >
          <v-icon color="#2250b3" class="mr-4">mdi-whatsapp</v-icon>
          <p style="color: #2250b3" class="mb-0 ml-n1 subtitle-1 text-capitalize">
            Enviar WhatsApp
          </p>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { postWsp } from "../../../../helpers/api/calls";
import {
  getDatabase,
  ref,
  onValue,
  set,
  onChildChanged,
  remove
} from "firebase/database";
export default {
  data() {
    return {
      info: "",

        msn:false,
        color:null,
        valid:null,
        wsp:null,
        name:'',
        phone:`569`,
        message:'',

      rules:{
       phone: (v) => (v && v.length >= 9) || "Debe contener el codigo de país y debe contener los 9 digitos",
       name: (v) => !!v || "El nombre es requerido",
      }  

    };
  },
  mounted() {
    // this.userData();
  },
watch: {
    '$store.state.userData'(newValue) {
      if(newValue){
        this.userData()
      }
      
    }
  },  
  computed: {
    disnableBtn() {
      return this.name != '' &&
      this.phone != `569` &&
      this.phone != ''  &&
      this.phone > 9
    }
  },
  methods: {
     userData() {
      this.info = this.$store.state.userData;
    },
  CallPrivate() {
      this.insertDb();
      this.$router.push({
        name: "NuevoSocketVideo",
        params: { sala_id: this.phone, id: this.info.id },
      });
    },
    insertDb() {
      const db = getDatabase();
      set(
        ref(
          db,
          "connections/" +
            "PrivateWsp/" +
            this.info.company_id +
            "/" +
            this.phone
        ),
        {
          sala_id: this.phone,
        }
      );
    }, 
    delereRoom() {
      const db = getDatabase();
      remove(
        ref(
          db,
          "connections/" +
            "PrivateWsp/" +
            this.info.company_id +
            "/" +
            this.phone
        ),
      );

      console.log("funciona?");
    },       

    async sendWsp(){
      postWsp({name: this.name, phone: this.phone })
      .then((resp) => {
        // console.log("Funciono: ",resp)
        this.msn = true
        this.color = "#4caf50"
        this.message = 'WhatsApp Enviado Con Exito'
        setTimeout(() => {
          this.wsp = false
          // console.log("LlegaAqui")
          this.$refs.whtasappform.reset()
        }, 4000);
        this.CallPrivate()
        this.delereRoom()
        
        
      })
      .catch((err) => {
        console.log("error whasapp: ",err)
        this.msn = true
        this.color = "#f44336"
        this.message = 'Error al enviar un WhatsApp, Verificar Datos.'
        setTimeout(() => {
          this.wsp = false
          this.$refs.whtasappform.reset()
        }, 4000);
        
      });
    }
  },
};
</script>

<style scoped>

</style>