<template>
  <div>
      <v-app-bar-nav-icon style="position:fixed;left:0px;background:white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-navigation-drawer

      v-model="drawer"
      floating
      fixed
      max-width="300"
      style="background-color: white;"
    >
      <v-list-item>
        <v-row class="mb-5 mt-5">
          <v-col>
            <div>
              <v-img
                class="mx-auto"
                eager
                width="200"
                :src="require('../../assets/Banner.png')"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-list-item>

      <v-list v-if="this.dashboard">
        <div>
          <v-btn
            text
            color="white"
            block
            large
            class="px-3 d-flex flex-row justify-start align-center"
            to="/DashBoard"
          >
            <v-icon color="#2250b3" class="mr-4">mdi-monitor</v-icon>
            <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
              Menu
            </p>
          </v-btn>
        </div>
      </v-list>

      <v-list v-if="this.administracion">
        <div>
          <v-btn
            text
            color="white"
            block
            large
            class="px-3 mt-n2 mb-n2 d-flex flex-row justify-start align-center"
            to="/Administracion"
          >
            <v-icon color="#2250b3" class="mr-4">mdi-file-document-edit</v-icon>
            <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
              Administracion
            </p>
          </v-btn>
        </div>
      </v-list>

      <v-list v-if="this.billetera" class="text-center mb-3 mt-n4">
        <div>
          <Billetera />
        </div>
      </v-list>

      <v-list>
        <div>
          <v-btn
            text
            color="white"
            block
            large
            class="px-3 d-flex flex-row justify-start align-center"
            to="/VideoEmail"
          >
            <v-icon color="#2250b3" class="mr-4">mdi-gmail</v-icon>
            <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
              VideoEmails
            </p>
          </v-btn>
        </div>
      </v-list>

      <v-list v-if="this.cuentas" class="mb-n2">
        <div>
          <v-btn
            text
            color="white"
            block
            large
            class="px-3 d-flex flex-row justify-start align-center"
            to="/Cuentas"
          >
            <v-icon color="#2250b3" class="mr-4">mdi-account-multiple</v-icon>
            <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
              Cuentas
            </p>
          </v-btn>
        </div>
      </v-list>

      <v-list v-if="this.vigilancia" class="mb-n2">
        <div>
          <v-btn
            text
            color="white"
            block
            large
            class="px-3 d-flex flex-row justify-start align-center"
            to="/Vigilancia"
          >
            <v-icon color="#2250b3" class="mr-4">mdi-eye-outline</v-icon>
            <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
              Vigilancia
            </p>
          </v-btn>
        </div>
      </v-list>

      <v-list v-if="this.codigoReferido" class="text-center mb-3 mt-n6">
        <div>
          <CodigoReferido />
        </div>
      </v-list>      
      <v-list v-if="this.videoLlamada && this.$store.state.infoLimit.available_videocalls_time > 0" class="text-center mb-3 mt-n6">
        <div>
          <PrivateVideoCall />
        </div>
      </v-list>      
      <v-list v-if="this.enviarWsp && this.$store.state.infoLimit.available_whatsapp_messages > 0" class="text-center mb-2 mt-n4">
        <div>
          <WhatsappForm/>
        </div>
      </v-list>      
      <v-list v-if="this.misDatos" class="text-center mb-2 mt-n3">
        <div>
          <MyDate/>
        </div>
      </v-list>
      <v-list class="mt-3">
        <div>
          <v-btn
            text
            color="white"
            block
            @click="statusUser(), Disconnect()"
            large
            class="px-3 d-flex flex-row justify-start align-center"
            
          >
            <v-icon color="#2250b3" class="mr-4">mdi-logout</v-icon>
            <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
              Cerrar Sesion
            </p>
          </v-btn>
        </div>
      </v-list>      
    </v-navigation-drawer>
    <AvisoVideoLlamada style="display:none" :room="room" :datos="datos" :info="datos.id"/>
    <AvisoLimites v-if="vigilancia" style="display:none"/>    
  </div>
</template>

<script>
import {
  getDatabase,
  ref,
  onValue,
  set,
  remove,
  onDisconnect
} from "firebase/database";

// import Register from './RegisterUser/Register.vue'
import { userData, statusUser } from "../../helpers/api/calls";
// import VideoEmail from "./StaticPages/VideoEmail/VideoMail.vue";
import AvisoVideoLlamada from "./StaticPages/Vigilancia/AvisoVideoLlamada.vue";
import Billetera from "./StaticPages/Billetera.vue";
import CodigoReferido from "./StaticPages/CodigoReferido.vue";
import WhatsappForm from "./StaticPages/WhatsappForm/WhatsappForm.vue";
import PrivateVideoCall from "./StaticPages/privateVideoCall/PrivateVideoCall.vue";
import MyDate from "./StaticPages/MyDate/MyDate.vue";
import { mapActions } from 'vuex';
import AvisoLimites from "./Avisos/AvisoLimites.vue";
export default {
  components: {
    // Register,
    // VideoEmail,
    AvisoVideoLlamada,
    Billetera,
    CodigoReferido,
    WhatsappForm,
    PrivateVideoCall,
    MyDate,
    AvisoLimites
},
  data() {
    return {
      drawer: false,
      rol: "",
      value: false,      
      datos: {},
      room:null,      
      rol: " ",
      menu : null,
      items: [
        // {
        //   title: "DashBoard",
        //   icon: "mdi-monitor",
        //   route: "DashBoard",
        // },
      ],
      right: null,

      menu:[],
      dashboard:null,
      administracion:null,
      billetera:null,
      videoEmail:null,
      cuentas:null,
      vigilancia: null,
      codigoReferido: null,
      videoLlamada: null,
      enviarWsp: null,
      misDatos: null,
    };
  },
  mounted() {
    // this.userData();
    this.$store.commit("setCardState", null);
  },
  watch: {
    room(newValue) {
      if(newValue)
      console.log(newValue)
      this.$store.commit('setCardAlertModal', true)
    },
    '$store.state.userData'(newValue){
      if(newValue){
        this.userData()


      }
    }
  },
  methods: {
    async statusUser() {
      const self = this;
      statusUser({ id: this.datos.id, status_id: 3 })
        .then((resp) => {
          if(resp){
            // console.log("funciono status")
            this.$router.push({ name: 'Login' , });
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    Disconnect() {
      const db = getDatabase();
      set(ref(db, "connections/" + this.datos.company_id + "/" + this.datos.id), {
        id_user: this.datos.id,
        name: this.datos.name,
        phone_number: this.datos.phone_number,
        email:this.datos.email,
        status: false,
        ocupacion: this.datos.roles[0].name,
        specialities: this.datos.specialities,
      });
    },    

  

    goTo(item) {
      if (item.route) {
        this.$router.push({ name: item.route });
      } else if (item.external) {
        window.open(item.external, "_blank");
      }
    },

    userData() {
      this.rol = this.$store.state.userData.roles[0].id;
      this.datos = this.$store.state.userData;
      this.alertCall();
      this.menu = this.$store.state.userData.roles[0].permissions.map((obj) => obj.id)
      this.dashboard = this.menu.includes(1)
      this.administracion = this.menu.includes(2)
      this.billetera = this.menu.includes(3)
      this.videoEmail = this.menu.includes(4)
      this.cuentas = this.menu.includes(5)
      this.vigilancia = this.menu.includes(6)
      this.codigoReferido = this.menu.includes(7)
      this.videoLlamada = this.menu.includes(8)
      this.enviarWsp = this.menu.includes(9)
      this.misDatos = this.menu.includes(10)

      // console.log(this.dashboard);
      // console.log(this.menu);



    },

    alertCall() {
      const db = getDatabase();
      const starCountRef = ref(
        db, "connections/" + "PrivateOperators/" + this.datos.company_id + "/" + this.datos.id
      );
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          this.room = data
        }
      });
    },     
  },
};
</script>

<style>
</style>