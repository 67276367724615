<template>
  <v-container>
		<v-card
			style="border: solid 1px #2250b3"
			elevation="0"
		>
			<v-card-title class="my-0 py-1 d-flex justify-space-between" style="background-color: #2250b3">
				<div class="d-flex">
					<p class="white--text mb-0 body-2 font-weight-bold">Especialidades</p>
					<div class="mx-2">
						<AddEspecialidad :reload="getEspeciality" />
					</div>
				</div>
				<v-btn @click="showContent = !showContent" icon x-small color="white">
					<v-icon>{{showContent ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="py-2 px-3" v-show="showContent">
				<v-list dense>
					<v-list-item-group v-for="(item, index) in especiality" :key="index">
						<v-list-item dense
								class="py-1"
								style="min-height: 40px">
							<v-list-item-content class="py-0">
								<v-list-item-title>{{ item.name }}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action class="my-0">
								<div class="d-flex">
									<DeleteEspecialidad
										:id="item.id"
										:nombre="item.name"
										:reload="getEspeciality"
									/>
									<UpdateEspecialidad
										:id="item.id"
										:nombre="item.name"
										:reload="getEspeciality"
										:desciption="item.description"
									/>
								</div>
							</v-list-item-action>
						</v-list-item>
						<v-divider
							v-if="index < especiality.length - 1"
							:key="index"
						></v-divider>
					</v-list-item-group>
				</v-list>
			</v-card-text>
		</v-card>
  </v-container>
</template>

<script>
import AddEspecialidad from "./AddEspecialidad.vue";
import { getAdminEsp } from "@/helpers/api/calls";
import DeleteEspecialidad from "./DeleteEspecialidad.vue";
import UpdateEspecialidad from "./UpdateEspecialidad.vue";
export default {
  name:'IndexSpeciality',
  components: { AddEspecialidad, DeleteEspecialidad, UpdateEspecialidad },
  data() {
    return {
      especiality: [],
      loading:false,
			showContent: false
    };
  },
  created() {
    this.getEspeciality();
  },
  methods: {
    async getEspeciality() {
    this.loading = true
      const data = await getAdminEsp();
      this.especiality = data.data;
      this.loading = false
      // console.log(data.data);
    },
  },
};
</script>

<style>
</style>