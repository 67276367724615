import { endpoints } from "./endpoints";
import request from "@/helpers/request";

export function startPayment(items) {
  return new Promise((resolve, reject) => {
    const data = {
      items,
    };
    request({
      url: endpoints.startPayment,
      method: "POST",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

export function getPayment(token) {
  return new Promise((resolve, reject) => {
    request({
      url: `${endpoints.getPaymentByToken}/${token}`,
      method: "GET",
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

export function getInvoice(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getInvoice + '/' + data.id + '/invoice',
      method: 'get',
      
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getPaymentInv(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getPaymentInv + '/' + data.id + '/invoice/' + data.idInvoice + '/pay',
      method: 'POST',
      data
      
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function postAdvancePay(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.postAdvancePay + '/' + data.id + '/plan/payInAdvance',
      method: 'POST',
      data
      
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
