<template>
  <v-container fluid style="background: #eaeef2; height: 100%">
    <div>
      <v-row class="ma-5">
        <v-col class="pt-6" sm="8" md="8" cols="12"> 
          <IndexComponent />
        </v-col>
        <v-col class="px-10" cols="4" >
          <DrawerRight class="d-none d-sm-flex" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import DrawerRight from "../DrawerLateralDerecho/DrawerRight.vue";
import IndexComponent from "./Components/IndexComponent.vue";
export default {
  components: {
    DrawerRight,
    IndexComponent
},
};
</script>

<style>
</style>