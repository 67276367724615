<template>
  <v-container class="pa-0">
    <v-dialog width="500" v-model="add">
      <v-container style="background: white">
        <div>
          <v-btn color="#2250b3" icon @click.stop="add = false">
            <v-icon size="30"> mdi-close </v-icon>
          </v-btn>
        </div>
        <div class="text-center">
          <p style="color: #2250b3" class="display-1">Agregar un nuevo Email</p>
        </div>

        <v-container>
          <v-form ref="addPhone" v-model="valid">
            <v-select
              class="mb-n3"
              filled
              rounded
              single-line
              v-model="infoSelect"
              :items="users"
              item-text="name"
              label="Personal"
              return-object
            ></v-select>
            <div v-if="infoSelect != ''" class="text-center">
              <p>
                <span class="font-weight-bold">Correo Electronico:</span> <br />
                {{ this.infoSelect.email }}
              </p>
                
                <div class="d-flex flex-row justify-center">
                    <p class="mr-5 font-weight-bold">CC:</p>
                <v-checkbox class="mt-n1" v-model="cc"></v-checkbox>

                </div>
            </div>

            <div class="text-center">
              <v-btn
                style="color: white"
                class="rounded-lg"
                color="#2250b3"
                :disabled="!disnable"
                @click.stop="nuevoEmail()"
                >Agregar nuevo Correo</v-btn
              >
            </div>
          </v-form>
        </v-container>
      </v-container>
    </v-dialog>
    <div>
      <v-snackbar color="#2250b3" timeout="2000" v-model="msn">
        <p
          style="color: white"
          class="text-center subtitle-1 font-weight-normal"
        >
          Numero Añadido
        </p>
      </v-snackbar>
    </div>

    <v-btn @click="(add = true), getUsers()" small dark block tile color="#2250b3">
			<v-icon class="mr-2">mdi-plus-circle</v-icon>
			Agregar
		</v-btn>
  </v-container>
</template>

<script>
import {getUsers, addEmail} from '../../../../helpers/api/calls'
export default {
  props: {
    update: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
        msn:false,
        valid:null,
      add: false,
      infoSelect:'',
      information:null,
      users:[],
      cc:false,
      
    };
  },
  computed: {
      disnable() {
          return this.infoSelect != '' 
      }
  },

  methods: {
      async getUsers() {
        const users = await getUsers()
        this.users = users.data
        console.log("usuarios",this.users);
    },
    async nuevoEmail(){
        addEmail({
            email:this.infoSelect.email,
            cc : this.cc,
            company_id:this.infoSelect.company_id

        })
        .then((resp) => {
            if(resp){
                this.add = false
                this.infoSelect = '',
                this.update()
                this.msn = true
                console.log("SE AGREGO NUMERO,",resp);
            }
        }).catch((err) => {
            console.log("ERROR Email",err);
            
        });
    }
  },
};
</script>

<style>
</style>