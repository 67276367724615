<template>
  <v-container fluid style="background: #00000029; height: 100%">
    <v-row class="mt-16" justify="center">
      <v-col cols="8">
        <v-card>
          <v-row>
            <v-col cols="8">
              <div class="d-flex flex-row ml-10">
                <p style="color: #2250b3" class="display-1 mt-6">
                  Video Call Center
                </p>
              </div>

              <v-row class="mt-3">
                <v-col cols="12" class="pr-0 pb-0">
                  <div
                    class="text-center"
                    id="remotes-video"
                    v-show="remoteVideoSet"
                  ></div>
                  <div
                    id="remote-waiting"
                    class="d-flex flex-column justify-center align-center"
                    v-if="!remoteVideoSet"
                  >
                    <v-progress-circular
                      v-if="esperando == 2"
                      indeterminate
                      color="#2250b3"
                    ></v-progress-circular>
                    <p v-if="esperando == 2" class="mb-0">Esperando conexión</p>
                    <p v-if="esperando == 3" class="mb-0">
                      Fin de la video llamada
                    </p>
                  </div>
                </v-col>
              </v-row>

              <v-row class="mt-5 mb-5">
                <v-col cols="12" sm="7">
                  <div class="text-end">
                    <v-btn
                    @click="disconnect(), deleteChat(), $store.commit('setCardFormStateVideoCall',true)"
                      text
                      class="subtitle-2 rounded-xl"
                      height="70"
                      width="230"
                      :style="
                        !$vuetify.breakpoint.mobile
                          ? 'border:solid 2px #2250B3;color:#2250B3;margin-right: -50px;'
                          : 'background-color: #cc2d2d;margin-top: -20px;'
                      "
                      >Terminar Llamada</v-btn
                    >
                  </div>
                </v-col>
                <v-col cols="12" sm="5">
                  <div
                    v-if="localStream"
                    :class="
                      !$vuetify.breakpoint.mobile ? 'text-end ' : 'text-start'
                    "
                    :style="
                      !$vuetify.breakpoint.mobile
                        ? 'margin-top:-150px;margin-left:'
                        : 'margin-top: -69%'
                    "
                    id="seller_room"
                    v-show="localVideoSet"
                  >
                    <video muted autoplay :srcObject.prop="localStream"></video>
                  </div>

                  <div
                    style="
                      background-color: rgb(255, 255, 255);
                      width: 300px;
                      height: 200px;
                    "
                    :class="
                      !$vuetify.breakpoint.mobile
                        ? 'text-end d-flex flex-column justify-center align-center rounded-xl'
                        : 'text-start'
                    "
                    :style="
                      !$vuetify.breakpoint.mobile
                        ? 'margin-top:-180px;margin-left:100px '
                        : 'margin-top: -69%'
                    "
                    id="remote-waiting-local"
                    v-if="!localVideoSet"
                  >
                    <div class="text-center">
                      <v-progress-circular
                        indeterminate
                        color="#2250b3"
                      ></v-progress-circular>
                      <p class="mb-0 mt-5">Esperando conexión</p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="py-0 pl-0" cols="4">
              <v-container
                style="
                  
                  height: 100%;
                  border-top: solid 1px #F7F7F7;
                  border-left: solid 1px #F7F7F7;
                  border-right: solid 1px #F7F7F7;
                "
              >
                <v-row >
                  <v-col style="overflow:auto;height:63vh">
                    <v-container
                      
                      v-for="(messages, index) in listMessage"
                      :key="index"
                      style="color: #022d41;"
                      class="rounded-lg"
                    >
                    
                      <div
                        
                        v-if="messages.user == 1"
                        class="d-flex flex-row-reverse"
                      >
                        <p
                          style="background: #ddd4d4"
                          class="pl-2 pr-5 ml-2 pt-2 pb-0 subtitle-1 rounded-lg"
                        >
                          {{ messages.mensaje }}<br />
                          <span
                            style="font-size: 10px; float: right"
                            class="blue-grey--text text--lighten-1 Datecontent"
                            >{{ messages.createdAt }}</span
                          >
                        </p>
                      </div>
                      <div v-else class="d-flex">
                        <p
                          style="background: #F7F7F7"
                          class="pl-2 pr-5 ml-2 pt-2 pb-0 subtitle-1 rounded-lg"
                        >
                          {{ messages.mensaje }}<br />
                          <span
                            style="font-size: 10px; float: right"
                            class="
                              blue-grey--text
                              text--lighten-2
                              Datecontent
                              ml-10
                            "
                            >{{ messages.createdAt }}</span
                          >
                        </p>
                      </div>
                    </v-container>
                  </v-col>
                </v-row>
                
                <div class="mt-3" style="background: #ffffff;position:absolute;bottom:0px">
                  <v-row class="pt-4">
                    <v-col class="d-flex flex-row" cols="12">
                      <v-btn class="mt-1 mr-1" icon><v-icon size="30" color="#2250B3">mdi-paperclip</v-icon></v-btn>
                      <v-text-field
                        v-on:keyup.13="insertDb()"
                        
                        style="width:250px"
                        filled
                        rounded
                        dense
                        placeholder="Escribe un mensaje aquí"
                        v-model="message"
                      >
                      </v-text-field>
                      <v-btn
                      @click="insertDb()"
                        class="ml-2"
                        rounded
                        height="40"
                        width="40px"
                        style="background: #feb62d"
                        icon
                        :disabled="message === ''"
                      >
                        <v-icon class="ml-1" outlined color="white"
                          >mdi-send</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import {
  connectToSocket,
  joinRoom,
  start,
  createNewReceivingPeer,
  disconnectFromSocket,
} from "@/helpers/Socket/room.js";
import moment from "moment";
import { UpdateInfoVideoCall } from "@/helpers/api/calls";
import { getDatabase, ref, onValue, push, remove } from "firebase/database";
export default { 
  data() {
    return {
      esperando: 2,
      remoteVideoSet: false,
      localVideoSet: false,
      idOperator: null,
      salachat: null,
      connected_at: true,
      ended_at: null,      
      listMessage: [],
      message: "",


      localStream: null,
      media: {
        audio: true,
        video: {
          width: { min: 640, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 720, max: 1080 },
        },
      },
    };
  },

  mounted() {
    const { sala_id } = this.$route.params;
    const { id } = this.$route.params;
    this.$store.commit("setIdRoomForm", sala_id);
    // console.log("id persona", id);
    this.idOperator = id;
    // console.log("IdSala",sala_id);
    if (sala_id) {
      this.startStreaming();
      this.salachat = sala_id;
      // console.log("viendosala",this.salachat);
      this.ListenAlert(sala_id);
      this.UpdateInfoVideoCall();
    }
    this.volver;
  },
  methods: {
    async UpdateInfoVideoCall() {
      const self = this;
      UpdateInfoVideoCall({
        id: this.salachat,
        operator_id: this.idOperator,
        connected_at: this.connected_at,
        ended_at: this.ended_at,
      })
        .then(function (resp) {
          console.log("funciono", resp);
        })
        .catch(function (err) {
          console.log("No Funciono", err);
        });
    },
        clearMessage() {
            this.message = "";
        },    
        deleteChat() {
            const db = getDatabase();
            remove(ref(db, "chat/" + this.salachat));
            // console.log("funciona?");
        },   
        insertDb() {
            const db = getDatabase();
            push(ref(db, "chat/" + this.salachat), {
                mensaje: this.message,
                createdAt: moment(new Date()).format("HH:mm"),
                user: 1
            });
            this.clearMessage();
        },     
        ListenAlert(sala_id) {
            const db = getDatabase();
            const starCountRef = ref(db, "chat/" + sala_id);
            onValue(starCountRef, (snapshot) => {
                const data = snapshot.val();
                this.listMessage = data;
                console.log("dataMENSAJE", this.listMessage);
                // console.log("dataSALACHAT", this.salachat);
            });
        },      
        volvermenu() {
            router.push({ path: "panel" });
        },                  

    startStreaming() {
			const userName = this.$store.state.userData ? this.$store.state.userData.name:'Operador'
      connectToSocket(
        "https://vcsocket.click2call.cl:8443/",
        () => {
          joinRoom(this.salachat, userName, this.idOperator, null, () => this.onJoin());
        },
        (message) => this.handleSocketMessage(message)
      );
      this.localVideoSet = true;
    },
    onJoin() {
      start((localStream) => {
        this.localStream = localStream;
      }, this.media);
    },
    createVideoContainer() {
      const parentVideoElement = document.getElementById("remotes-video");
      const videoElement = document.createElement("video");
      videoElement.id = "remote-video";
      videoElement.autoplay = "autoplay";
      parentVideoElement.appendChild(videoElement);
      this.remoteVideoSet = true;
      return videoElement;
    },
    disconnect(){
      disconnectFromSocket();
      this.ended_at = true;
      this.connected_at = null;
      this.UpdateInfoVideoCall();
      this.localVideoSet = false;
      this.remoteVideoSet = false;   
      this.$router.push({ name: "DashBoard", });         

    },

    onNewUserInRoom(user) {
      console.log("onNewUserInRoom: ", user);
      if (user.mediaFlowingOut) {
        this.onVideoReady(user.socketID);
      }
    },
    onVideoReady(socketID) {
      const videoElement = this.createVideoContainer();
      createNewReceivingPeer(socketID, videoElement);
    },
    onMultipleNewUserInRoom(usersInRoom) {
      usersInRoom.forEach((user) => this.onNewUserInRoom(user));
    },
    handleSocketMessage(message) {
      switch (message.id) {
        case "usersInRoom":
          this.onMultipleNewUserInRoom(message.usersInRoom);
          break;
        case "newUserInRoom":
          this.onNewUserInRoom(message.user);
          console.log("Nuevo Usuario");
          break;
        case "onVideoReady":
          console.log("onVideoReady");
          this.onVideoReady(message.socketID);
          break;
        case "userLeaveRoom":
          console.log("Borrar elementos de video en DOM");
          (this.remoteVideoSet = false), (this.esperando = 3);
          break;
        default:
          console.log("Unrecognized message", message);
      }
    },
  },
};
</script>

<style>
</style>



