<template>
  <v-container>
    <div class="d-flex align-center justify-center mb-10">
      <v-card outlined elevation="0" width="400">
        <v-container class="text-center">
          <div>
            <p style="color: rgb(34, 80, 179)" class="display-1">Mi plan:</p>
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <div v-else>
              <p style="color: rgb(34, 80, 179)" class="display-1">{{ company.name }}</p>
            </div>
          </div>
        </v-container>
      </v-card>
    </div>
    <!-- <OpcionesPlan /> -->
    <DataTable />
  </v-container>
</template>

<script>
import DataTable from "./DataTable.vue";
// import OpcionesPlan from "./OpcionesPlan.vue";
import { getservicesCompany } from "@/helpers/api/calls";
import { mapState } from "vuex";
export default {
  data() {
    return {
      company: "",
      loading:true,
    };
  },
  components: { DataTable,
  //  OpcionesPlan
    },
  computed: {
    ...mapState(["userData"]),
  },
  watch: {
    userData(newValue) {
      if (newValue) {
        this.infoCompany();
      }
    },
  },
  methods: {
    async infoCompany() {
      const data = await getservicesCompany({
        id: this.userData.company_id,
      });
      this.company = data.data.plan;
      this.$store.commit('payPlan/setMyPlan', data.data)
      this.loading = false
      // console.log("InformacionEmpresa", this.company);
    },
  },
};
</script>

<style>
</style>