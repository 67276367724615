<template>
  <v-container fluid style="background:#EAEEF2;height:100%">
    <div>
      <v-row class="pt-5">
        <v-col  sm="8" cols="12">
          <Banner />
          <Cuadro :id="datos.id"/>
          <VideoEmailRecibido class="d-none"/>
          <VideoEmailDerivados class="d-none"/>
          <ModalContact class="d-none"/>
          <IndexTableWsp class="d-none"/>
          <v-row class="d-none">
            <v-col sm="12" md="12" xl="6" cols="12">
              <PersonasAtendidasDia class="d-none" :id="datos.id" />
            </v-col>
            <v-col sm="12" md="12" xl="6" cols="12">
              <PersonasAtendidasMes class="d-none"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="px-10" cols="4" >
          <DrawerRight  />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ModalContact from '../CuadroInformatico/TableContacto/Index.vue'
import IndexTableWsp from '../CuadroInformatico/TableWhatsapp/index.vue'
import DrawerMobile from '../DrawerMobile.vue'
import Drawer from '../Drawer.vue'
import Banner from "../../Panel/BannerSuperior/Banner.vue"
import Cuadro from "./../CuadroInformatico/Cuadro.vue"
import DrawerRight from "./../DrawerLateralDerecho/DrawerRight.vue"
import PersonasAtendidasDia from "./../TablasInformativas/PersonasAtendidasDia.vue"
import PersonasAtendidasMes from "./../TablasInformativas/PersonasAtendidasMes.vue"
import AvisoVideoLlamada from './Vigilancia/AvisoVideoLlamada.vue'
import VideoEmailRecibido from '../TablasInformativas/VideoEmailRecibido.vue'
import VideoEmailDerivados from '../TablasInformativas/VideoEmailDerivados.vue'

export default {
  data() {
    return {
      datos: '',
      room:null,
    };
  },
  components: {
    DrawerMobile,
    Drawer,
    Banner,
    Cuadro,
    DrawerRight,
    PersonasAtendidasDia,
    PersonasAtendidasMes,
    // SocketVideoPanel,
    // ConexionEntrante,
    AvisoVideoLlamada,
    VideoEmailRecibido,
    VideoEmailDerivados,
    IndexTableWsp,
    ModalContact
},

  watch: {
    '$store.state.userData'(newValue) {
      if(newValue){
        this.userData();
      }
    }
  },
  methods: {
      userData() {
      this.datos = this.$store.state.userData;
      // console.log("DATOS!:", this.datos)
    },
  },
};
</script>

<style>

</style>