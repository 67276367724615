<template>
  <v-container>
    <v-dialog persistent width="550" v-model="datosBancarios">
      <v-container style="background:#eaeef2">
        <div>
          <v-btn
            color="#2250b3"
            icon
            @click="
              (datosBancarios = false),
                $store.commit('setCardWallet', true),
                limpiador()
            "
          >
            <v-icon size="30">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="text-center pb-4">
          <p style="color:#2250b3" class="display-1">Datos bancarios</p>
        </div>

        <v-form
          class="d-flex justify-center"
          v-model="datosB"
          lazy-validation
          ref="form"
        >
          <div style="width:60%">
            <v-text-field
              single-line
              filled
              rounded
              v-model="name"
              :rules="nameRules"
              label="Nombre"
              required
            ></v-text-field>
            <v-text-field
              single-line
              filled
              rounded
              maxlength="50"
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
            <v-select
              single-line
              filled
              rounded
              :items="typeBank"
              item-value="id"
              item-text="name"
              label="Seleccione su banco"
              v-model="bank_id"
            >
            </v-select>
            <v-select
              single-line
              filled
              rounded
              label="Tipo de cuenta"
              item-value="id"
              item-text="name"
              :items="typeAcountItem"
              v-model="bank_account_type_id"
            />
            <v-text-field
              single-line
              filled
              rounded
              v-model="number"   
                                    
              label="Numero de cuenta"
              required
            ></v-text-field>
            <v-text-field
            
              single-line
              filled
              rounded
              v-model="amount"
              :rules="ruleMount"
              label="Monto a retirar"
              required
            ></v-text-field>
            <v-container class="mt-n5 text-center">
              <p style="color:#2250b3" class="title">Mi Saldo: ${{saldo}}</p>
            </v-container>
          </div>
        </v-form>
        <div class="d-flex justify-center">
          <v-btn
          :disabled="!disnableBtn"
            @click="
              $store.commit('setCardWallet', true),
                (datosBancarios = false),
                postForm()
            "
            color="#2250b3"
            style="color:white"
            >Enviar</v-btn
          >
        </div>
      </v-container>
    </v-dialog>
    <div class="d-flex justify-center">
      <v-btn
        width="300"
        height="45"
        color="#2250b3"
        style="color:white"
        class="rounded-xl "
        @click="(datosBancarios = true), $store.commit('setCardWallet', false), infoWallet()"
        >Retirar los fondos</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import {
  getListBank,
  gettypeAccount,
  postWithdrawal,
} from "@/helpers/api/calls";
export default {
  props: {
    updateWallet: {
      type: Function,
      default: null,
    },
    info:{
      type:Object,
      default:null,
    }
  },
  data() {
    return {
      saldo:'',
      datosBancarios: false,
      amount: "",
      datosB: true,
      bank_id: null,
      typeBank: [],
      number: "",
      bank_account_type_id: null,
      typeAcountItem: [],
      name: "",
      nameRules: [
        (v) => !!v || "Nombre requerido",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail es invalido",
      ],
      ruleNumber: [
        (v) =>
          (v && v.length >= 9) ||
          "Debe tener minimo 9 digitos el numero de tarjeta",
      ],
      ruleMount: [
        (v) => (v && this.amount <= this.saldo && this.saldo != 0 ) ||
        "El monto no puede superar tu saldo"
      ],
    };
  },
  computed: {
    disnableBtn() {
      return (
        this.email != '' &&
        this.name != '' &&
        this.number !='' &&
        this.bank_account_type_id != null &&
        this.bank_id != null &&
        this.amount  <= this.saldo &&
        this.amount != 0
      );
    },
  },
  mounted() {
    this.getlistBank();
    this.gettypeAccount();
    
  },
  methods: {
    infoWallet(){
      console.log(this.info)
      this.saldo = parseInt(this.info.funds)
      
    },

    limpiador() {
      this.email = "";
      this.name = "";
      this.number = "";
      this.bank_account_type_id = null;
      this.bank_id = null;
      this.amount = "";
      this.$refs.form.reset()
    },
    async getlistBank() {
      const typeBank = await getListBank();
      this.typeBank = typeBank.data;
      console.log("tiposDeBancos: ", typeBank);
    },
    async gettypeAccount() {
      const typeAcountItem = await gettypeAccount();
      this.typeAcountItem = typeAcountItem.data;
      // console.log("tiposDeBancos: ",typeAcountItem)
    },
    async postForm() {
      postWithdrawal({
        name: this.name,
        email: this.email,
        number: this.number,
        bank_id: this.bank_id,
        bank_account_type_id: this.bank_account_type_id,
        amount: this.amount,
      })
        .then((resp) => {
          // console.log("Funciono El retiro", resp);
          this.datosBancarios = false;
          this.limpiador();
          this.updateWallet();
        })
        .catch((err) => {
          console.log("Hubo un error con el form", err);
        });
    },
  },
};
</script>

<style></style>
