import {getPlan} from '../../helpers/api/calls'

export default {
    namespaced: true,
    state: {
        myPlan:[],
        modalAdvance:false,
        modalCart:false,
        boxInfo: '',
        modalInformation: false,
        informacionModal:'',
        modalMoreInformation:false,
        boxMoreInfo:'',
        listadoDePlan:[],
    },
    mutations: {
        setMyPlan(state,payload){
            state.myPlan = payload
        },
        setModalAdvance(state,payload){
            state.modalAdvance = payload
            // console.log("template", payload)
        },
        setModalCart(state,payload){
            state.modalCart = payload
        },
        setListadoPlan(state, payload){
            state.listadoDePlan = payload
        },
        setBoxInfo(state, payload) {
            // state.boxInfo = payload
            state.modalInformation = payload
            // console.log("payload",payload)
        },
        setInformacionModal(state,payload){
            state.boxInfo = payload
        },
        setModalMoreInformation(state, payload){
            state.modalMoreInformation = payload.status
            state.boxMoreInfo = payload.information
            // console.log(payload)
        }
    },
    actions: {
        async listPlan({commit}){
            try {
                const data = await getPlan()
                console.log(data)
                commit('setListadoPlan',data)
            } catch (error) {
                console.log(error)
            }
        }
    }
};