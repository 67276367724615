<template>
	<v-dialog
		v-model="dialog"
		persistent
		max-width="600px"
	>
		<v-card>
			<v-card-title class="my-0 py-1 d-flex justify-space-between" style="background-color: #2250b3">
				<p class="white--text mb-0 body-1 font-weight-bold">{{isNew ? 'Nueva Pregunta Frecuente':'Modificar Pregunta Frecuente'}}</p>
			</v-card-title>
			<v-card-text>
				<v-container>
  				<v-form
						ref="form"
						v-model="valid"
						lazy-validation
						class="mt-2">
						<v-row dense>
							<v-col cols="12">
								<v-text-field
									v-model="question"
									label="Pregunta"
      						:rules="faqRules"
									class="py-0 my-0"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea
									v-model="answer"
									label="Respuesta"
      						:rules="faqRules"
									rows="3"
									class="py-0 my-0"
								></v-textarea>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="#2250b3"
					text
					@click="closeModal"
				>
					Cerrar
				</v-btn>
				<v-btn
					color="#2250b3"
					text
					@click="sendForm"
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'UpdateCreateModal',
	props: {
		isNew: {
			type: Boolean,
			default: true
		},
		closeModal: {
			type: Function,
			required: true
		},
		updatingData: {
			type: Object,
			default: () => null
		}
	},
	computed: {
		dialog(){
			return this.isNew || this.updatingData
		}
	},
  data() {
		return{
			valid: true,
			question: null,
			answer: null,
      faqRules: [
        v => !!v || 'Campo requerido',
        v => (v && v.length >= 10) || 'Debe tener al menos 10 caracteres',
      ]
		}
	},
	mounted(){
		if(!this.isNew && this.updatingData){
			this.question = this.updatingData.question
			this.answer = this.updatingData.answer
		}
	},
	methods: {
		sendForm(){
			if(this.$refs.form.validate()){
				this.closeModal(true)
			}
		}
	}
}
</script>