<template>
  <div>
    <v-dialog eager width="650" v-model="callback">
      <v-container style="background: #3364d0" fluid>
        <v-row :class="!$vuetify.breakpoint.mobile ? 'ml-10 mt-1' : 'mt-1'">
          <v-col sm="2" cols="3">
            <v-img
              eager
              :class="!$vuetify.breakpoint.mobile ? 'mt-2 mx-auto' : ' ml-4'"
              :src="require('../../../../assets/iconPhone.png')"
              :max-width="!$vuetify.breakpoint.mobile ? '45px' : '50px'"
            />
          </v-col>
          <v-col sm="6" cols="6">
            <p
              style="color: #feb62d"
              :class="
                !$vuetify.breakpoint.mobile
                  ? 'display-2 font-weight-bold mt-2 ml-10 text-center'
                  : 'display-1 font-weight-bold mt-4 text-center'
              "
            >
              Llamada
            </p>
          </v-col>
          <v-col sm="4" cols="3">
            <div class="mt-3 mr-5 text-end">
              <v-btn @click="callback = false" text
                ><v-icon color="#FEB62D" style="font-size: 50px"
                  >mdi-close</v-icon
                ></v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pb-16" v-if="formNumero" style="background: #3364d0">
        <v-row>
          <v-col>
            <div
              class="mx-auto"
              style="
                background: #feb62d;
                border-radius: 100%;
                width: 150px;
                height: 150px;
              "
            >
              <v-img
                eager
                class="mx-auto"
                :src="require('../../../../assets/WomanChico.png')"
                max-width="150"
                style="border-radius: 100%"
              />
            </div>
            <p v-if="llamar == 1" style="color: white" class="text-center mt-4">
              Comunicate con el cliente
            </p>
            <p v-if="llamar == 0" style="color: white" class="text-center mt-4">
              Terminar la llamada
            </p>
            <p v-if="termino" style="color: white" class="text-center mt-4">
              Fin de la llamada
            </p>
          </v-col>
        </v-row>

        <v-container v-if="termino" >
          <v-row >
            <v-col class="text-center">
              <v-btn
                height="50"
                text
                width="250"
                style="border: solid 2px white"
                rounded
                @click.stop="
                  (callback = false), (llamar = 1), (termino = false)
                "
                ><p style="color: white" class="mt-4">Cerrar</p></v-btn
              >
            </v-col>
          </v-row>
        </v-container>

        <v-row v-if="llamar == 1">
          <v-col class="text-center">
            <v-btn
              @click="callReturn(), (llamar = 0)"
              height="50"
              text
              width="250"
              style="border: solid 2px white"
              rounded
              ><p style="color: white" class="mt-4">Llamar</p></v-btn
            >
          </v-col>
        </v-row>

        <v-row v-if="llamar == 0">
          <v-col class="text-center">
            <v-btn
              @click="endCall()"
              height="50"
              text
              width="250"
              style="border: solid 2px white"
              rounded
              ><p style="color: white" class="mt-4">Terminar</p></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <div>
      <v-btn
        elevation="1"
        style="background: #2250B3;"
        @click="(callback = true),setupClient()"
        rounded
        icon
        class="caption text-justify text-capitalize mt-2"
        ><v-icon color="white">mdi-phone</v-icon></v-btn
      >
    </div>    
  </div>
</template>

<script>
import {Device} from 'twilio-client'
// import { Device } from "@twilio/voice-sdk";
import { tokencallback } from "@/helpers/api/calls.js";
import { mapState } from "vuex";
import { child } from "@firebase/database";
import { updateStatusEmail,solutionCallback,token } from "@/helpers/api/calls";
export default {
  props: {
      user:{
          type:Object,          
      }
  },
  data() {
    return {
      callback: false,
      llamada: true,
      formNumero: true,
      valid: false,
      deviceStatus: 9,
      llamar: 1,
      termino: false,
    //   phone_number: this.$store.state.infoUserEmail.client.phone_number,
      phone_number: this.user.phone_number,
      device: null,
      token: null,
    };
  },
  computed: {
    ...mapState(["infoUserEmail"]),
  },
  mounted() {
      // this.setupClient();
      // console.log("Informacion Usuario: ",this.user)
  },
  methods: {
    setupClient() {
      token()
        .then((res) => {
          this.token = res;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    callReturn() {
      tokencallback()
        .then(async (resp) => {
          console.log("numero cliente: ", this.phone_number);
          this.device = new Device();
          this.device.setup(this.token);
          this.device.ready((device) => {
            this.device.connect({
              phoneNumber: this.phone_number,
            });
          });

          this.setupHandlers(this.device);
        })
        .catch(function(error) {
          console.error(error);
          console.log("Por la chita");
        });
    },
    
    setupHandlers(device) {
      device.on("ready", function(_device) {});

      /* Report any errors to the call status display */
      device.on("error", function(error) {
        console.log(error);
      });

      /* Callback for when Twilio Client initiates a new connection */
      device.on("connect", function(connection) {});

      /* Callback for when a call ends */
      device.on("disconnect", function(connection) {});

      /* Callback for when Twilio Client receives a new incoming call */
      device.on("incoming", function(connection) {});
    },    

    endCall() {
      this.termino = true;
      this.llamar = 3;
      this.deviceStatus = 9;
      this.device.destroy();
      this.device = null;
      
    },
    isNumber(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else event.preventDefault();
    },

  },
};
</script>

<style>
</style>