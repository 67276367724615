<template>
  <v-container>
    <v-dialog width="500" v-model="modalDelete">
      <v-card height="200" class="px-4">
        <v-card-text class="text-center">
          <p class="display-1">Eliminar Perfil</p>
        </v-card-text>
        <div class="text-center">
          <p>Desea Eliminar el perfil: {{ perfilName }}</p>
        </div>
        <v-row class="mt-5" justify="center">
          <v-col cols="4">
            <v-btn @click="deleteUser()">Confirmar</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn @click="closeModal()">Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- <v-btn @click="eliminar = true">Eliminar</v-btn> -->
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { deleteUser } from "../../../../helpers/api/calls";
export default {
  data() {
    return {
      eliminar: false,
      perfilName:'',
      id:null,
    };
  },
  computed:{
    ...mapState('CrudCuentas',['modalDelete','datosElimiar'])
  },
  watch: {
    datosElimiar(newValue, oldValue) {
      // console.log(newValue)
      if(newValue){
        this.perfilName = newValue.name
        this.id = newValue.id
      }      
    }
  },
  methods: {
    ...mapActions('CrudCuentas',['pushUsers']),
    closeModal(){
      this.$store.commit('CrudCuentas/setModalDelete',false)
    },
    async deleteUser() {
      const self = this;
      deleteUser({ id: this.id })
        .then((resp) => {
          console.log("funciono", resp);
          this.pushUsers();
          // self.actualizar(); -> CREAR EN ACTION LA LISTA Y ACTUALIZAR
          this.closeModal()
        })
        .catch(function (err) {
          console.log("No Funciono", err);
        });
    },
  },
};
</script>

<style>
</style>