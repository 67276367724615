<template>
  <v-container>
    <v-dialog v-model="myInfo" width="600">
      <v-container style="background: white">
        <div>
          <v-btn icon color="#2250b3" @click="myInfo = false">
            <v-icon size="30">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="text-center">
          <p style="color:#2250b3" class="display-1">Mis Datos</p>
        </div>

        <div class="text-center mt-10 mb-10">
          <div class="mb-8">
            <p style="color:#2250b3" class="subtitle-1 font-weight-bold ">Mi plan actual</p>
            <div style="background:#2250b3; width:30%" class="rounded-lg mx-auto">
              <p style="color:white" class="display-1">{{ nameCompany }}</p>
            </div>
          </div>

          <p style="color:#2250b3" class="subtitle-1 font-weight-bold ">Servicios del plan actual</p>
          <div class="d-flex flex-row">
            <p class="mx-auto font-weight-bold" v-for="(nam, index) in services" :key="index">
              {{ nam.name }}
            </p>
          </div>
        </div>

        <v-container>
          <v-row justify="start">
            <p style="color:#2250b3" class="ml-2 mb-5 title font-weight-bold">Mi saldo actual</p>
            <v-btn @click="goTo()" style="color:white"  small class="ml-4 mt-1" color="#2250b3">
              Comprar Bolsa
            </v-btn>
          </v-row>
          <div>
            <p>
              <span class="subtitle-1 font-weight-bold">Minutos llamadas</span>:
              {{ stats.available_callbacks_time }} Minutos
            </p>
            <p>
              <span class="subtitle-1 font-weight-bold">Minutos video llamadas</span>:
              {{ stats.available_videocalls_time }} Minutos
            </p>
            <p>
              <span class="subtitle-1 font-weight-bold">Video Email usado</span>:
              {{ stats.available_videomails_data }} Gb
            </p>
            <p>
              <span class="subtitle-1 font-weight-bold">Mensaje Whatsapp</span>: {{ stats.available_whatsapp_messages }}
            </p>
          </div>
        </v-container>

        <v-container>
          <p style="color:#2250b3" class="title font-weight-bold">El plan actual contempla:</p>
          <div>
            <ul>
              <li>
                <span class="subtitle-1 font-weight-bold">Minutos llamadas</span>:
                {{ limit.available_callbacks_time }} Minutos
              </li>
              <li>
                <span class="subtitle-1 font-weight-bold">Minutos video llamadas</span>:
                {{ limit.available_videocalls_time }} Minutos
              </li>
              <li>
                <span class="subtitle-1 font-weight-bold">Video Emails</span>: {{ limit.available_videomails_data }} Gb
              </li>
              <li>
                <span class="subtitle-1 font-weight-bold">Mensaje Whatsapp</span>:
                {{ limit.available_whatsapp_messages }}
              </li>
            </ul>
          </div>
        </v-container>
      </v-container>
    </v-dialog>

    <div>
      <div class="mb-n8 ml-n3">
        <v-btn
          text
          color="white"
          block
          large
          class="px-3 d-flex flex-row justify-start align-center"
          @click="(myInfo = true), userData()"
        >
          <v-icon color="#2250b3" class="mr-4">mdi-book-information-variant</v-icon>
          <p style="color: #2250b3" class="mb-0 ml-n1 subtitle-1 text-capitalize">
            Mis Datos
          </p>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { getservicesCompany } from "@/helpers/api/calls";
export default {
  name: "MyDate",
  data() {
    return {
      myInfo: false,
      info: null,
      nameCompany: "",
      InfoCompany: "",
      idPlan: null,
      stats: {},
      limit: {},
      services: [],
    };
  },
  methods: {
    async getCompanyInfo() {
      const myInfoCompany = await getservicesCompany({ id: this.info });
      this.InfoCompany = myInfoCompany.data;
      this.nameCompany = myInfoCompany.data.plan.name;
      this.idPlan = myInfoCompany.data.plan.id;
      this.stats = myInfoCompany.data.stats;
      this.limit = myInfoCompany.data.plan.limit;
      this.$store.commit("setGetCompanyInfo", myInfoCompany.data);
      this.services = myInfoCompany.data.plan.services;
    },
    userData() {
      this.info = this.$store.state.userData.company.id;
      this.getCompanyInfo();
    },
    goTo(){
      this.myInfo = false,
      this.$router.push({name:'Bolsas'})
    }
  },
};
</script>

<style></style>
