<template>
  <v-container class="pa-0">
    <v-dialog width="600" v-model="modalAddEsp">
      <v-container style="background: white">
        <div>
          <v-btn
            color="#2250b3"
            icon
            @click="$store.commit('setModalAddEsp', false)"
          >
            <v-icon size="30"> mdi-close </v-icon>
          </v-btn>
        </div>
        <div class="text-center">
          <p style="color: #2250b3" class="display-1">Nueva Especialidad</p>
        </div>

        <v-container>
          <v-form ref="addEspecialidad" v-model="valid">
              <v-text-field
              v-model="nameEsp"
              label="Nombre de la especialidad"
              outlined
              single-line
              elevation="0"
              >
                  
              </v-text-field>
              <v-text-field
              v-model="desc"
              label="Descripción"
              outlined
              single-line
              elevation="0"
              >
                  
              </v-text-field>
            <!-- <v-select
              class="mb-n3"
              filled
              rounded
              single-line
              v-model="infoSelect"
              label="Nombre de la especialidad"
              return-object
            ></v-select> -->

            <div class="text-center">
              <v-btn
                style="color: white"
                class="rounded-lg text-capitalize"
                color="#2250b3"
                @click="addEspecialidad()"
                :disabled="!disnable"
                >Agregar Especialidad</v-btn
              >
            </div>
          </v-form>
        </v-container>
      </v-container>
    </v-dialog>
    <!-- <div>
      <v-snackbar color="#2250b3" timeout="2000" v-model="msn">
        <p
          style="color: white"
          class="text-center subtitle-1 font-weight-normal"
        >
          Numero Añadido
        </p>
      </v-snackbar>
    </div> -->
    <div class="d-flex align-center justify-center">
      <v-btn @click="$store.commit('setModalAddEsp', true)" icon x-small color="white">
				<v-icon>mdi-plus-circle</v-icon>
			</v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {AddAdminEsp} from '../../../../../helpers/api/calls'
export default {
  props: {
    reload: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      valid: null,
      nameEsp:'',
      desc:'',
    };
  },
  computed: {
    ...mapState(["modalAddEsp"]),
    disnable() {
      return this.infoSelect != "";
    },
  },
  methods: {
    async addEspecialidad() {
        AddAdminEsp({name:this.nameEsp, description:this.desc,

        })
        .then((resp) => {
          if(resp){
            // console.log(resp)
            this.nameEsp = '',
            this.$refs.addEspecialidad.reset();
            this.$store.commit('setModalAddEsp',false)
            this.reload()
          }
          
        }).catch((err) => {
          console.log(err)
        });
      
    }
  },
};
</script>

<style>
</style>