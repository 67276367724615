<template>
  <v-card class="mx-auto my-5" outlined elevation="7">
    <v-list-item three-line>
      <v-list-item-avatar class="mt-15" tile size="50">
        <v-icon x-large color="#2250b3" class="mr-4">mdi-shopping</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h5 mb-1">
          <p text style="color:#2250b3">{{ pack.name }}</p>
        </v-list-item-title>

        <p class="font-weight-bold" style="color:#2250b3">Incluye:</p>
        <v-list-item-subtitle style="color:#2250b3" class="my-1" v-for="resource in pack.resources" :key="resource.id">
          {{ resource.pivot.resource_amount }} ({{ resource.unit }}) {{ resource.name }}
        </v-list-item-subtitle>

        <v-row class="mt-5">
          <v-col md="4" lg="4" xl="4" cols="12">
            <v-list-item-title class="text-h5 mb-1">
              <p style="color:#2250b3" text class="font-weight-bold">${{ pack.price }}</p>
            </v-list-item-title>
          </v-col>
          <v-col md="6" lg="6" xl="4" cols="12">
            <v-btn @click="togglePaymentWidget(pack)" depressed large outlined color="primary">
              Comprar
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import store from "../../../store/index";
export default {
  props: ["pack"],
  data: () => ({}),
  methods: {
    togglePaymentWidget(item) {
      store.commit("setPaymentWidget", !store.state.paymentWidget);
      store.commit("setPaymentItems", item);
    },
  },
};
</script>

<style></style>
