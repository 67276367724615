<template>
  <!-- eslint-disable -->
  <v-container>
    <v-dialog width="1200" persistent v-model="this.$store.state.modalContac">
      <v-card>
        <v-container class="d-flex flex-columns">
          <div>
            <v-btn
              color="#2250b3"
              icon
              class="mt-2"
              @click="$store.commit('setModalContac', false)"
            >
              <v-icon size="30"> mdi-close </v-icon>
            </v-btn>
          </div>
          <div class="ml-5">
            <p
              style="color: #2250b3"
              class="mt-3 subtitle-1 font-weight-regular"
            >
              Contactos Recibidos
            </p>
          </div>
        </v-container>
        <v-container>
          <v-tabs v-model="tab">
            <v-tabs-slider />
            <v-tab
              class="text-capitalize"
              v-for="(item, index) in tabs"
              :key="index"
            >
              {{ item.text }}
            </v-tab>
          </v-tabs>
        </v-container>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-data-table
                :headers="headers"
                :items="list"
                :search="search"
                :items-per-page="10"
              >
                <template v-slot:[`item.contact_date`]="{ item }">
                  {{ item.contact_date | toDate }}
                  {{ item.contact_date | toHour }}
                </template>

                <template v-slot:[`item.contact_message`]="item">
                  <v-btn @click="openMsn(item)" color="#2250b3" outlined icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <template v-slot:[`item.opciones`]="item">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :loading="loadingCheck"
                        @click="changeStatus(item.item)"
                        icon
                        outlined
                        color="green"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>Respondido</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.llamada`]="item">
                  <!-- <CallbackContacto :info="item.item"/> -->
                  <div>
                    <v-btn
                      @click="OpenModalCallback(item)"
                      color="#81B322"
                      icon
                      outlined
                      ><v-icon>mdi-phone</v-icon></v-btn
                    >
                    <v-btn
                      class="ml-2"
                      @click="sendWsp(item)"
                      color="#81B322"
                      icon
                      outlined
                      ><v-icon>mdi-video</v-icon></v-btn
                    >
                  </div>
                </template>
                <template v-slot:[`item.responder`]="item">
                  <div>
                    <v-btn
                      @click="OpenMensaje(item), (loadingMesseng = true)"
                      color="#feb62d"
                      :loading="loadingMesseng"
                      icon
                      outlined
                      ><v-icon>mdi-mail</v-icon></v-btn
                    >
                  </div>
                </template>
                <template v-slot:[`item.client_name`]="{ item }">
                  {{ item.client_name }}
                </template>
                <template v-slot:[`item.client_email`]="{ item }">
                  {{ item.client_email }}
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-data-table
                :headers="headers2"
                :search="search"
                :items="list2"
                :items-per-page="10"
              >
                <template v-slot:[`item.contact_date`]="{ item }">
                  {{ item.contact_date | toDate }}
                  {{ item.contact_date | toHour }}
                </template>
                <template v-slot:[`item.contact_message`]="item">
                  <v-btn @click="openMsn(item)" color="#2250b3" outlined icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.llamada`]="item">
                  <div>
                    <v-btn
                      @click="OpenModalCallback(item)"
                      color="#81B322"
                      icon
                      outlined
                      ><v-icon>mdi-phone</v-icon></v-btn
                    >
                    <v-btn
                      class="ml-2"
                      @click="sendWsp(item)"
                      color="#81B322"
                      icon
                      outlined
                      ><v-icon>mdi-video</v-icon></v-btn
                    >
                  </div>
                </template>
                <template v-slot:[`item.responder`]="item">
                  <div>
                    <v-btn
                      @click="OpenMensaje(item), (loadingMesseng = true)"
                      :loading="loadingMesseng"
                      color="#feb62d"
                      icon
                      outlined
                      ><v-icon>mdi-mail</v-icon></v-btn
                    >
                  </div>
                </template>
                <template v-slot:[`item.client_name`]="{ item }">
                  {{ item.client_name }}
                </template>
                <template v-slot:[`item.client_email`]="{ item }">
                  {{ item.client_email }}
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
    <v-dialog width="500" v-model="msn">
      <v-container class="pa-0">
        <v-card>
          <div>
            <v-btn color="#2250b3" icon @click="msn = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
          <v-card-title
            style="color: #2250b3"
            class="d-flex justify-center display-1 font-weight-bold"
          >
            Mensaje
          </v-card-title>
          <div class="body-1 font-weight-bold">
            <v-container class="px-5">
              {{ mensaje }}
            </v-container>
          </div>
        </v-card>
      </v-container>
    </v-dialog>
    <CallbackContacto />
    <MensajeContacto />
  </v-container>
</template>

<script>
import {
  getDatabase,
  ref,
  onValue,
  set,
  onChildChanged,
  remove,
} from "firebase/database";
import moment from "moment";
import { getContact, postWsp, patchsetAnswered } from "@/helpers/api/calls";
import CallbackContacto from "./CallbackContacto.vue";
import MensajeContacto from "./MensajeContacto.vue";
import { mapActions, mapState } from 'vuex';
export default {
  filters: {
    toDate: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },
    toHour: function (date) {
      if (!date) {
        return "-";
      }
      return moment(date).format("HH:mm A");
    },
  },
  data() {
    return {
      loadingCheck: false,
      loadingMesseng: false,
      tabs: [{ text: "Bandeja de entrada" }, { text: "Respondidos" }],
      tab: null,
      loading: false,
      phone: null,
      namee: null,
      search: "",
      msn: false,
      mensaje: "",
      info: "",
      list: [],
      list2: [],
      headers: [
        {
          text: "Fecha y Hora",
          value: "contact_date",
        },
        { text: "Nombre", value: "client_name" },
        { text: "Correo", value: "client_email" },
        { text: "Teléfono", value: "client_phone" },
        { text: "Mensaje", value: "contact_message" },
        { text: "Llamar", value: "llamada", align: "center" },
        { text: "Responder", value: "responder" },
        { text: "Respondido", value: "opciones" },
      ],
      headers2: [
        {
          text: "Fecha y Hora",
          value: "contact_date",
        },
        { text: "Nombre", value: "client_name" },
        { text: "Correo", value: "client_email" },
        { text: "Teléfono", value: "client_phone" },
        { text: "Mensaje", value: "contact_message" },
        { text: "Llamar", value: "llamada", align: "center" },
        { text: "Responder", value: "responder" },
      ],
    };
  },
  mounted() {
    // this.getContacto();
  },
  watch: {
    'messageContact'(newValue, oldValue) {
      if(newValue){
        console.log("Funciona")
        this.list = newValue.data
        this.list2 = newValue.data2
      }
    }
  },
  computed: {
...mapState('dashboard',['messageContact'])
  },
  methods: {
    ...mapActions('dashboard',['getContact'] ),
    async changeStatus(item) {
      this.loadingCheck = true;
      patchsetAnswered({ id: item.contact_id })
        .then((resp) => {
          if (resp) {
            this.getContact(),
              setTimeout(() => {
                this.loadingCheck = false;
              }, 1500);
            // console.log("Funciono")
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log("item", item);
    },
    userData() {
      this.info = this.$store.state.userData;
      // console.log("INDEX");
    },
    OpenModalCallback(item) {
      this.$store.commit("setModalCallbackContact", true);
      this.$store.commit("setDateModalCallback", item.item);
    },
    OpenMensaje(item) {
      this.$store.commit("setModalMensaje", true);
      this.$store.commit("setDateMensaje", item.item);
      setTimeout(() => {
        this.loadingMesseng = false;
      }, 1000);
    },
    openMsn(item) {
      console.log(item);
      this.msn = true;
      this.mensaje = item.item.contact_message;
    },
    // async getContacto() {
    //   const data = await getContact({ status: 1 });
    //   const res = await getContact({ status: 2 });
    //   this.list = data.data.reverse();
    //   this.list2 = res.data.reverse();
    //   this.userData();
    //   // console.log(data.data);
    // },
    CallPrivate() {
      this.phone = this.phone.slice(1);
      console.log("Dato:", this.phone);
      this.insertDb();
      this.$router.push({
        name: "NuevoSocketVideo",
        params: { sala_id: this.phone, id: this.$store.state.userData.id },
      });
    },
    insertDb() {
      const db = getDatabase();
      set(
        ref(
          db,
          "connections/" +
            "PrivateWsp/" +
            this.info.company_id +
            "/" +
            this.phone
        ),
        {
          sala_id: this.phone,
        }
      );
    },
    delereRoom() {
      const db = getDatabase();
      remove(
        ref(
          db,
          "connections/" +
            "PrivateWsp/" +
            this.info.company_id +
            "/" +
            this.phone
        )
      );

      console.log("funciona?");
    },

    async sendWsp(item) {
      this.phone = item.item.phone_number;
      this.namee = item.item.name;
      this.loading = true;
      this.CallPrivate();
      this.delereRoom();
      postWsp({ name: this.namee, phone: this.phone })
        .then((resp) => {
          console.log("Funciono: ", resp);
          // this.CallPrivate()
          // this.delereRoom()
        })
        .catch((err) => {
          console.log("error whasapp: ", err);
        });
    },
  },
  components: { CallbackContacto, MensajeContacto },
};
</script>

<style></style>
