<template>
  <v-container fluid style="background: #eaeef2; height: 100%">
    <div >
      <!-- <div :class="$vuetify.breakpoint.mobile ? '':'d-flex flex-nowrap ma-5'">
        <Drawer class="d-none d-sm-flex" />
        <DrawerMobile  class="d-flex d-sm-none"/>
      </div> -->
      <v-row class="pt-5">
        <v-col  sm="8" cols="12">
          <v-row >
            <v-col sm="12" lg="6" xl="6" cols="12">
              <Bandeja v-if="this.$store.state.cardBandeja == 1" />
              <CorreosRespondidos v-else />
            </v-col>

            <v-col sm="12" lg="6" xl="6" cols="12" v-if="cardState == true">
              <CardInfoEmail />
            </v-col>
          </v-row>
        </v-col>

        <v-col class="py-0" cols="4">
          <DrawerRight class="d-none d-sm-flex" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Drawer from "../Drawer.vue";
import DrawerRight from "../DrawerLateralDerecho/DrawerRight.vue";
import Bandeja from "./VideoEmail/Bandeja.vue";
import CardInfoEmail from "./VideoEmail/CardInfoEmail.vue";
import DrawerMobile from "../DrawerMobile.vue";
import CorreosRespondidos from "./VideoEmail/CorreosRespondidos.vue";
export default {
  components: {
    Drawer,
    DrawerRight,
    Bandeja,
    CardInfoEmail,
    DrawerMobile,
    CorreosRespondidos
},
  computed: {
    ...mapState(["cardState"]),
  },
};
</script>

<style>
</style>