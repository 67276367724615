<template>
  <v-container class="py-0" fluid>
    <v-card style="overflow: auto" height="100vh">
      <v-container
        :class="!$vuetify.breakpoint.xl ? 'pt-3 text-center' : 'pt-3'"
      >
        <p style="color: #2250b3" class="headline">Correos Respondidos</p>
      </v-container>
      <v-container
        :class="!$vuetify.breakpoint.xl ? 'text-center' : 'd-flex flex-columns mb-n5'"
      >
        <p style="color: #2250b3" class="mt-3 subtitle-1 font-weight-regular">
          Buscar correo
        </p>
        <div class="ml-5">
        <v-text-field
          v-model="search"
          outlined
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>          
        </div>
      </v-container>
      <v-container>
        <v-btn color="#2250b3" style="color:white" @click="cardBandejaChange()" rounded class="caption ">
          Ir a bandeja
        </v-btn>
      </v-container>      
      <v-container class="text-center">
        <v-row>
          <v-col
            xl="3"
            cols="4"
          >
            <p class="mb-0">Estado</p>
          </v-col>
          <v-col sm="4" xl="5" lg="4" cols="4">
            <p class="mb-0">Nombre</p>
          </v-col>

          <v-col sm="4" xl="4" lg="4" cols="4">
            <p
              :class="
                !$vuetify.breakpoint.xl ? 'mb-0 text-center' : 'mb-0 text-end'
              "
            >
              Fecha de envio
            </p>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>

      <v-container class="px-0 py-0" v-for="(ema, index) in filterCorreo" :key="index">
        <v-btn
          height="100%"
          width="100%"
          @click="ChangeStateCard(), AddInfo(ema), getVideoUser(ema)"
          text
        >
          <v-row>
            <v-col class="d-flex justify-center" xl="3" cols="4">
              <v-avatar
                :color="ema.status.id == 1 ? 'red' : 'blue'"
                size="20"
                class="mt-4"
              ></v-avatar>
            </v-col>
            <v-col class="text-center" sm="5" md="5" lg="4" xl="6" cols="4">
              <p class="caption font-weight-bold mt-4 textNameTest">
                {{ ema.client.name }}
              </p>
            </v-col>

            <v-col sm="3" md="3" lg="4" xl="3" cols="4">
              <p class="caption mt-3">{{ ema.created_at | toDate }} <br> {{ ema.created_at | toHour }}</p>
            </v-col>
          </v-row>
        </v-btn>
        <v-divider></v-divider>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import {
  getMailRespondidos,
  getVideoUser,
  getVideoEmailID,
} from "../../../../helpers/api/calls";
export default {
  filters: {
    toDate: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },
    toHour: function(date) {
      if (!date) {
        return "-";
      }
      return moment(date).format("HH:mm A");
    },        
  },

  data() {
    return {
      info: [],
      search:'',
    };
  },
  created() {
    this.getMailRespondidos();
  },
  watch: {
    "$route.query.id": {
      immediate: true,
      handler(id) {
        console.log("queides:", id);
        if (id) {
          this.getInfoEmail(id);
          this.ChangeStateCard();
          this.getVideoUserEmail(id);
        }
      },
    },
  },
  computed: {
      filterCorreo(){
        return this.info.filter((ema) => {
          return ema.client.name.match(this.search);
        })
      }
  },

  methods: {
      cardBandejaChange(){
        this.$store.commit("setCardBandeja", 1);
        this.$store.commit("setCardState", false);
      },
    AddInfo(ema) {
      this.$store.commit("setInfoUserEmail", "");
      if (ema) {
        this.$store.commit("setInfoUserEmail", ema);
        // console.log("informacion que se manda", ema);
      }
    },

    ChangeStateCard() {
      this.$store.commit("setCardState", true);
    },
    async getMailRespondidos() {
      const infoEmail = await getMailRespondidos();
      // console.log("lo que viene",infoEmail);
      this.info = infoEmail.data;
      // console.log("info de info",this.info);
    },
    async getVideoUser(ema) {
      const self = this;
      this.$store.commit("setUrlVideoEmail", null);
      getVideoUser({ id: ema.id })
        .then((resp) => {
          if (resp) {
            // console.log("respuesta",resp);
            this.$store.commit("setUrlVideoEmail", resp);
          }
        })
        .catch(function (err) {
          // console.log("No Funciono",err);
        });
    },

    async getVideoUserEmail(id) {
      const self = this;
      this.$store.commit("setUrlVideoEmail", null);
      getVideoUser({ id: id })
        .then((resp) => {
          if (resp) {
            // console.log("respuesta",resp);
            this.$store.commit("setUrlVideoEmail", resp);
          }
        })
        .catch(function (err) {
          // console.log("No Funciono",err);
        });
    },
    async getInfoEmail(id) {
      const self = this;
      getVideoEmailID({ id: id })
        .then((resp) => {
          if (resp) {
            this.$store.commit("setInfoUserEmail", resp.data.videomail);
            // console.log("informacion que se manda especifico",resp);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.textTest {
  width: 135px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.textNameTest {
  max-width: 165px;
  min-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
</style>