<template>
  <v-dialog width="500" v-model="modal">
    <v-card>
      <v-container>
        <v-btn
          color="rgb(34, 80, 179)"
          icon
          @click="$store.commit('payPlan/setModalAdvance', false)"
        >
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
      </v-container>
      <v-container>
        <div class="text-center">
          <p style="color: " class="display-1">Su plan actual</p>
          <div>
            <p class="display-1">
              {{ nombre }}
            </p>
          </div>
        </div>
        <v-container>
          <p class="subtitle-1">característica de su plan:</p>
          <div>
            <v-container>
              <ul>
                <li>
                  <p>Minutos de llamadas: {{ tiempoLlamadas }} Minutos</p>
                </li>
                <li>
                  <p>
                    Minutos video llamadas: {{ tiempoVideoLlamada }} Minutos
                  </p>
                </li>
                <li>
                  <p>Video Emails: {{ videoEmails }}</p>
                </li>
                <li>
                  <p>Mensaje Whatsapp: {{ mensajeWsp }}</p>
                </li>
              </ul>
            </v-container>
          </div>
          <div class="text-center">
            <p class="title font-weight-regular">Precio plan: ${{ nuevo }}</p>
          </div>
        </v-container>
        <v-container class="text-center">
          <p class="display-1">Paga por adelantado!</p>
          <div class="">
            <v-select
              class="mx-auto"
              style="width: 80%"
              clearable
              outlined
              v-model="mesAdelanto"
              :items="items"
              item-value="items"
              solo
              label="¿Cuanto meses desea adelantar?"
            />
          </div>
          <div>
            <p>Total a pagar: ${{ valorTotal }}</p>
          </div>
          <div>
            <v-btn :disabled="!disnableBtn" @click="payAdvance()" color="rgb(34, 80, 179)" outlined>
              Pagar
            </v-btn>
          </div>
        </v-container>
        <v-container> </v-container>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { postAdvancePay } from "@/helpers/api/payments/payment";
import { mapState } from "vuex";
export default {
  data() {
    return {
      idCompany: null,
      mesAdelanto: null,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      nombre: "",
      tiempoLlamadas: "",
      tiempoVideoLlamada: "",
      videoEmails: "",
      mensajeWsp: "",
      precio: "",
    };
  },
  computed: {
    ...mapState("payPlan", ["modalAdvance", "myPlan"]),
    ...mapState(["userData"]),
    modal: {
      get() {
        return this.modalAdvance;
      },
      set() {
        return this.$store.commit("payPlan/setModalAdvance", false);
      },
    },
    nuevo() {
      // return this.precio
      const nuevoFormat = new Intl.NumberFormat("es-CL");
      return nuevoFormat.format(this.precio);
    },
    valorTotal() {
      const nuevoFormat = new Intl.NumberFormat("es-CL");
      const valor = this.precio * this.mesAdelanto * 0.9;
      return nuevoFormat.format(valor);
    },
    disnableBtn(){
      return this.mesAdelanto != null
    }
  },
  watch: {
    myPlan(newValue) {
      console.log("miPlancito", newValue);
      if (newValue) {
        this.precio = newValue.plan.price;
        this.nombre = newValue.plan.name;
        this.tiempoLlamadas = newValue.plan.limit.available_callbacks_time;
        (this.tiempoVideoLlamada =
          newValue.plan.limit.available_videocalls_time),
          (this.videoEmails = newValue.plan.limit.available_videomails_data),
          (this.mensajeWsp = newValue.plan.limit.available_whatsapp_messages);
      }
    },
    userData(newValue) {
      if (newValue) {
        this.idCompany = newValue.company_id;
      }
    },
  },
  methods: {
    async payAdvance() {
      // this.loading = true;
      const response = await postAdvancePay({
        id: this.idCompany,
        monthsToPay: this.mesAdelanto,
      });
      console.log("resp", response);
      const { flowData } = await response.data;
      if (["url", "token"].every((value) => value in flowData))
        window.location.href = `${flowData.url}?token=${flowData.token}`;
      // this.loagin = false;
    },
  },
};
</script>

<style scoped>
p {
  color: rgb(34, 80, 179) !important;
}
</style>