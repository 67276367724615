export default {
  namespaced: true,
  state: {
    modalInfo: false,
    information: [],
  },
  mutations: {
    setModalInfo(state, status) {
      state.modalInfo = status.status; //Estado del modal
      state.information = status.information; //Informacion para el componente
      // console.log(status)
    },
  },
  actions: {},
};
