import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSocialSharing from 'vue-social-sharing'
import VueAudio from 'vue-audio-better'

Vue.use(VueAudio)


Vue.config.productionTip = false
Vue.use(VueSocialSharing);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')