<template>
  <v-dialog persistent width="500" v-model="modalMoreInformation"> 
    <v-card>
        <v-container>
            <v-btn icon @click="setModalMoreInformation({status:false, information:null})">
                <v-icon size="30">
                    mdi-close
                </v-icon>
            </v-btn>
        </v-container>
        <div class="d-flex flex-column align-center justify-center">
            <v-icon  size="100">
                mdi-robot-happy-outline
            </v-icon>
            <p class="display-1">Página en contrucción</p>
        </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
    computed: {
        ...mapState('payPlan',['modalMoreInformation'])
    },
    methods: {
        ...mapMutations("payPlan", ['setModalMoreInformation']),

    },
};
</script>

<style>
</style>