<template>
  <v-container fluid style="background: #eaeef2; height: 100%">
    <div>
      <!-- <div class="d-flex flex-nowrap ma-5">
        <Drawer class="d-none d-sm-flex" />
        <DrawerMobile style="position:absolute;top:0px;left:0px" class="d-flex d-sm-none" />
      </div> -->
      <v-row class="ma-5 d-flex justify-space-between">
        <v-col class="py-1" md="12" lg="12" xl="12" cols="12">
          <Index />
        </v-col>

        <WidgetDrawer />

        <v-col hidden cols="0">
          <DrawerRight class="d-none d-sm-flex" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Drawer from "../Drawer.vue";
import DrawerMobile from "../DrawerMobile.vue";
import DrawerRight from "../DrawerLateralDerecho/DrawerRight.vue";
import Index from "../Bolsas/Index.vue";
import WidgetDrawer from "../Payment/WidgetDrawer.vue";

export default {
  name: "vigilancia",
  components: {
    DrawerMobile,
    Index,
    Drawer,
    DrawerRight,
    WidgetDrawer,
  },
  mounted() {
    this.$store.commit("setCardState", null);
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style>
.blue-text {
  color: "#2250b3";
}
</style>
