<template>
  <v-container temporary class="rounded-lg" fluid>
    <v-card height="90vh" width="100%" floating>
      <v-list-item>
        <v-row justify="center" class="mb-5 mt-5">
          <v-col class="hidden-md-and-down" lg="3" xl="3" cols="3">
            <v-container class="text-center mt-10">
              <v-icon color="#81B322" size="45">mdi-bell</v-icon>
            </v-container>
          </v-col>
          <v-col sm="12" md="12" lg="6" cols="6">
            <div
              class="mx-auto"
              :style="
                operador == 1
                  ? 'background: #feb62d;border-radius: 100%;width: 150px;height: 150px;'
                  : 'background: #eaeef2;border-radius: 100%;width: 150px;height: 150px;'
              "
            >
              <v-img
                eager
                class="mx-auto"
                :src="require('../../../assets/WomanChico.png')"
                max-width="150"
                :style="
                  operador == 1
                    ? 'border-radius: 100%;'
                    : 'border-radius: 100%;filter: opacity(0.3) drop-shadow(0 0 0 #eaeef2);'
                "
              />
            </div>
          </v-col>
          <v-col
            :class="!$vuetify.breakpoint.md.lg ? 'd-flex justify-center' : ''"
            md="12"
            lg="3"
            xl="3"
            cols="3"
          >
            <div>
              <v-switch
                :class="!$vuetify.breakpoint.md ? 'mt-14' : ''"
                v-model="operador"
                :value="operador ? 1 : 3"
                :loading="loading"
                color="#81B322"
                hide-details
              >
              </v-switch>
              <p
                :style="operador == 1 ? 'color:#81B322' : 'color:#E20D4E'"
                class="caption"
              >
                {{ operador == 1 ? "En línea" : "Ausente" }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-list-item>

      <v-container class="text-center mb-n2">
        <p class="title font-weight-regular" style="color: #2250b3">
          Solicitud de atenciones
        </p>
      </v-container>
      <v-divider></v-divider>

      <v-container
        class="pa-1"
        v-for="(sala, index) in Object.keys(alert)"
        :key="index"
      >
        <div>
          <v-row>
            <v-col cols="3">
              <div>
                <v-img
                  width="100%"
                  height="70"
                  class="rounded-lg"
                  :src="require('../../../assets/imgPerfil.png')"
                ></v-img>
              </div>
            </v-col>
            <v-col>
              <div
                :class="alert[sala].operator_id == info.id ? 'paraUsuario' : ''"
                :style="
                  alert[sala].speciality ? 'color:#81B322' : 'color: #2250b3'
                "
              >
                <p
                  :class="
                    alert[sala].operator_id == info.id
                      ? 'subtitle-2 mb-1'
                      : 'caption mb-1'
                  "
                >
                  {{ alert[sala].created_at | toHour }}
                </p>
                <p
                  :class="
                    alert[sala].operator_id == info.id
                      ? 'subtitle-2 mb-1'
                      : 'caption mb-1'
                  "
                >
                  {{
                    alert[sala].speciality ? alert[sala].speciality : "General"
                  }}
                </p>
                <p
                  :class="
                    alert[sala].operator_id == info.id
                      ? 'subtitle-2'
                      : 'caption mb-1'
                  "
                >
                  Video llamada
                </p>
              </div>
            </v-col>
            <v-col>
              <div
                :class="
                  $vuetify.breakpoint.md
                    ? 'mt-n4 d-flex justify-center mb-3'
                    : 'mt-4'
                "
              >
                <v-btn
                  @click="stopSong(alert[sala]), DeleteGeneral(alert[sala])"
                  :style="
                    alert[sala].operator_id == info.id
                      ? 'border: solid 1px #feb62d;color:#feb62d'
                      : 'border: solid 1px #81b322'
                  "
                  class="rounded-lg"
                  color="#81B322"
                  text
                  >Atender</v-btn
                >
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
      </v-container>
    </v-card>
    <DrawerMobile
      style="position: absolute; top: 0px; left: 0px"
      class="d-flex d-sm-none"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import { userData, statusUser } from "../../../helpers/api/calls";
import {
  getDatabase,
  ref,
  onValue,
  set,
  remove,
  onDisconnect,
  onChildRemoved,
} from "firebase/database";

import AvisoConexion from "../SocketVideoPanel/AvisoConexion.vue";
import DrawerMobile from "../DrawerMobile.vue";
import { mapActions } from "vuex";
export default {
  components: {
    AvisoConexion,
    DrawerMobile,
  },

  data() {
    return {
      loading: true,
      info: [],
      alert: [],
      alert2: [],
      alert3: [],
      roomdelete: [],
      sonido: null,
      operador: null,
      statusOperador: null,
    };
  },
  filters: {
    toDate: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },
    toHour: function (date) {
      if (!date) {
        return "-";
      }
      return moment(date).format("HH:mm");
    },
  },

  watch: {
    operador(newValue) {
      if (newValue == 1) {
        this.statusOperador = 1;
        this.statusUser();
        this.insertDb();
      } else {
        this.statusOperador = 3;
        this.statusUser();
        this.insertDb();
      }
    },

    alert(newValue, oldValue) {
      console.log("NuevoValor", newValue, oldValue);
      if (this.alert.length > 0) {
        this.song();
      }
    },
  },

  mounted() {
    this.userData();
    this.sonido = new Audio(
      require("../../../assets/sounds/notification2.mp3")
    );
  },

  methods: {
    ...mapActions(['limit']),
    async userData() {
    	this.limit();
      const info = await userData();
      // console.log("info",info.data)
      this.$store.commit('setUserData', info.data)
      this.info = info.data;
      this.operador = info.data.status_id;
      this.loading = false;
      this.insertDb();
      this.ListenAlert();
      this.ListenAlertSpeciality();
      this.DisconneectUser();
      this.testinglistenDelete();
    },
    stopSong(sala) {
      this.$router.push({
        name: "NuevoSocketVideo",
        params: { sala_id: sala.id, id: this.info.id },
      });
      this.sonido.pause();
    },

    song() {
      this.sonido.play();
    },

    insertDb() {
      const db = getDatabase();
      set(ref(db, "connections/" + this.info.company_id + "/" + this.info.id), {
        id_user: this.info.id,
        name: this.info.name,
        phone_number: this.info.phone_number,
        email: this.info.email,
        status: this.operador == 1 ? true : false,
        ocupacion: this.info.roles[0].name,
        specialities: this.info.specialities,
      });
      // console.log(this.info.company_id);
    },
    DisconneectUser() {
      const db = getDatabase();
      const presenceRef = ref(
        db,
        "connections/" + this.info.company_id + "/" + this.info.id
      );
      // Write a string when this client loses connection
      onDisconnect(presenceRef).set({
        id_user: this.info.id,
        name: this.info.name,
        phone_number: this.info.phone_number,
        email: this.info.email,
        status: false,
        ocupacion: this.info.roles[0].name,
        specialities: this.info.specialities,
      });
    },

    async statusUser() {
      const self = this;
      statusUser({ id: this.info.id, status_id: this.statusOperador })
        .then(function (resp) {
          // console.log("statusUser", resp);
        })
        .catch(function (err) {
          console.log(err);
        });
    },

    testinglistenDelete() {
      const db = getDatabase();
      const starCountRef = ref(
        db,
        "notifications/" + this.info.company_id + "/" + "common"
      );
      onChildRemoved(starCountRef, (snapshot) => {
        const data = snapshot.val();
        // console.log("data", data);
        if (data) {
          this.sonido.pause();
          // this.alert.splice(this.alert.indexOf(data.id), 1);
          // console.log("datosRemovidos",data);
          const index = this.alert.findIndex((items) => {
            return items.id == data.id; //(o algún dato identificador que puedas comparar)
          });
          if (index != -1) {
            this.alert.splice(index, 1);
          }
        }
      });
    },
    ListenAlert() {
      const db = getDatabase();
      const starCountRef = ref(
        db,
        "notifications/" + this.info.company_id + "/" + "common"
      );
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data && this.$store.state.infoLimit.available_videocalls_time > 0) {
          this.alert2 = Object.values(data);
          this.roomdelete = Object.keys(data)[0];
          // this.roomdelete = Object.keys(data);

          this.testing();
        }
      });
    },

    ListenAlertSpeciality() {
      const db = getDatabase();
      const starCountRef = ref(
        db,
        "notifications/" + this.info.company_id + "/" + this.info.id
      );
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data && this.$store.state.infoLimit.available_videocalls_time > 0) {
          // console.log("infoData:,",data);
          this.alert3 = data;
          // console.log("alert3",this.alert3);
          this.testing();
        }
      });
    },

    testing() {
      this.alert = this.alert2.concat(this.alert3);
      // console.log("info alert+aler2+alert3",this.alert);
    },

    DeleteGeneral(sala) {
      // console.log("Informacion",sala);
      if (sala.speciality) {
        this.writeNewPostSpecialyty();
        this.writeNewPost(sala);
      } else {
        this.writeNewPost(sala);
      }
    },

    writeNewPost(sala) {
      const db = getDatabase();
      remove(
        ref(
          db,
          "notifications/" + this.info.company_id + "/" + "common/" + sala.id
        )
      );
      // console.log("que viene", this.roomdelete);

      // console.log("funciona?");
    },
    writeNewPostSpecialyty() {
      const db = getDatabase();
      remove(
        ref(db, "notifications/" + this.info.company_id + "/" + this.info.id)
      );
      //  console.log("funcionaEspecialidad?");
    },
  },
};
</script>

<style scoped>
.paraUsuario {
  color: #feb62d !important;
}
</style>
