<template>
  <div>
    <v-dialog persistent eager width="800" v-model="this.$store.state.modalCallbackContact">
      <v-container v-if="termino != true" style="background: #3364d0" fluid>
        <v-row :class="!$vuetify.breakpoint.mobile ? 'ml-10 mt-1' : 'mt-1'">
          <v-col sm="2" cols="3">
            <v-img
              eager
              :class="!$vuetify.breakpoint.mobile ? 'mt-2 mx-auto' : ' ml-4'"
              :src="require('../../../../assets/iconPhone.png')"
              :max-width="!$vuetify.breakpoint.mobile ? '45px' : '50px'"
            />
          </v-col>
          <v-col sm="6" cols="6">
            <p
              style="color: #feb62d"
              :class="
                !$vuetify.breakpoint.mobile
                  ? 'display-2 font-weight-bold mt-2'
                  : 'display-1 font-weight-bold mt-4 text-center'
              "
            >
              Call Back
            </p>
          </v-col>
          <v-col sm="4" cols="3">
            <div v-if="termino != true" class="mt-3 mr-5 text-end">
              <v-btn @click="$store.commit('setModalCallbackContact',false)" text
                ><v-icon color="#FEB62D" style="font-size: 50px"
                  >mdi-close</v-icon
                ></v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pb-16" v-if="formNumero" style="background: #3364d0">
        <v-row>
          <v-col>
            <div
              class="mx-auto"
              style="
                background: #feb62d;
                border-radius: 100%;
                width: 150px;
                height: 150px;
              "
            >
              <v-img
                eager
                class="mx-auto"
                :src="require('../../../../assets/WomanChico.png')"
                max-width="150"
                style="border-radius: 100%"
              />
            </div>
            <p v-if="llamar == 1" style="color: white" class="text-center mt-4">
              Comunicate con el cliente
            </p>
            <p v-if="llamar == 0" style="color: white" class="text-center mt-4">
              Terminar la llamada
            </p>
            <!-- <p v-if="termino" style="color: white" class="text-center mt-4">
              Fin de la llamada
            </p> -->
          </v-col>
        </v-row>

        <v-container v-if="termino">
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            ¿Que solicitaba el cliente?
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">
            ¿Que hizo el Operador?
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 3" step="3">
            ¿Cual fue la solución?
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card elevation="0">
              <v-textarea
                auto-grow
                v-model="request"
                single-line
                outlined
                label="Respuesta"
              ></v-textarea>
            </v-card>
            <v-btn color="primary" @click="e1 = 2"> Continuar </v-btn>

            <v-btn
              @click="
                solutionCallback(),
                  $store.commit('setModalCallbackContact',false)
              "
              text
            >
              Omitir
            </v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card elevation="0">
              <v-textarea
                auto-grow
                v-model="action"
                outlined
                label="Respuesta"
                single-line
              ></v-textarea>
            </v-card>
            <v-btn color="primary" @click="e1 = 3"> Continuar </v-btn>

            <v-btn @click="e1 = 1" text> Volver </v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card elevation="0">
              <v-textarea
                auto-grow
                v-model="solution"
                outlined
                label="Respuesta"
                single-line
              ></v-textarea>
            </v-card>
            <v-btn
              color="primary"
              @click="
                solutionCallback(),
                  $store.commit('setModalCallbackContact',false)
              "
            >
              Finalizar
            </v-btn>

            <v-btn @click="e1 = 2" text> Volver </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
        </v-container>

        <v-row v-if="llamar == 1">
          <v-col class="text-center">
            <v-btn
              @click="callReturn(), (llamar = 0)"
              height="50"
              text
              width="250"
              style="border: solid 2px white"
              rounded
              ><p style="color: white" class="mt-4">Llamar</p></v-btn
            >
          </v-col>
        </v-row>

        <v-row v-if="llamar == 0">
          <v-col class="text-center">
            <v-btn
              @click="endCall()"
              height="50"
              text
              width="250"
              style="border: solid 2px white"
              rounded
              ><p style="color: white" class="mt-4">Terminar</p></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import { Device } from "twilio-client";
import { tokencallback } from "@/helpers/api/calls.js";
import { mapState } from "vuex";
import { child } from "@firebase/database";
import {
  updateStatusEmail,
  solutionCallback,
  token,
} from "@/helpers/api/calls";
export default {
  data() {
    return {
      e1: 1,
      request: "",
      action: "",
      solution: "",
      id:null,

      callback: false,
      llamada: true,
      formNumero: true,
      valid: false,
      //   phoneNumber: 56934038618,
      deviceStatus: 9,
      llamar: 1,
      termino: false,
      phone_number: null,
      device: null,
      token: null,
      rule:[
        v => ( v && v.length >= 4 ) || "Debe tener minimo 4 caracteres el formulario",
      ]      
    };
  },
  computed: {
    // ...mapState(["infoUserEmail"]),
    disnableForm() {
      return this.request != "" && this.action != "" && this.solution != "";
    },
  },
  watch: {
      '$store.state.dateModalCallback'(newValue, oldValue) {
          console.log(newValue)
          if(newValue){
              this.phone_number = newValue.client_phone;
              this.id = newValue.client_id
            //   console.log(this.phone_number)

          }
          
      }
  },
  methods: {
    setupClient() {
      token()
        .then((res) => {
          this.token = res;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    callReturn() {
      tokencallback()
        .then(async (resp) => {
          // console.log("numero cliente: ", this.phone_number);
          // console.log("id user", this.id_mail);
          this.device = new Device();
          this.device.setup(this.token);
          this.device.ready((device) => {
            this.device.connect({
              phoneNumber: this.phone_number,
            //   video_mail_id: this.id_mail,
            //   user_id: this.$store.state.userData.id


            });
          });

          this.setupHandlers(this.device);
        })
        .catch(function(error) {
          console.error(error);
          console.log("Por la chita");
        });
    },

    setupHandlers(device) {
      device.on("ready", function(_device) {});

      /* Report any errors to the call status display */
      device.on("error", function(error) {
        console.log(error);
      });

      /* Callback for when Twilio Client initiates a new connection */
      device.on("connect", function(connection) {});

      /* Callback for when a call ends */
      device.on("disconnect", function(connection) {});

      /* Callback for when Twilio Client receives a new incoming call */
      device.on("incoming", function(connection) {});
    },

    endCall() {
      this.termino = true;
      this.llamar = 3;
      this.deviceStatus = 9;
      this.device.destroy();
      this.device = null;
    },
    isNumber(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else event.preventDefault();
    },

    async solutionCallback() {
      const self = this;
      if(this.request == ""){
        this.request = "Sin Información"
      }
      if(this.action == ""){
        this.action = "Sin Información"
      }
      if(this.solution == ""){
        this.solution = "Sin Información"
      }

      solutionCallback({
        attentionable_id: this.id,
        request: this.request,
        action: this.action,
        solution: this.solution,
        attentionable_type: 1,
      })
        .then((resp) => {
          console.log("Funciono status contacto", resp);
          console.log(resp);
          (this.request = ""), (this.action = ""), (this.solution = "");
        })
        .catch(function(err) {
          console.log("error", err);
        });
    },
  },
  mounted() {
    this.setupClient();
  },
};
</script>


