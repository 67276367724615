<template>
  <v-container>
    <v-dialog width="750" v-model="refe">
      <v-container style="background:white">
        <div>
          <v-btn icon @click="refe = false"
            ><v-icon  size="30" color="#2250b3">mdi-close</v-icon></v-btn
          >
        </div>
        <div class="text-center">
          <p style="color:#2250b3" class="display-1">Codigo de Referido</p>
        </div>
        <v-container>
          <div
            style="background:rgb(194, 192, 192)"
            class="rounded-lg text-center"
          >
            <p style="color:white" class="display-1">{{ code }}</p>
          </div>
        </v-container>
        <v-container class="text-center">
      <ShareNetwork
          style="text-decoration: none"
          v-for="network in networks"
          :key="network.network"
          :network="network.network"
          :url="code"
          title="Visita Click2Call con mi codigo de referrido"
        >
        <v-icon class="mr-5" size="50" :color="network.color" >{{network.icon}}</v-icon>
      </ShareNetwork>          
        </v-container>
      </v-container>
    </v-dialog>
    <div>
      <div class="mb-n8 ml-n4">
        <v-btn
          text
          color="white"
          block
          large
          class="px-3 d-flex flex-row justify-start align-center"
          @click="(refe = true), getCode()"
        >
          <v-icon color="#2250b3" class="mr-4">mdi-account-plus</v-icon>
          <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
            Codigo Referido
          </p>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { getCodeReferido } from "@/helpers/api/calls";
export default {
  data() {
    return {
      refe: false,
      code: "",

      networks:[
        {network:'facebook', name:'facebook', icon:'mdi-facebook', color: '#1877f2'},
        {network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', color: '#1da1f2' },
        { network: 'messenger', name: 'messenger', icon: 'mdi-facebook-messenger', color: '#0084ff' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: '#25d366' },
        //  { network: 'sms', name: 'SMS', icon: 'mdi-message-processing', color: '#333333' },
         { network: 'email', name: 'Email', icon: 'mdi-email', color: '#333333' },
      ]
    };
  },
  methods: {
    async getCode() {
      const refCode = await getCodeReferido({
        id: this.$store.state.userData.id,
      });
      this.code = refCode.data;
      // console.log(refCode)
    },
  },
};
</script>

<style></style>
