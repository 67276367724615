<template>
  <v-app>
    <v-row class="d-flex flex-nowrap">
      <v-col class="pa-0" cols="1" md="3" xl="2"
        v-if="$route.name !== 'Login' && $route.name !== 'FlowReturn' && $route.name !== 'NuevoSocketVideo'">
        <Menu />
      </v-col>
      <v-col class="pa-0">
        <router-view />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Drawer from "./components/Panel/Drawer.vue";
import DrawerMobile from "./components/Panel/DrawerMobile.vue";
import Menu from "./views/Menu.vue";
import { mapActions } from 'vuex';

export default {
  name: "App",
  data: () => ({
    //
  }),
  mounted() {
    this.initFirebase();
		// this.$router.push({
		// 	name: "NuevoSocketVideo",
		// 	params: { sala_id: '1234567890', id: 1 },
		// });
  },
  methods: {
    initFirebase() {
      // Click2Call Original
      const firebaseConfig = {
        apiKey: "AIzaSyC__hpErbrF-l9vP_7mWI8FSXSjqJFAyG0",
        authDomain: "click2call-671d1.firebaseapp.com",
        databaseURL: "https://click2call-671d1-default-rtdb.firebaseio.com",
        projectId: "click2call-671d1",
        storageBucket: "click2call-671d1.appspot.com",
        messagingSenderId: "360045088915",
        appId: "1:360045088915:web:aa0f190408fd668efcfd66",
        measurementId: "G-J9DTZPLG1S",
      };
      // Dev
      // const firebaseConfig = {
      //   apiKey: "AIzaSyD4yWqis7Qny7gfp5bugquN1o1c1ZCzxBU",
      //   authDomain: "click2call-dev-d9306.firebaseapp.com",
      //   databaseURL: "https://click2call-dev-d9306-default-rtdb.firebaseio.com",
      //   projectId: "click2call-dev-d9306",
      //   storageBucket: "click2call-dev-d9306.appspot.com",
      //   messagingSenderId: "643543297069",
      //   appId: "1:643543297069:web:1edbbfab15cf737cf51ca4",
      //   measurementId: "G-YPR9NQ3VW4",
      // };
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
    },
  },
  components: { Drawer, DrawerMobile, Menu },
};
</script>

<style>
.blue-text {
  color: "#2250b3";
}
</style>
