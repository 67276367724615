<template>
  <v-container fluid style="background: #eaeef2; height: 100%">
    <div>
      <v-row class="ma-5">
        <v-col class="pt-6" sm="8" md="8" cols="12">
          <VigilanciaPage />
        </v-col>

        <v-col cols="4">
          <DrawerRight class="d-none d-sm-flex" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Drawer from "../Drawer.vue";
import DrawerMobile from "../DrawerMobile.vue";
import DrawerRight from "../DrawerLateralDerecho/DrawerRight.vue";
import VigilanciaPage from "./Vigilancia/VigilanciaPage.vue";
export default {
  name: "vigilancia",
  components: {
    DrawerMobile,
    VigilanciaPage,
    Drawer,
    DrawerRight,
  },
  mounted() {
    this.$store.commit("setCardState", null);
  },
};
</script>

<style></style>
