<template>
  <v-container fluid style="background: #eaeef2; height: 100%" class="pa-0 ">
    <div :class="$vuetify.breakpoint.mobile ? '' : 'd-flex flex-row'">
      <div class="d-flex flex-nowrap ma-5">
        <Drawer class="d-none d-sm-flex" />
        <DrawerMobile style="position:absolute;top:0px;left:0px" class="d-flex d-sm-none" />
      </div>
      <v-row class="ma-5 d-flex justify-space-between">
        <v-col class="py-0" md="8" lg="8" xl="8" cols="12">
          <Plans />
        </v-col>

        <v-col cols="4">
          <DrawerRight class="d-none d-sm-flex" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Drawer from "../Drawer.vue";
import DrawerMobile from "../DrawerMobile.vue";
import DrawerRight from "../DrawerLateralDerecho/DrawerRight.vue";
import Plans from "../Plans/Plans.vue";
export default {
  name: "vigilancia",
  components: {
    DrawerMobile,
    Plans,
    Drawer,
    DrawerRight,
  },
  mounted() {
    this.$store.commit("setCardState", null);
  },
};
</script>

<style></style>
