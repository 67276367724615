<template>
  <v-container class="pa-0">
    <v-dialog width="500" v-model="add">
      <v-container style="background: white">
        <div>
          <v-btn color="#2250b3" icon @click="add = false">
            <v-icon size="30"> mdi-close </v-icon>
          </v-btn>
        </div>
        <div class="text-center">
          <p style="color: #2250b3" class="display-1">
            Agregar numero llamadas telefonicas
          </p>
        </div>

        <v-container>
          <v-form ref="addPhone" v-model="valid">
            <v-select
              class="mb-n3"
              filled
              rounded
              single-line                
              v-model="infoSelect"
              :items="users"
              item-text="name"
              label="Personal"
              return-object
            ></v-select>
            <div v-if="infoSelect != ''" class="text-center">
                <p><span class="font-weight-bold">Numero Telefonico:</span> <br>
                {{this.infoSelect.phone_number}}</p>

                <v-text-field
                  class="mb-n3"
                  filled
                  rounded
                  single-line                
                  v-model="otherNumber"
                  label="Otro Numero telefonico"
                ></v-text-field>

            </div>

            <div class="text-center">
                <v-btn :disabled="!disnable" style="color:white" class="rounded-lg" color="#2250b3" @click="addPhoneNumber()">Agregar numero</v-btn>
            </div>
          </v-form>
        </v-container>
      </v-container>
    </v-dialog>
    <div>
      <v-snackbar color="#2250b3" timeout="2000" v-model="msn" >
         <p style="color:white" class="text-center subtitle-1 font-weight-normal">Numero Añadido</p> 
      </v-snackbar>           
    </div>

    <v-btn @click="add = true, getUsers()" small dark block tile color="#2250b3">
			<v-icon class="mr-2">mdi-plus-circle</v-icon>
			Agregar
		</v-btn>
  </v-container>
</template>

<script>
import {getUsers, addPhone} from '../../../../helpers/api/calls'
export default {
  props: {
    update: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
        msn:false,
    valid:null,
      add: false,
      infoSelect:'',
      information:null,
      users:[],
      otherNumber:`+569`,
      
    };
  },
  computed: {
      disnable() {
          return this.infoSelect != '' 
      }
  },  

  methods: {
      async getUsers() {
        const users = await getUsers()
        this.users = users.data
        console.log("usuarios",this.users);
    },
    async addPhoneNumber(){
      if(this.otherNumber == `+569`){
        addPhone({
            number:this.infoSelect.phone_number,
            priority:1,
            is_call_center: 0,
            user_id:this.infoSelect.id,
            company_id:this.infoSelect.company_id

        })
        .then((resp) => {
            if(resp){
                this.add = false
                this.infoSelect = ''
                this.otherNumber =`+569`  
                this.update()
                this.msn = true
                // console.log("SE AGREGO NUMERO,",resp);
            }
        }).catch((err) => {
            console.log("ERROR NUMERO",err);
            
        });
      }
      else{
        addPhone({
            number:this.otherNumber,
            priority:1,
            is_call_center: 0,
            user_id:this.infoSelect.id,
            company_id:this.infoSelect.company_id

        })
        .then((resp) => {
            if(resp){
                this.add = false
                this.infoSelect = ''
                this.otherNumber =`+569`                
                this.update()
                this.msn = true
                // console.log("SE AGREGO NUMERO,",resp);
            }
        }).catch((err) => {
            console.log("ERROR NUMERO",err);
            
        });        
      }
    }
  },
};
</script>

<style>
</style>