<template>
  <v-container class="pa-0">
    <v-dialog width="700" v-model="dialogDelete">
      <v-container style="background: white">
        <div>
          <v-btn color="#2250b3" icon @click="dialogDelete = false">
            <v-icon size="30"> mdi-close </v-icon>
          </v-btn>
        </div>
        <div class="text-center">
          <p style="color: #2250b3" class="display-1">
            ¿Esta seguro de eliminar esta Especialidad?
          </p>
          <div class="text-center">
            <span class="title">{{ this.name }}</span> <br />
            <!-- <span class="title">{{this.id}}</span> <br> -->
          </div>
        </div>

        <div class="d-flex justify-space-around mt-10">
          <v-btn
            @click="dialogDelete = false"
            color="#2250b3"
            style="color: white"
            class=""
            >Cancelar</v-btn
          >
          <v-btn @click="deleteEsp()" style="color: white" color="red"
            >Eliminar</v-btn
          >
        </div>
      </v-container>
    </v-dialog>
    <!-- <div>
      <v-snackbar :color="color" timeout="3000" v-model="msn" >
         <p style="color:white" class="text-center subtitle-1 font-weight-normal">{{text}}</p> 
      </v-snackbar>           
    </div>       -->
    <div class="d-flex justify-end">
      <v-btn @click="dialogDelete = true" class="mt-n2" color="red" icon
        ><v-icon>mdi-delete</v-icon></v-btn
      >
    </div>
  </v-container>
</template>

<script>
import { deleteAdminEsp } from "@/helpers/api/calls";
import { mapState } from "vuex";
export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    nombre: {
      type: String,
      default: null,
    },
    reload: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      dialogDelete: false,
      name: this.nombre,
    };
  },
  computed: {
    ...mapState(["modalDeleteEsp"]),
  },
  methods: {
    async deleteEsp() {
      deleteAdminEsp({
        id: this.id,
        name: this.name,
        description: "test",
      })
        .then((resp) => {
          if (resp) {
            console.log("RESP", resp);
            this.dialogDelete = false;
            this.reload()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>