<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-btn @click="attentionsHoy()" class="fondo" width="100%" height="170">
          <div style="color: white" class="text-center pt-3">
            <v-icon color="white" size="45">mdi-account-multiple</v-icon>
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="#feb62d"
              ></v-progress-circular>
            </div>
            <div v-if="!loading">
              <p class="display-1">{{ dailyAttentions }}</p>
            </div>
            <p class="subtitle-1 text-capitalize">Atenciones hoy</p>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn @click="attentionsMes()" width="100%" height="170" class="fondo">
          <div style="color: white" class="text-center pt-3">
            <v-icon color="white" size="45">mdi-account-multiple</v-icon>
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="#feb62d"
              ></v-progress-circular>
            </div>
            <div v-if="!loading">
              <p class="display-1">{{ dailyAttentionsMes }}</p>
            </div>
            <p class="subtitle-1 text-capitalize">Atenciones en el mes</p>
          </div>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-card height="170" class="fondo">
          <div style="color: white" class="text-center pt-3">
            <v-icon color="white" size="45">mdi-clock-outline</v-icon>
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="#feb62d"
                class="my-1"
              ></v-progress-circular>
            </div>
            <div v-if="!loading">
              <p class="display-1">{{ timeActiveDay }}</p>
            </div>
            <p class="subtitle-1">tiempo activo hoy</p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card height="170" class="fondo">
          <div style="color: white" class="text-center pt-3">
            <v-icon color="white" size="45">mdi-clock-outline</v-icon>
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="#feb62d"
                class="my-3"
              ></v-progress-circular>
            </div>
            <div v-if="!loading">
              <p class="text-md-h5 text-xl-h5">{{ timeactiveMes }}</p>
            </div>
            <v-container class="py-0">
              <p class="subtitle-1 mt-n4">tiempo activo en el mes</p>
            </v-container>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row > 
      <v-col md="6" xl="6" lg="6" cols="6">
        <v-btn
          @click="$store.commit('setEmailRecibido', true)"
          width="100%"
          height="170"
          class="fondo"
        >
          <div style="color: white" class="text-center pt-3">
            <v-icon class="ml-n3" color="white" size="45"
              >mdi-email-fast-outline</v-icon
            >
            <div v-if="loadingMail">
              <v-progress-circular
                indeterminate
                color="#feb62d"
                class="my-3"
              ></v-progress-circular>
            </div>
            <div v-if="!loadingMail">
              <p class="display-1">{{ this.$store.state.countRecibidoMes }}</p>
            </div>
            <p class="subtitle-1 text-capitalize mt-n3">VideoEmail Recibidos</p>
          </div>
        </v-btn>
      </v-col>
      <v-col v-if="rol != 4" md="6" xl="6" lg="6" cols="6">
        <v-btn
          @click="modalCorreoD()"
          width="100%"
          height="170"
          class="fondo"
        >
          <div style="color: white" class="text-center pt-3">
            <v-icon color="white" size="45">mdi-email-receive-outline</v-icon>
            <div v-if="loadingMail">
              <v-progress-circular
                indeterminate
                color="#feb62d"
                class="my-3"
              ></v-progress-circular>
            </div>
            <div v-if="!loadingMail">
              <p class="display-1">{{ this.$store.state.countMes }}</p>
            </div>
            <p class="subtitle-1 mt-n3 text-capitalize">VideoEmail derivados</p>
          </div>
        </v-btn>
      </v-col>

      <v-col v-if="rol != 4">
        <v-btn
          @click="modalWsp()"
          width="100%"
          height="170"
          class="fondo"
        >
          <div style="color: white" class="text-center pt-3">
            <v-icon color="white" size="45">mdi-whatsapp</v-icon>
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="#feb62d"
                class="my-3"
              ></v-progress-circular>
            </div>
            <div v-if="!loading">
              <p class="display-1">{{ wspsends }}</p>
            </div>
            <p class="subtitle-1 mt-n3 text-capitalize">WhatsApp Enviados</p>
          </div>
        </v-btn>
      </v-col>

      <v-col>
        <v-btn
          @click="modalContact()"
          width="100%"
          height="170"
          class="fondo"
        >
          <div style="color: white" class="text-center pt-3">
            <v-icon color="white" size="45">mdi-pencil</v-icon>
            <div v-if="loading">
              <v-progress-circular
                indeterminate
                color="#feb62d"
                class="my-3"
              ></v-progress-circular>
            </div>
            <div v-if="!loading">
              <p class="display-1">{{ peding }}</p>
            </div>
            <p class="subtitle-1 mt-n3 text-none">Mensajes de Contacto</p>
          </div>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import {
  dateUser,
  infoVideoEmail,
  getAttentions,
  getWspReport,
  getContact,
  getVideoCall
} from "../../../helpers/api/calls";
import { mapActions } from 'vuex';
export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  filters: {
    toDate: function (date) {
      return moment(date).minute();
    },
    toMes: function (date) {
      return moment(date).format("hh:mm");
      // return moment(date).hours()
    },
  },
  data() {
    return {
      wspsends: "",
      peding: "",
      loading: true,
      loadingMail: true,
      datos: [],
      info: "",
      timeActiveDay: "",
      timeactiveMes: "",
      dailyAttentions: "",
      dailyAttentionsMes: "",
      rol: "",
      EmailInfo: [],
      id_company: null,
    };
  },

  mounted() {
    this.infoVideoEmail();
  },
  watch: {
    id(newValue) {
      if (this.id && newValue) {
        this.dateUser();
      }
    },
    "$store.state.userData"(newValue) {
      if (newValue) {
        this.userData();
        this.id_company = newValue.company_id;
      }
    },
  },
  methods: {
    ...mapActions('dashboard',['getContact'] ),
    
    async dateUser() {
      const info = await dateUser({ id: this.id });
			console.log('dailyAttentionsMes: ', info)
      this.info = info.data;
      // this.$store.commit("setInfoAtenciones", info.data);
      // console.log("IFNORMACION DE TODO",info)
      this.timeActiveDay = info.data.dailyActiveTime;
      this.timeactiveMes = info.data.monthlyActiveTime;
      this.dailyAttentions = info.data.dailyAttentions;
      this.dailyAttentionsMes = info.data.monthlyAttentions;
      this.peding = info.data.pendingContacts;
      this.wspsends = info.data.sentWhatsapps;
      this.loading = false;
    },
    userData() {
      this.rol = this.$store.state.userData.roles[0].id;
      this.datos = this.$store.state.userData;
    },



    async infoVideoEmail() {
      const infoEmail = await infoVideoEmail();
      this.EmailInfo = infoEmail.data;
      this.loadingMail = false;
    },

    async modalCorreoD(){
      console.log("derivado")
      this.$store.commit('setModalEmailDerivado', true)
      const data = await getVideoCall()
      this.$store.commit('dashboard/setMessageDerivado',data.data)
    },

    async modalContact(){
      this.$store.commit('setModalContac', true)
      this.getContact()
    },

    async modalWsp(){
      this.$store.commit('setModalWhatsapp', true)
      const data = await getWspReport()
      this.$store.commit("dashboard/setWspSends", data.data)
    },

    async attentionsHoy() {
      getAttentions({
        id: this.id_company,
        type: 1,
      })
        .then((resp) => {
          this.$store.commit("setModalHoy", true);
          this.$store.commit("dashboard/setAttentionDay", resp.data);
          
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async attentionsMes() {
      getAttentions({
        id: this.id_company,
        type: 2,
      })
        .then((resp) => {
          console.log(resp)
          this.$store.commit("setModalMes", true);
          this.$store.commit("dashboard/setAttentionMonth", resp.data.reverse());
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.fondo {
  background-image: linear-gradient(to bottom right, #2fa5ba, #2250b3);
}
</style>