<template>
  <v-container fluid style="background: #eaeef2; height: 100%">
    <div>
      <!-- <div class="d-flex flex-nowrap ma-5">
        <Drawer class="d-none d-sm-flex" />
        <DrawerMobile style="position:absolute;top:0px;left:0px" class="d-flex d-sm-none"/>
      </div> -->
      <v-row class="pt-5">
        <v-col class="py-0" md="8"  cols="12">
            <Administracion/>
        </v-col>
        <v-col cols="4">
          <v-container>
          <DrawerRight class="hidden-sm-and-down" />
          </v-container>
        </v-col>
      </v-row>      
    </div>
  </v-container>
</template>

<script>
import Drawer from '../Drawer.vue'
import DrawerMobile from '../DrawerMobile.vue'
import DrawerRight from '../DrawerLateralDerecho/DrawerRight.vue'
import Administracion from './Administracion/Administracion.vue';
export default {
    name:'vigilancia',
    components: {
    DrawerMobile,
    Drawer,
    DrawerRight,
    Administracion
},
    mounted () {
      this.$store.commit("setCardState", null);
    },
};
</script>

<style>
</style>