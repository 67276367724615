<template>
  <v-card class="mx-auto">
    <div>
      <v-row>
        <v-col cols="6">
          <v-card-text class="ml-5">
            <p style="color:#2250b3" class="display-1 mt-3">
              Bolsas
            </p>
            <p class="text-h6">
              Compre una bolsa para ser utilizada en su empresa
            </p>
            <v-divider></v-divider>
          </v-card-text>
        </v-col>
        <v-col class="d-flex align-center justify-center" cols="6">
          <v-btn @click="$store.commit('setPaymentWidget',!$store.state.paymentWidget)" color="#2250b3" large outlined icon>
            <v-icon size="30">
              mdi-cart-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-card-text>
      <v-row dense class="ma-1 d-flex justify-space-between">
        <v-col class="px-5" md="12" lg="6" xl="4" cols="12" v-for="bolsa in packages" :key="bolsa.id">
          <Package :pack="bolsa" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { getPackages } from "../../../helpers/api/package";
import Package from "./Package.vue";

export default {
  components: { Package },
  data: () => ({
    packages: [],
  }),
  methods: {
    async getPackages() {
      const response = await getPackages();
      this.packages = response.data;
    },
  },
  async mounted() {
    await this.getPackages();
  },
};
</script>

<style></style>
