<template>
  <v-container class="py-0">
    <v-card>
      <v-container>
        <p class="display-1">Usuarios</p>
      </v-container>
      <v-container>
        <Register />
      </v-container>
      <v-row>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Rol</th>
                  <th class="text-left">Correo</th>
                  <th class="text-center">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user,index) in listUsers" :key="index">
                  <td>{{ user.name | mayus }}</td>
                  <td>{{ user.roles[0] ? user.roles[0].name: 'Sin Información' }}</td>
                  <td>{{user.email}}</td>
                  <td >
                      <div :class="$vuetify.breakpoint.mobile" class="d-flex flex-columns ">
                          <v-btn color="blue" @click="modalUpdate(user)" class="mt-3 mr-3" outlined icon>
                            <v-icon>mdi-badge-account</v-icon>
                          </v-btn>
                          <v-btn color="red" @click="modalDelete(user)" class="mt-3" outlined icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                      </div>
                  </td>
                  
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
    <Delete class="d-none"/>
    <Editar class="d-none" />
  </v-container>
</template>

<script>
import {getUsers} from '../../../../helpers/api/calls'
import Editar from './Editar.vue'
import Delete from './Delete.vue'
import Register from '../../RegisterUser/Register.vue'
import { mapActions, mapState } from 'vuex'
  export default {
      components: {
          Editar,
          Delete,
          Register,
      },
      filters: {
        mayus: function(data) {
          return data.charAt(0).toUpperCase() + data.slice(1);
        }
      },
    data () {
      return {
        // users:[],
        bool:1,
      }
    },
    mounted () {
      this.pushUsers()
      console.log("USUARIOS",this.listUsers)
    },
    computed: {
      ...mapState('CrudCuentas',['listUsers'])
    },
    methods: {
      ...mapActions('CrudCuentas',['pushUsers']),
      modalUpdate(item){
        // console.log(item)
        this.$store.commit('CrudCuentas/setModalUpdate', true)
        this.$store.commit('CrudCuentas/setinfoUpdate',item)
      },
      modalDelete(item){
        console.log(item)
        this.$store.commit('CrudCuentas/setModalDelete',true)
        this.$store.commit('CrudCuentas/setDatosElimnar',item)
      }
    },
  }
</script>

<style>
</style>