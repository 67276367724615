// import {endpoints} from './endpoints'
import request from "@/helpers/request";
import store from "../../store/index.js";
import { endpoints } from "./endpoints";

export function login(email, password) {
  return new Promise((resolve, reject) => {
    const data = {
      email: email,
      password: password,
    };
    request({
      url: endpoints.login,
      method: "POST",
      data,
    })
      .then(async function(response) {
        setUserToken(response.data);
        resolve(response.data);
        // console.log("Probando respuesta",response);
      })
      .catch(function(error) {
        // console.log(error)
        reject(error);
      });
  });
}
function setUserToken(token) {
  localStorage.setItem("userToken", token.access_token);
}

export function userData() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.userData,
      method: "get",
    })
      .then(async function(response) {
        store.commit("setUserData", response.data);
        resolve(response);
        // console.log(response.data);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getAdminEsp() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getAdminEsp,
      method: "get",
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getContact(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getContact,
      method: "get",
      params: data,
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getAllInformation() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getAllInformation,
      method: "get",
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function AddAdminEsp(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.AddAdminEsp,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function deleteAdminEsp(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteAdminEsp + "/" + data.id,
      method: "delete",
      data,
    })
      .then(async function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}
export function updateAdminEsp(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.updateAdminEsp + "/" + data.id,
      method: "put",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}

export function getVideoEmail() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getVideoEmail,
      method: "get",
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function limit() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.limit,
      method: "get",
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function rol() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.rol,
      method: "get",
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getPlan() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getPlan,
      method: "get",
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getLimitCompany() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getLimitCompany,
      method: "get",
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getVideoEmailID(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getVideoEmailID + "/" + data.id,
      method: "get",
      data,
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getservicesCompany(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getservicesCompany + "/" + data.id,
      method: "get",
      data,
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}

export function getVideoUser(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getVideoUser + "/" + data.id,
      method: "get",
      data,
    })
      .then(async function(response) {
        resolve(response);
        //  store.commit('setUrlVideoEmail',response)
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}
export function getAudioOrVideo(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getAudioOrVideo + "/" + data.id,
      method: "get",
      data,
    })
      .then(async function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}

export function registroUser(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.registroUser + "/" + data.id,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function updateStatusEmail(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.updateStatusEmail + "/" + data.id,
      method: "put",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function AddSpeciality(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.AddSpeciality + "/" + data.id,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}
export function postCompany(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.postCompany,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}
export function setHours(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.setHours + "/" + data.id + "/setWorkingHours",
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}

export function sendMessage(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.sendMessage + "/" + data.id,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}
export function solutionCallback(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.solutionCallback,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}
export function updateVideoMail(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.updateVideoMail + "/" + data.id,
      method: "put",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getVideoCall(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getVideoCall,
      method: "get",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getAttentions(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getAttentions + "/" + data.id + "/attentions",
      method: "get",
      params: data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getListVideoEmail() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getListVideoEmail,
      method: "get",
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getListVideoEmailDerivados() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getListVideoEmailDerivados,
      method: "get",
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getMailRespondidos(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getMailRespondidos,
      method: "get",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function dateUser(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.dateUser + "/" + data.id,
      method: "get",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function patchsetAnswered(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.patchsetAnswered + "/" + data.id + "/setAnswered",
      method: "patch",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function getUsers(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getUsers,
      method: "get",
      data,
    })
      .then(function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function infoVideoEmail(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.infoVideoEmail,
      method: "get",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}

export function speciality(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.speciality,
      method: "get",
      data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function UpdateInfoVideoCall(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.UpdateInfoVideoCall + "/" + data.id,
      method: "put",
      params: data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function statusUser(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.statusUser + "/" + data.id,
      method: "patch",
      params: data,
    })
      .then(function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function registerUser(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.registerUser,
      method: "post",
      data,
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function postMsn(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.postMsn + "/" + data.id + "/reply",
      method: "post",
      data,
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}

export function tokencallback(data) {
  return new Promise((resolve, reject) => {
    request({
      url: "api/token/newToken",
      method: "get",
      data,
    })
      .then(async function(response) {
        resolve(response);
        //   console.log(response);
      })
      .catch(function(error) {
        reject(error);
        //   console.log(error);
        //   console.log("error");
      });
  });
}
export function updateUser(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.updateUser + "/" + data.id,
      method: "post",
      data,
    })
      .then(async function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}
export function patchCompany(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.patchCompany + "/" + data.id,
      method: "patch",
      data,
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function deleteUser(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteUser + "/" + data.id,
      method: "delete",
      data,
    })
      .then(async function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}

//VideoEmail

export function videollamada(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.videollamada,
      method: "post",
      data: data,
    })
      .then(async function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

//RUTAS WALLET
export function getInfoWallet(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getInfoWallet + "/" + data.id + "/" + "wallet",
      method: "get",
      data,
    })
      .then(async function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}
export function getListBank() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getListBank,
      method: "get",
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}
export function postWithdrawal(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.postWithdrawal,
      method: "post",
      data,
    })
      .then(async function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}
export function gettypeAccount() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.gettypeAccount,
      method: "get",
    })
      .then(async function(response) {
        resolve(response);
        // console.log(response);
      })
      .catch(function(error) {
        reject(error);
        // console.log(error);
      });
  });
}

//Referidos

export function getCodeReferido(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getCodeReferido + "/" + data.id + "/" + "referral",
      method: "get",
      data,
    })
      .then(async function(response) {
        resolve(response);
        console.log(response);
      })
      .catch(function(error) {
        reject(error);
        console.log(error);
      });
  });
}

//

export function token() {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getToken,
      method: "get",
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function postWsp(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.postWsp,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function getWspReport(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.getReportWsp,
      method: "get",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function addPhone(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.addPhone,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
export function addEmail(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.addEmail,
      method: "post",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
export function deletePhone(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.deletePhone + "/" + data.id,
      method: "delete",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
export function deleteEmail(data) {
  return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteEmail + "/" + data.id,
      method: "delete",
      data,
    })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
