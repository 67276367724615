<template>
  <v-container>
    <v-dialog persistent width="800" v-model="modalInfo">
      <v-card height="550" class="px-4">
        <div class="text-end mb-n5">
          <v-btn @click="closeModal()" color="black" class="mt-3" icon>
            <v-icon size="30"> mdi-close </v-icon>
          </v-btn>
        </div>
        <v-container class="display-1 text-center">
          <p class="display-1 text-break">Información del usuario <br> {{ nombre }}</p>
        </v-container>

        <v-row>
          <v-col cols="6">
            <v-card
              class="fondo d-flex align-center justify-center"
              height="170"
            >
              <div v-if="!loading">
                <v-progress-circular
                  indeterminate
                  color="amber"
                ></v-progress-circular>
              </div>
              <div v-else style="color: white" class="text-center pt-3">
                <v-icon color="white" size="45">mdi-account-multiple</v-icon>
                <p class="display-1">{{ dailyAttentions }}</p>
                <p class="subtitle-1">Atenciones hoy</p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card height="170" class="fondo d-flex align-center justify-center">
              <div v-if="!loading">
                <v-progress-circular
                  indeterminate
                  color="amber"
                ></v-progress-circular>
              </div>
              <div v-else style="color: white" class="text-center pt-3">
                <v-icon color="white" size="45">mdi-clock-outline</v-icon>
                <p class="display-1">{{ timeActiveDay }}</p>
                <p class="subtitle-1">tiempo activo hoy</p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card height="170" class="fondo d-flex align-center justify-center">
              <div v-if="!loading">
                <v-progress-circular
                  indeterminate
                  color="amber"
                ></v-progress-circular>
              </div>
              <div v-else style="color: white" class="text-center pt-3">
                <v-icon color="white" size="45">mdi-account-multiple</v-icon>
                <p class="display-1">{{ dailyAttentionsMes }}</p>
                <p class="subtitle-1">Atenciones en el mes</p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card height="170" class="fondo d-flex align-center justify-center">
              <div v-if="!loading">
                <v-progress-circular
                  indeterminate
                  color="amber"
                ></v-progress-circular>
              </div>
              <div v-else style="color: white" class="text-center pt-3">
                <v-icon color="white" size="45">mdi-clock-outline</v-icon>
                <p class="text-md-h5 text-xl-h5">{{ timeactiveMes }}</p>
                <v-container>
                  <p class="subtitle-1 mt-n4">tiempo activo en el mes</p>
                </v-container>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { dateUser, infoVideoEmail } from "../../../../helpers/api/calls";
import { mapState } from "vuex";
export default {
  filters: {
    toDate: function (date) {
      return moment(date).minute();
    },
    toMes: function (date) {
      return moment(date).format("hh:mm");
    },
  },
  data() {
    return {
      loading: false,
      nombre: "",
      id: null,
      info: "",
      timeActiveDay: "",
      timeactiveMes: "",
      dailyAttentions: "",
      dailyAttentionsMes: "",
      rol: "",
      EmailInfo: [],
    };
  },

  mounted() {
    this.infoVideoEmail();
  },
  watch: {
    "$store.state.userData"(newValue) {
      if (newValue) {
        this.userData();
      }
    },
    information(newValue) {
      if (newValue) {
        this.nombre = newValue.name;
        this.id = newValue.id_user;
        this.dateUser();
      }
    },
  },
  computed: {
    ...mapState("vigilancia", ["modalInfo", "information"]),
  },
  methods: {
    closeModal() {
      this.$store.commit("vigilancia/setModalInfo", {
        status: false,
        information: null,
      });
      this.loading = false;
    },
    async dateUser() {
      const info = await dateUser({ id: this.id });
      (this.loading = true), (this.info = info.data);
      this.dailyAttentions = info.data.dailyAttentions;
      this.dailyAttentionsMes = info.data.monthlyAttentions;
      this.timeActiveDay = info.data.dailyActiveTime;
      this.timeactiveMes = info.data.monthlyActiveTime;
    },
    userData() {
      this.rol = this.$store.state.userData.roles[0].id;
    },
    async infoVideoEmail() {
      const infoEmail = await infoVideoEmail();
      this.EmailInfo = infoEmail.data;
    },
  },
};
</script>

<style scoped>
.fondo {
  background-image: linear-gradient(to bottom right, #2fa5ba, #2250b3);
}
</style>