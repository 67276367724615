<template>
  <v-container>
    <v-dialog width="1000" persistent v-model="this.$store.state.modalMes">
      <v-card>
        <v-container class="d-flex flex-columns">
          <div>
            <v-btn
              color="#2250b3"
              icon
              class="mt-2"
              @click="$store.commit('setModalMes', false)"
            >
              <v-icon size="30"> mdi-close </v-icon>
            </v-btn>
          </div>
          <div class="ml-5">
            <p
              style="color: #2250b3"
              class="mt-3 subtitle-1 font-weight-regular"
            >
              Personas atendidas mensual
            </p>
          </div>

          <div class="mx-auto">
            <v-text-field label="Buscar Atención" class="rounded-lg" outlined>
            </v-text-field>
          </div>
        </v-container>
        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="dateId"
          disable-sort
          :items-per-page="5"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | toDate }} {{ item.created_at | Hour }}
          </template>
          <template v-slot:[`item.tiempo`]="{ item }">
            <div v-if="item.attentionable == null">S/info</div>
            <div v-else-if="item.attentionable.CallDuration">
              {{ item.attentionable.CallDuration }} s
            </div>
            <div v-else>
              {{ item.attentionable.connected_at | toHour }} a
              {{ item.attentionable.ended_at | toHour }}
            </div>
          </template>
          <template v-slot:[`item.tipoAtencion`]="{ item }">
            {{
              item.attentionable_type.slice(11) == "Contact"
                ? "Contacto"
                : item.attentionable_type.slice(11)
            }}
          </template>
          <!-- <template v-slot:[`item.tipoAtencion`]="{ item }">
					Videollamadas
				</template> -->
          <template v-slot:[`item.profesional`]="{ item }">
            <div v-if="item.user != null">
              <p>{{ item.user.name }}</p>
            </div>
          <div v-else-if="[item.response != null]">
            <p v-if="item.response == null">Sin información</p>
          </div>
          <div v-else>Sin Información</div>
          </template>

          <template v-slot:[`item.observacion`]="{ item }">
            <v-btn
              v-if="item.attentionable_type.slice(11) !== 'Contact'"
              color="#3364d0"
              @click="setInfo(item)"
              class="ml-3"
              icon
              ><v-icon>mdi-information-outline</v-icon></v-btn
            >
            <!-- <v-btn style="display:none" v-if="item.attentionable == null">
            </v-btn> -->

            <v-btn
              v-if="item.attentionable_type.slice(11) == 'Callback'"
              color="#3364d0"
              @click="getAudioOrVideo(item)"
              class="ml-3"
              icon
              ><v-icon>mdi-play-circle-outline</v-icon></v-btn
            >
            <v-btn
              v-else-if="item.attentionable_type.slice(11) == 'Videocall'"
              color="#3364d0"
              @click="getAudioOrVideo(item)"
              class="ml-3"
              icon
              ><v-icon>mdi-play-circle-outline</v-icon></v-btn
            >
            <v-btn
              v-else-if="item.attentionable_type.slice(11) == 'Contact'"
              color="#feb62d"
              @click="getInfoContact(item)"
              class="ml-3"
              icon
              ><v-icon>mdi-information-outline</v-icon></v-btn
            >
          </template>
        </v-data-table>

        <v-dialog hide-overlay :retain-focus="false" width="800" v-model="obs">
          <v-container style="background: white">
            <div class="mb-16">
              <v-btn color="#3364d0" icon @click.stop="obs = false"
                ><v-icon size="30">mdi-close</v-icon></v-btn
              >
              <p style="color: #3364d0" class="text-center display-2">
                Observación
              </p>
            </div>
            <div class="text-center">
              <p style="color: #3364d0" class="display-1 text-center">
                ¿Que solicitaba el cliente?
              </p>
              <p class="title">{{ request }}</p>
            </div>

            <div class="text-center">
              <p style="color: #3364d0" class="display-1 text-center">
                ¿Que hizo el Operador?
              </p>
              <p class="title">{{ action }}</p>
            </div>
            <div class="text-center">
              <p style="color: #3364d0" class="display-1 text-center">
                ¿Cual fue la solución?
              </p>
              <p class="title">{{ solution }}</p>
            </div>
          </v-container>
        </v-dialog>

        <v-dialog
          persistent
          hide-overlay
          :retain-focus="false"
          width="600"
          v-model="audio"
        >
          <v-container style="background: white">
            <div class="mb-5">
              <v-btn color="#3364d0" icon @click.stop="closeStop()"
                ><v-icon size="30">mdi-close</v-icon></v-btn
              >
              <p style="color: #3364d0" class="text-center display-2">
                Grabación
              </p>
            </div>
            <div class="d-flex justify-center mb-10">
              <mini-audio ref="audiio" :audio-source="recording"></mini-audio>
            </div>
          </v-container>
        </v-dialog>

        <v-dialog width="800" v-model="cont">
          <v-card>
            <v-btn color="black" icon @click="cont = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-container>
              <p class="display-1 text-center">Información Enviada</p>
              <div>
                <p class="title">Asunto:</p>
                <p v-if="solutionContact == null" class="subtitle-1">
                  Sin información
                </p>
                <p v-else>{{solutionContact.subject}}</p>
              </div>
              <div>
                <p class="title">Mensaje:</p>
                <p v-if="solutionContact == null" class="subtitle-1">
                  Sin información
                </p>
                <p v-else>{{solutionContact.message}}</p>
              </div>
            </v-container>
          </v-card>
        </v-dialog>
        <GrabacionesVideo />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { getAudioOrVideo } from "@/helpers/api/calls";
import GrabacionesVideo from "./GrabacionesVideo.vue";
import { mapState } from 'vuex';
// import { registroUser, getVideoCall } from "../../../helpers/api/calls";
export default {
  filters: {
    toDate: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },
    toHour: function (date) {
      if (!date) {
        return "-";
      }
      return moment(date).format("HH:mm");
    },
    Hour: function (date) {
      if (!date) {
        return "-";
      }
      return moment(date).format("HH:mm A");
    },
  },
  data() {
    return {
       solutionContact:[],
      cont: false,
      dateId: null,
      info: [],
      obs: false,
      audio: false,
      recording: null,
      detener: null,
      request: null,
      action: null,
      solution: null,
      headers: [
        {
          text: "Fecha y Hora",
          value: "created_at",
        },
        { text: "Tiempo", value: "tiempo" },
        { text: "Tipo de Atención", value: "tipoAtencion" },
        { text: "Profesional", value: "profesional" },
        { text: "Observacion", value: "observacion" },
      ],
    };
  },
  watch: {
    "attentionMonth"(newValue) {
      if (newValue) {
        // console.log(newValue)
        this.dateId = newValue;
        // console.log("AtencionesMensuales",this.dateId);
      }
    },
  },
  computed: {
    ...mapState('dashboard',["attentionMonth"])
  },
  methods: {
    getInfoContact(item) {
      // console.log(item)
      this.solutionContact = item.response
      this.cont = true;
    },
    async getAudioOrVideo(item) {
      // console.log("Information item",item.id);
      const information = await getAudioOrVideo({ id: item.id });
      // console.log(information.data);
      if (item.attentionable_type.slice(11) == "Callback") {
        this.recording = information.data.attentionable.recordings[0].url;
        this.audio = true;
      } else if (item.attentionable_type.slice(11) == "Videocall") {
        this.$store.commit(
          "setSrcVideoRecording",
          information.data.attentionable.recordings
        );
        this.$store.commit("setModalVideos", true);
      }
    },
    setInfo(item) {
      this.obs = true;
      this.request = item.request;
      this.action = item.action;
      this.solution = item.solution;
      // console.log("Dialog",item);
    },
    closeStop() {
      this.audio = false;
      this.$refs.audiio.stop();
      // console.log("Probando");
    },
  },
  components: { GrabacionesVideo },
};
</script>

<style>
.iconfont icon-speed-1 rate {
  display: none;
}
</style>
