<template>
  <!-- eslint-disable -->
  <v-container>
    <v-dialog width="1000" persistent v-model="this.$store.state.modalWhatsapp">
      <v-card>
        <v-container class="d-flex flex-columns">
          <div>
            <v-btn
              color="#2250b3"
              icon
              class="mt-2"
              @click="$store.commit('setModalWhatsapp', false)"
            >
              <v-icon size="30"> mdi-close </v-icon>
            </v-btn>
          </div>
          <div class="ml-5">
            <p
              style="color: #2250b3"
              class="mt-3 subtitle-1 font-weight-regular"
            >
              Whatsapp Enviados
            </p>
          </div>

          <!-- <div class="mx-auto">
            <v-text-field label="Buscar VideoEmail" class="rounded-lg" outlined>
            </v-text-field>
          </div> -->
        </v-container>
        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="list"
          disable-sort
          :items-per-page="5"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | toDate }} {{ item.created_at | toHour }}
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            {{ item.user_name }}
          </template>
          <!-- <template v-slot:[`item.email`]="{item}">
            {{item.client.email}}
          </template> -->
          <!-- <template v-slot:[`item.tipoAtencion`]="{ item }">
					Videollamadas
				</template> -->
          <template v-slot:[`item.receiver`]="{ item }">
            {{ item.receiver.replace("Num.", "&nbsp N°") }}
          </template>
          <!-- <template v-slot:[`item.id`]="{item}">
            <v-btn color="#2250b3" class="ml-n2" elevation="1" outlined icon @click="irVideoEmail(item)">
              <v-icon>mdi-email-fast</v-icon>
            </v-btn>

          </template> -->
          <template> </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { getWspReport } from "@/helpers/api/calls";
import { mapState } from "vuex";
export default {
  filters: {
    toDate: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },
    toHour: function (date) {
      if (!date) {
        return "-";
      }
      return moment(date).format("HH:mm A");
    },
  },
  data() {
    return {
      info: [],
      list: [],
      headers: [
        {
          text: "Fecha y Hora",
          value: "created_at",
        },
        { text: "Nombre Operador", value: "user_name" },
        { text: "Destino y numero", value: "receiver" },
      ],
    };
  },
  computed: {
    ...mapState("dashboard", ["wspSends"]),
  },
  watch: {
    wspSends(newValue, oldValue) {
      if (newValue) {
        this.list = newValue;
      }
    },
  },
};
</script>

<style></style>
