<template>
  <v-container class="px-0" fluid>
    <v-dialog persistent hide-overlay width="600" v-model="this.$store.state.modalMensaje">
      <v-card class="px-3">
        <v-row style="background: #2250b3">
          <v-col cols="10">
            <v-container class="pb-0 pt-5">
              <p style="color: white" class="title">
                respondiendo a : {{ userName }}
              </p>
            </v-container>
          </v-col>

          <v-col cols="2">
            <v-container>
              <v-btn @click="$store.commit('setModalMensaje', false)" color="white" class="mt-1" text>
                <v-icon size="35">mdi-close</v-icon>
              </v-btn>
            </v-container>
          </v-col>
        </v-row>
        <v-form class="mt-3" ref="formMensaje">
          <v-row class="mb-n8">
            <v-col class="px-0 py-0">
              <v-text-field single-line label="Asunto" outlined v-model="formMessage.subject" />
            </v-col>
          </v-row>
          <v-row style="border: solid 1px #9e9e9e" class="my-0">
            <v-col cols="2" class="py-0">
              <v-checkbox v-model="formMessage.refer" label="CC" />
            </v-col>
            <v-col class=" pr-3 pb-0" cols="10">
              <div>
                <v-text-field v-model="formMessage.referred" :disabled="formMessage.refer == false" class="mb-n4" dense
                  filled outlined label="Correo Electronico" />
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col class="px-0 pt-0 mb-n6">
              <v-textarea auto-grow single-line label="Mensaje" height="350" v-model="formMessage.message" outlined />
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="12">
            <div class="text-center mr-11">
              <v-btn @click="sendMessage(), $store.commit('setCardFormState', true)" style="border: solid 1px #81b322"
                color="#81B322" text width="150" height="40" class="ml-10 rounded-xl">Enviar</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar color="#2fa5ba" v-model="notify">
      <p class="text-center subtitle-1 font-weight-normal">Respuesta enviada</p>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  sendMessage,
  updateStatusEmail,
  postMsn,
} from "../../../../helpers/api/calls";
export default {
  data() {
    return {
      userName: "",
      id: null,
      sendMsn: false,
      notify: false,
      formMessage: {
        refer: false,
        referred: '',
        subject: "",
        message: "",
      },
    };
  },
  watch: {
    "$store.state.dateMensaje"(newValue, oldValue) {
      if (newValue) {
        this.id = newValue.contact_id;
        this.userName = newValue.client_name;
        console.log(newValue);
      }
    },
  },
  computed: {
    ...mapState("dashboard", ["messageContact"]),
  },
  methods: {
    ...mapActions("dashboard", ["getContact"]),
    clearData() {
      this.formMessage.subject = "";
      this.formMessage.message = "";
      this.formMessage.refer = false;
      this.formMessage.message = "";
    },

    async sendMessage() {
      const self = this;
      
      postMsn({
        id: this.id,
        subject: this.formMessage.subject,
        message: this.formMessage.message,
        refer: this.formMessage.refer,
        referred: this.formMessage.referred
      })
        .then((resp) => {
          console.log(resp);
          if (resp) {
            // this.updateStatusEmail();
            this.getContact();
            this.$refs.formMensaje.reset();
            this.$store.commit("setModalMensaje", false);
            this.clearData();
            self.notify = true;
          }
        })

        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>