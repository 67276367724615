<template>
  <v-container fluid style="background: #eaeef2; height: 100%">
    <div>
      <!-- <div class="d-flex flex-nowrap ma-5">
        <Drawer class="d-none d-sm-flex" />
        <DrawerMobile style="position:absolute;top:0px;left:0px"  class="d-flex d-sm-none"/>
      </div> -->
      <v-row :class="$vuetify.breakpoint.mobile ? 'ma-5':'ma-5 d-flex justify-space-between'">
        <v-col class="py-0" sm="8" cols="12">
            <CuentaPage/>
        </v-col>

        <v-col  cols="4">
          <DrawerRight class="d-none d-sm-flex"/>
        </v-col>
      </v-row>      
    </div>
  </v-container>
</template>

<script>
import Drawer from '../Drawer.vue'
import DrawerMobile from '../DrawerMobile.vue'
import DrawerRight from '../DrawerLateralDerecho/DrawerRight.vue'
import CuentaPage from '../StaticPages/Cuentas/CuentaPage.vue'
export default {
    components: {
        DrawerMobile,
        CuentaPage,
        Drawer,
        DrawerRight,
    },
    mounted () {
      this.$store.commit("setCardState", null);
    },
};
</script>

<style>
</style>