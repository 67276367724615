<template>
  <v-container class="py-3">
    <v-card width="100%">
      <v-container class="text-center">
        <p style="color: #2250b3" class="display-1">Administración</p>
      </v-container>

      <v-container>
        <v-row>
          <v-col md="12" lg="6" cols="12">
            <v-card
              style="border: solid 1px #2250b3"
              elevation="0"
            >
							<v-card-title class="my-0 py-1 d-flex justify-space-between" style="background-color: #2250b3">
								<p class="white--text mb-0 body-2 font-weight-bold">Números Telefónicos (Llamadas)</p>
								<v-btn @click="showNumbers = !showNumbers" icon x-small color="white">
									<v-icon>{{showNumbers ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text class="pa-0" v-show="showNumbers">
								<div class="py-2 px-3" v-if="loading">
									<v-skeleton-loader
										loading
										type="list-item-two-line"
									></v-skeleton-loader>
								</div>
								<v-list dense v-else>
									<v-list-item-group
												v-for="(pho, index) in phone"
												:key="index">
										<v-list-item two-line dense
												class="py-1"
												style="min-height: 40px">
											<v-list-item-content class="py-0">
												<v-list-item-title>{{ pho.user.name }}</v-list-item-title>
												<v-list-item-subtitle>{{ pho.number }}</v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-action class="my-0">
												<DeletePhone
													:update="getCompanyInfo"
													:phone="pho.number"
													:phoneName="pho.user.name"
													:phoneId="pho.id"
												/>
											</v-list-item-action>
										</v-list-item>
										<v-divider
											v-if="index < phone.length - 1"
											:key="index"
										></v-divider>
									</v-list-item-group>
								</v-list>
								<AddPhone :update="getCompanyInfo" />
							</v-card-text>
						</v-card>
          </v-col>
          <v-col md="12" lg="6" cols="12">
            <v-card
              style="border: solid 1px #2250b3"
              elevation="0"
            >
							<v-card-title class="my-0 py-1 d-flex justify-space-between" style="background-color: #2250b3">
								<p class="white--text mb-0 body-2 font-weight-bold">Correos Electrónicos</p>
								<v-btn @click="showEmails = !showEmails" icon x-small color="white">
									<v-icon>{{showEmails ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text class="pa-0" v-show="showEmails">
								<div class="py-2 px-3" v-if="loading">
									<v-skeleton-loader
										loading
										type="list-item-two-line"
									></v-skeleton-loader>
								</div>
								<v-list dense v-else>
									<v-list-item-group
												v-for="(email, index) in correos"
												:key="index">
										<v-list-item dense
												class="py-1"
												style="min-height: 40px">
											<v-list-item-content class="py-0">
												<v-list-item-title>{{ email.email }}</v-list-item-title>
											</v-list-item-content>
											<v-list-item-action class="my-0">
												<DeleteEmail
													:update="getCompanyInfo"
													:email="email.email"
													:emailID="email.id"
												/>
											</v-list-item-action>
										</v-list-item>
										<v-divider
											v-if="index < phone.length - 1"
											:key="index"
										></v-divider>
									</v-list-item-group>
								</v-list>
								<AddEmail :update="getCompanyInfo" />
							</v-card-text>
						</v-card>
          </v-col>
        </v-row>
      </v-container>
			<SetHorario />
			<CrudSpeciality/>
			<!-- <FAQAdmin /> -->
    </v-card>
  </v-container>
</template>

<script>
// import ChangePlan from "../MyDate/ChangePlan.vue";
import AddPhone from "./AddPhone.vue";
import DeletePhone from "./DeletePhone.vue";
import { getservicesCompany } from "@/helpers/api/calls";
import DeleteEmail from "./DeleteEmail.vue";
import AddEmail from "./AddEmail.vue";
import SetHorario from "./SetHorario.vue";
import CrudSpeciality from "./CrudSpeciality/index.vue"
import FAQAdmin from './Cati/FAQ'
export default {
  components: {
    /*ChangePlan,*/ AddPhone,
    DeletePhone,
    DeleteEmail,
    AddEmail,
    SetHorario,
    CrudSpeciality,
		FAQAdmin
  },
  data() {
    return {
      loading:true,
      InfoCompany: null,
      nameCompany: "",
      idPlan: null,
      phone: [],
      correos: [],
			showNumbers: false,
			showEmails: false
    };
  },
  watch: {
    "$store.state.userData"(newValue) {
      if (newValue) {
        this.userData();
      }
    },
  },
  methods: {
    async getCompanyInfo() {
      const myInfoCompany = await getservicesCompany({ id: this.info });
      this.InfoCompany = myInfoCompany.data;
      this.nameCompany = myInfoCompany.data.plan.name;
      this.idPlan = myInfoCompany.data.plan.id;
      this.phone = myInfoCompany.data.phone_numbers;
      this.correos = myInfoCompany.data.recipients;
      this.loading = false

      // console.log(myInfoCompany.data);
    },
    userData() {
      this.info = this.$store.state.userData.company.id;
      this.getCompanyInfo();
      
    },
  },
};
</script>

<style>
</style>