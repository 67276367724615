import { getUsers } from "@/helpers/api/calls";

export default {
  namespaced: true,
  state: {
    listUsers: [],
    modalCreate: false,
    modalDelete: false,
    modalUpdate: false,
    datosUpdate: [],
    datosElimiar: [],
  },
  mutations: {
    setListUsers(state, val) {
      state.listUsers = val;
    },
    setModalCreate(state, status) {
      state.modalCreate = status;
      console.log("ModalCreate", status);
    },
    setDatosElimnar(state, val) {
      state.datosElimiar = val;
    },
    setModalDelete(state, status) {
      state.modalDelete = status;
      // console.log("ModalDelete", status);
    },
    setModalUpdate(state, status) {
      state.modalUpdate = status;
      //   console.log("ModalUpdate", status);
    },
    setinfoUpdate(state, val) {
      state.datosUpdate = val;
    },
  },
  actions: {
    async pushUsers({ commit }) {
      const user = await getUsers();
      commit("setListUsers",user.data);
      console.log("LISTADO",user.data)
    },
  },
};
