var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.created_at))+" ")]}},{key:"item.paid_at",fn:function(ref){
var item = ref.item;
return [(item.paid_at != null)?_c('div',[_vm._v(" "+_vm._s(_vm._f("toDate")(item.paid_at))+" ")]):_c('div',[_c('p',{staticClass:"mt-3 font-weight-bold",staticStyle:{"color":"red"}},[_vm._v("No pagado")])])]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moreInformationModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Mas Información")])])],1)]}},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:(item.paid_at != null ? 'color:green' : 'color:red'),attrs:{"loading":_vm.loading,"disabled":item.paid_at != null ? true : false,"icon":""},on:{"click":function($event){return _vm.pagar(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-credit-card-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Pagar")])]),(_vm.poderPagarA != false)?_c('v-tooltip',{attrs:{"color":"warning","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.loading,"icon":"","color":"warning"},on:{"click":function($event){return _vm.openModalAdvance()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-star")])],1)]}}],null,true)},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v("Adelanta tu pago")])]):_vm._e()],1),_c('div')]}}],null,true)}),_c('MoreInformation',{staticClass:"d-none"}),_c('AdvancePayment',{staticClass:"d-none"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }