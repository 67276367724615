const endpoints ={
login: '/api/login',
postCompany:'/api/company',
userData: '/api/userData',
videollamada: '/api/calls/videoCall',
videoEmail: '/api/calls/videoMail',
registroUser: '/api/user/attentions',
UpdateInfoVideoCall: '/api/calls/videoCall',
dateUser:'/api/user/stats',
getVideoCall: '/api/calls/videoCall',
updateVideoMail: '/api/company',
rol:'/api/role',
//Informacion Cuadros
infoVideoEmail: '/api/calls/videoMail/stats',
getListVideoEmail: '/api/calls/videoMail',
getListVideoEmailDerivados:'/api/calls/videoMail/derived',
patchsetAnswered:'/api/attention/contact',
//CRUD PANEL
registerUser: 'api/user',
getUsers:'/api/user',
updateUser:'/api/user',
deleteUser:'/api/user',
speciality: '/api/speciality',
AddSpeciality:'/api/user/speciality',
getAttentions:'/api/company',

// Estado Usuarios
statusUser: '/api/user/statusUpdate',
//Panel CorreoEmail
getVideoEmail: '/api/calls/videoMail',
getVideoEmailID: '/api/calls/videoMail',
getVideoUser: '/api/calls/videoMail/publicUrl',
sendMessage: '/api/calls/videoMail/reply',
updateStatusEmail: '/api/calls/videoMail',
getMailRespondidos: '/api/calls/videoMail/resolved',
solutionCallback:'/api/resolution',
postWsp:'/api/whatsapp/sendWhatsapp',
getReportWsp:'/api/whatsapp/report',
getContact:'/api/attention/contact',


getAdminEsp:'/api/speciality',
AddAdminEsp:'/api/speciality',
deleteAdminEsp:'/api/speciality',
updateAdminEsp:'/api/speciality',

//PANEL WALLET
getInfoWallet: '/api/user',
getListBank: '/api/bank',
gettypeAccount: '/api/account_type',
postWithdrawal: '/api/withdrawal',

//Referido
getCodeReferido: 'api/user',

//ValidateServices
getPlan:'/api/plan',
getservicesCompany: '/api/company',
patchCompany: '/api/company',

//Administracion
addPhone:'/api/company/phoneNumber',
deletePhone:'/api/company/phoneNumber',
deleteEmail:'/api/company/recipient',
addEmail:'/api/company/recipient',

//GRABACIONES
getAudioOrVideo: '/api/resolution',

//Limit
limit: '/api/company/creditStatus',
getLimitCompany:'/api/limit',

/** Bolsas / Package */
getPackages: 'api/package',

setHours:'/api/company',
getAllInformation:'/api/resolution',
postMsn:'/api/attention/contact',


getToken: 'api/token',

fileUpload: 'api/calls/videoCall/{videoCallID}/storeAttachment',
getFilePublicUrl: 'api/attachments/'
}



export {endpoints}