<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | toDate }}
      </template>
      <template v-slot:[`item.paid_at`]="{ item }">
        <div v-if="item.paid_at != null">
          {{ item.paid_at | toDate }}
        </div>
        <div v-else>
          <p class="mt-3 font-weight-bold" style="color: red">No pagado</p>
        </div>
      </template>

      <template v-slot:[`item.info`]="{ item }">
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                @click="moreInformationModal(item)"
                icon
              >
                <v-icon>mdi-dots-horizontal-circle</v-icon>
              </v-btn>
            </template>
            <span>Mas Información</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.opciones`]="{ item }">
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :loading="loading"
                :disabled="item.paid_at != null ? true : false"
                :style="item.paid_at != null ? 'color:green' : 'color:red'"
                @click="pagar(item)"
                icon
              >
                <v-icon>mdi-credit-card-outline</v-icon>
              </v-btn>
            </template>
            <span>Pagar</span>
          </v-tooltip>

          <v-tooltip v-if="poderPagarA != false"  color="warning" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              @click="openModalAdvance()"
                v-bind="attrs"
                v-on="on"
                :loading="loading"
                icon
                color="warning"
              >
                <v-icon>mdi-star</v-icon>
              </v-btn>
            </template>
            <span class="subtitle-1 font-weight-bold">Adelanta tu pago</span>
          </v-tooltip>          
        </div>
        <div>

        </div>
      </template>
    </v-data-table>
    <MoreInformation class="d-none" />
    <AdvancePayment class="d-none"/>
  </v-container>
</template>

<script>
import { getInvoice, getPaymentInv } from "@/helpers/api/payments/payment";
import moment from "moment";
import { mapMutations, mapState } from "vuex";
import MoreInformation from "./MoreInformation.vue";
import AdvancePayment from "./AdvancePayment.vue";
export default {
  filters: {
    toDate: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  data() {
    return {
      idCompany: null,
      poderPagarA:false,
      loading: false,
      headers: [
        {
          text: "Fecha de cobro",
          value: "created_at",
          align: "center",
        },
        {
          text: "Fecha de pago",
          value: "paid_at",
          align: "center",
        },
        { text: "Total", value: "total", align: "center" },
        { text: "Asunto", value: "detalle", align: "center" },
        { text: "Mas Información", value: "info", align: "center" },
        { text: "Opciones", value: "opciones", align: "center" },
      ],
      items: [],
    };
  },
  watch: {
    userData(newValue) {
      // console.log(newValue)
      if (newValue) {
        this.idCompany = newValue.company_id;
        this.listInvoice();
      }
    },
  },
  computed: {
    ...mapState(["userData"]),
  },
  methods: {
    ...mapMutations("payPlan", ["setModalMoreInformation","setModalAdvance"]),

    openModalAdvance(){
      this.setModalAdvance(true)
    },

    moreInformationModal(item) {
      // console.log(item);
      this.setModalMoreInformation({
        status: true,
        information: item,
      });
    },

    async listInvoice() {
      const data = await getInvoice({
        id: this.$store.state.userData.company_id,
      });
      this.items = data.data.invoices;
      this.poderPagarA = data.data.upToDate
      console.log("listado", data);
    },

    async pagar(item) {
      this.loading = true;
      const response = await getPaymentInv({
        id: this.idCompany,
        idInvoice: item.id,
      });
      console.log("resp", response);
      const { flowData } = await response.data;
      if (["url", "token"].every((value) => value in flowData))
        window.location.href = `${flowData.url}?token=${flowData.token}`;
      this.loagin = false;
    },
  },
  components: { MoreInformation, AdvancePayment },
};
</script>

<style scoped>
</style>