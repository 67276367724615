<template>
  <v-container>
    <v-card>
      <v-row>
        <v-col  sm="7" md="7" lg="7" xl="7" cols="12">
          <v-container :class="$vuetify.breakpoint.mobile ? 'text-center':'ml-5'">
            <p
              class="title font-weight-regular mb-1"
              style="color: #feb62d; text-transform: capitalize"
            >
              {{ rol == "operator" ? 'Operador': rol }}
            </p>
            <p class="headline font-weight-bold mb-n1" style="color: #2250b3">
              Bienvenido
            </p>
            <p
              class="title font-weight-regular mb-1"
              style="color: #2250b3; text-transform: capitalize"
            >
              {{ user.name }}
            </p>
            <p class="caption font-weight-regular" style="color: #2250b3">
              Promedio de atenciones
            </p>
          </v-container>
        </v-col>
        <v-col cols="6" md="2" lg="2" xl="2">
          <v-container class="text-center">
            <v-icon size="40" color="#2250B3">mdi-account-multiple</v-icon>
            <p style="color: #2250b3" class="headline font-weight-bold mb-1">
              {{mes}}
            </p>
            <p style="color: #81b322" class="caption font-weight-regular mb-1">
              20
              <span
                ><v-icon color="#81B322" size="19"
                  >mdi-arrow-top-right</v-icon
                ></span
              >
            </p>
            <p style="color: #81b322" class="caption font-weight-regular">
              + que mes anterior
            </p>
          </v-container>
        </v-col>
        <v-col cols="6" md="3" lg="3" xl="3">
          <v-container class="text-center">
            <v-icon size="40" color="#2250B3">mdi-clock-outline</v-icon>
            <p style="color: #2250b3" class="headline font-weight-bold mb-1">
              {{ info.monthlyActiveTime  }} 
            </p>
            <p style="color: #e20d4e" class="caption font-weight-regular mb-1">
              20
              <span
                ><v-icon color="#E20D4E" size="19"
                  >mdi-arrow-bottom-right</v-icon
                ></span
              >
            </p>
            <p style="color: #e20d4e" class="caption font-weight-regular">
              - que mes anterior
            </p>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import { userData, dateUser } from "../../../helpers/api/calls";
export default {
  filters: {
    toDate: function(date) {
      return moment(date).format('mm:ss');;
    },
    toMes:function(date){
      return moment(date).format('hh')
    }
  },    
  data() {
    return {
      user: "",
      rol: "",
      info: "",
      mes:"",
    };
  },
  mounted() {
    // this.userData();
  },
watch: {
  '$store.state.userData'(newValue) {
    if(newValue)
    this.userData()
  }
},
  methods: {
    // async userData() {
    //   const user = await userData();
    //   this.user = user.data;
    //   this.rol = user.data.roles[0].name;
    //   
    //     // console.log("information",this.user);
    //     // console.log("Name Rol: ",this.rol);
    // },
    
    userData(){
      this.user = this.$store.state.userData
      this.rol = this.$store.state.userData.roles[0].name
      this.dateUser();
    },
    async dateUser() {
      const info = await dateUser({ id: this.user.id });
      this.info = info.data;
      this.mes = info.data.monthlyAttentions.length
      // console.log("info cuadro personal", this.info);
    },
  },
};
</script>

<style scoped>
</style>