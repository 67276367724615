<template>
  <v-container class="px-0" fluid>
    <v-dialog hide-overlay width="600" v-model="sendMsn">
      <v-card class="px-3">
        <v-row style="background: #2250b3">
          <v-col cols="10">
            <v-container class="pb-0 pt-5">
              <p style="color: white" class="title">respondiendo a : {{ userName }}</p>
            </v-container>
          </v-col>

          <v-col cols="2">
            <v-container>
              <v-btn @click="sendMsn = false" color="white" class="mt-1" text>
                <v-icon size="35">mdi-close</v-icon>
              </v-btn>
            </v-container>
          </v-col>
        </v-row>
        <v-row class="mb-n8">
          <v-col class="px-0 py-0">
            <v-text-field single-line label="Asunto" outlined v-model="formMessage.subject" />
          </v-col>
        </v-row>

        <v-row style="border: solid 1px #9e9e9e" class="my-0">
          <v-col cols="2" class="py-0">
            <v-checkbox v-model="formMessage.refer" label="CC" />
          </v-col>
          <v-col class=" pr-3 pb-0" cols="10">
            <div>
              <v-text-field v-model="formMessage.referred" :disabled="formMessage.refer == false" class="mb-n4" dense
                filled outlined label="Correo Electronico" />
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col class="px-0 pt-0 mb-n6">
            <v-textarea auto-grow single-line label="Mensaje" height="350" v-model="formMessage.message" outlined />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="text-center mr-11">
              <v-btn @click="sendMessage(), $store.commit('setCardFormState', true)" style="border: solid 1px #81b322"
                color="#81B322" text width="150" height="40" class="ml-10 rounded-xl">Enviar</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar color="#2fa5ba" v-model="notify">
      <p class="text-center subtitle-1 font-weight-normal">Respuesta enviada</p>
    </v-snackbar>
    <div>
      <div>
        <v-btn @click="sendMsn = true" color="#81B322" width="100%"
          :class="!$vuetify.breakpoint.xl ? 'rounded-lg ' : 'rounded-lg '" text style="border: solid 1px">Responder
        </v-btn>
      </div>
    </div>


  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { sendMessage, updateStatusEmail } from "../../../../helpers/api/calls";
export default {
  data() {
    return {
      cc: false,
      userName: '',
      sendMsn: false,
      notify: false,
      formMessage: {
        refer: false,
        referred: '',
        subject: "",
        message: "",
      },
    };
  },
  computed: {
    ...mapState(["infoUserEmail"]),
  },
  mounted() {
    // console.log("infoEmail", this.$store.state.infoUserEmail);
  },
  watch: {
    "$store.state.infoUserEmail"(newValue) {
      if (newValue) {
        // console.log("A",newValue)
        this.userName = newValue.client.name
        this.clrearData();
        // console.log("Limpiado");
      }
    },
  },

  methods: {
    ...mapActions(['getVideoEmaill'])
    ,
    clearData() {
      this.formMessage.subject = "";
      this.formMessage.message = "";
      this.formMessage.refer = false;
      this.formMessage.message = "";
    },

    async sendMessage() {
      const self = this;
      console.log({
        id: this.id,
        subject: this.formMessage.subject,
        message: this.formMessage.message,
        refer: this.formMessage.refer,
        referred: this.formMessage.referred
      });
      sendMessage({
        id: this.$store.state.infoUserEmail.id,
        subject: this.formMessage.subject,
        message: this.formMessage.message,
        refer: this.formMessage.refer,
        referred: this.formMessage.referred
      })
        .then((resp) => {
          // console.log(resp);
          if (resp) {
            this.updateStatusEmail();
            self.sendMsn = false;
            self.notify = true;
          }
        })

        .catch(function (err) {
          console.log(err);
        });
    },
    async updateStatusEmail() {
      const self = this
      updateStatusEmail({ id: this.$store.state.infoUserEmail.id, videomail_status_id: 3 })
        .then(function (resp) {
          console.log("Funciono status email", resp);
          console.log(resp);
        })
        .catch(function (err) {
          console.log("error", err);
        })
    }

  },
};
</script>

<style>
</style>