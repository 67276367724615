<template>
	<div>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(1)"
			@click="selectedTo = 1">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">1</v-chip>
			Hola, como estás? Mi nombre es ......, cual es tu nombre?</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(2)"
			@click="selectedTo = 2">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">2</v-chip>
			XXXX, antes de seguir me puedes dar tu número de teléfono, por favor, para devolverte el llamado  si se corta…y tu correo, por favor?</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(3)"
			@click="selectedTo = 3">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">3</v-chip>
			Muchas gracias.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(4)"
			@click="selectedTo = 4">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">4</v-chip>
			Bien! Entonces cuéntame un poco de ti XXXX, en qué situación laboral estás? Trabajando o sin trabajo.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(5)"
			@click="selectedTo = 5">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">5</v-chip>
			Ok, y que fue lo que captaste del vídeo?</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(6)"
			@click="selectedTo = 6">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">6</v-chip>
			XXXX, se explaya un poquito, o no entendió mucho.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(7)"
			@click="selectedTo = 7">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">7</v-chip>
			Perfecto, entonces vamos a contarte de que trata esta nueva forma de trabajar.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(8)"
			@click="selectedTo = 8">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">8</v-chip>
			Te gustaría trabajar desde tu casa y recibir comisión sobre todo lo que vendas, sin tener que invertir en productos, nada más en tiempo y lo mejor de todo sin tener que estar llamando a largas listas de prospectos, ni a tus amigos, ni a tus parientes.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(9)"
			@click="selectedTo = 9">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">9</v-chip>
			Esperar su respuesta… si esta es si, seguir y si tiene alguna consulta aclarar dudas y continuar.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(10)"
			@click="selectedTo = 10">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">10</v-chip>
			Muy bien, esta misma herramienta que estamos usando ahora para comunicarnos contigo, es la que usarías tu desde tu casa o desde donde quieras trabajar, solo necesitas un pc con conexión a internet, ok?</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(11)"
			@click="selectedTo = 11">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">11</v-chip>
			Y de la misma forma en que te contactamos a ti, contactamos a nuestros futuros clientes y cada persona interesada en lo que vea en la campaña y que de clic al igual que hiciste tú ahora, es derivada a un ejecutivo para que le aclare las dudas y cierre el negocio. </p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(12)"
			@click="selectedTo = 12">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">12</v-chip>
			Estamos claros hasta ahí. simple y fácil</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(13)"
			@click="selectedTo = 13">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">13</v-chip>
			Perfecto!</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(14)"
			@click="selectedTo = 14">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">14</v-chip>
			Te preguntarás que es lo que tengo que vender?.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(15)"
			@click="selectedTo = 15">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">15</v-chip>
			Bien, para iniciar será un sistema de vigilancia o monitoreo, llamado SOSclick, el cual está orientado a:</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(16)"
			@click="selectedTo = 16">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">16</v-chip>
			Comunicación y monitoreo de adultos mayores que viven solos o al cuidado de alguien.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(17)"
			@click="selectedTo = 17">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">17</v-chip>
			Comunicación y monitoreo de pequeños que están en casa al cuidado de otra persona o quedan solos en casa porque sus papas tuvieron que salir o están trabajando.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(18)"
			@click="selectedTo = 18">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">18</v-chip>
			Monitoreo y vigilancia de tu casa,  segunda vivienda o lugares de trabajo empresas u oficinas.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(19)"
			@click="selectedTo = 19">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">19</v-chip>
			Pero no te preocupes porque una vez decidas que quieres ser parte de este proyecto, tendrás toda la información y capacitación necesaria, para estar preparado y cerrar todos los negocios que quieras.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(20)"
			@click="selectedTo = 20">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">20</v-chip>
			Lo mismo con las comisiones, te enviare información para que la revises y aclares todas tus dudas antes de comenzar, eso si te digo que aquí si trabajas ganas y si no, por supuesto que no se recibe nada, simple y claro.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(21)"
			@click="selectedTo = 21">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">21</v-chip>
			Tienes alguna otra duda XXXX?.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(22)"
			@click="selectedTo = 22">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">22</v-chip>
			Perfecto XXXX, entonces enviare a tu correo la información para que la revises y agendemos una reunión por video conferencia.</p>
		<p class="mb-0 pa-1 single-container"
			:style="getBackgroundSelected(23)"
			@click="selectedTo = 23">
			<v-chip
      color="#2250b3"
			small
			text-color="white"
      class="ma-2">23</v-chip>
			Y agendamos para máximo 48 horas, después de eso la curva de interés vuelve a cero.</p>
	</div>
</template>

<script>
export default {
  data() {
    return {
			selectedTo: 0
    }
  },
	methods: {
		getBackgroundSelected(index){
			return this.selectedTo >= index ? 'background-color: #434343':''
		}
	}
}
</script>
<style scoped>
	.single-container:hover{
		background-color: #434343;
		cursor: pointer;
	}
</style>