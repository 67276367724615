<template>
  <v-container class="pa-0">
      <v-dialog width="700" v-model="delit">
          <v-container style="background:white">
              <div>
                  <v-btn color="#2250b3" icon @click="delit = false">
                      <v-icon size="30">
                          mdi-close
                      </v-icon>
                  </v-btn>
              </div>
              <div class="text-center">
                  <p style="color:#2250b3" class="display-1">¿Esta seguro de eliminar el siguiente Email?</p>
                  <div class="text-center">
                      <span class="title">{{this.nombre}}</span> <br>
                  </div>
              </div>

              <div class="d-flex justify-space-around mt-10">
                  <v-btn @click="delit = false" color="#2250b3" style="color:white" class="">Cancelar</v-btn>
                  <v-btn @click="eliminar()" style="color:white" color="red">Eliminar</v-btn>
              </div>
          </v-container>
      </v-dialog>
    <div>
      <v-snackbar :color="color" timeout="3000" v-model="msn" >
         <p style="color:white" class="text-center subtitle-1 font-weight-normal">{{text}}</p> 
      </v-snackbar>           
    </div>      
      <v-btn @click="delit = true" class="mt-n2" color="red" icon><v-icon>mdi-delete</v-icon></v-btn>
  </v-container>
</template>

<script>
import {deleteEmail} from '@/helpers/api/calls'
export default {
    props: {
        emailID: {
            type: Number,
            default: null
        },
        email:{
            type: String,
            default:null,
        },
        update:{
            type:Function,
            default:null,
        },


    },
    data() {
        return {
            color:null,
            text:null,
            msn: false,
            delit: false,
            id:this.emailID,
            nombre: this.email,
        }
    },
    methods: {
        async eliminar() {
            deleteEmail({id:this.emailID})
            .then((resp) => {
                if(resp){
                    this.delit = false
                    this.nombre = '',
                    this.update()
                    this.color = '#2250b3'
                    this.text = "Email Eliminado"
                    this.msn = true
                }
            }).catch((err) => {
                // console.log("No se pudo eliminar",err);
                if(err){
                    this.delit = false
                    this.color = 'red'
                    this.text = "No se pudo eliminar el Email, favor de reintentar"
                    this.msn = true
                }
            });
        }
    },

}
</script>

<style>

</style>