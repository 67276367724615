import {getContact} from '../../helpers/api/calls'
export default {
  namespaced: true,

  state: {
    // Atenciones
    modalOperador:false,
    attentionDay: [],
    attentionMonth: [],
    wspSends:[],
    messageContact:[],
    messageDerivado:[]
  },
  mutations: {
    setModalOperador(state,val){
      state.modalOperador = val
      console.log(val)
    },
    setMessageDerivado(state,val){
      state.messageDerivado = val
      console.log(val)
    },
    setMessageContact(state,val){
      state.messageContact = val
      // console.log("contact",val)
    },
    setWspSends(state,val){
      state.wspSends = val
      // console.log(val)
    },
    setAttentionDay(state, val) {
      state.attentionDay = val;
      // console.log(val)
    },
    setAttentionMonth(state, val) {
      state.attentionMonth = val;
      // console.log("mesVuex",val)
    },
  },
  actions: {
    async getContact({commit}){
      const data = await getContact({status:1})
      const data2 = await getContact({status:2})
      commit('setMessageContact',{
        data: data.data.reverse(),
        data2: data2.data.reverse()        
      })
    }
  },
};
