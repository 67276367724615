<template>
  <v-container>
    <v-dialog persistent width="550" v-model="this.$store.state.cardWallet">
      <v-container style="background:#eaeef2">
        <div>
          <v-btn
            color="#2250b3"
            icon
            @click="$store.commit('setCardWallet', false)"
          >
            <v-icon size="30">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="text-center">
          <p style="color:#2250b3" class="display-1">Mi Billetera</p>
        </div>

        <v-container>
          <div
            class="rounded-xl d-flex justify-center align-center flex-column"
            style="background:white;"
          >
            <p style="color:#2250b3" class="display-1 mt-4">${{fondo}}</p>
            <p style="color:#2250b3" class="caption">
              monto acumulado periodo 12/20 - 01/03
            </p>
          </div>
        </v-container>

        <div>
            <RetirarFondos :info="this.wallet" :updateWallet="getInfoWallet"/>
        </div>

        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <div class="d-flex justify-center">
                <v-btn color="#2250b3" elevation="0" outlined @click="option = 1" class="text-capitalize"
                  >Mis Ventas</v-btn
                >
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex justify-center">
                <v-btn color="#2250b3" elevation="0" outlined @click="option = 2" class="text-capitalize"
                  >Mis Comisiones</v-btn
                >
              </div>
            </v-col>
            <v-col cols="4">
              <div class="d-flex justify-center">
                <v-btn color="#2250b3" elevation="0" outlined @click="option = 3" class="text-capitalize"
                  >Mis Retiros</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-container>

        <div v-if="option == 1">
          <v-container>
            <p class="title" style="color:#2250b3">Mis Ventas</p>
          </v-container>

          <!-- <div
            v-for="(item, i) in 0"
            :key="i"
            class=" my-2 rounded-lg"
            style="background:white;"
          >
            <v-row class="my-1">
              <v-col class="py-0" cols="6">
                <v-container style="color:#2250b3" class="caption mb-0 pl-5">
                  <p class="mb-0 font-weight-bold">Venta</p>
                  <p class="mb-0">Jueves 20 de Diciembre 2020</p>
                  <p class="mb-0">Hora: 2:00pm</p>
                </v-container>
              </v-col>
              <v-col class="text-end py-0" cols="6">
                <v-container style="color:#2250b3" class="pr-5 ">
                  <p class="font-weight-bold mb-1">Total 15.000$</p>
                  <v-btn
                    elevation="0"
                    color="#2250b3"
                    style="color:white"
                    rounded
                  >
                    Pagado
                  </v-btn>
                </v-container>
              </v-col>
            </v-row>
          </div> -->
        </div>

        <div v-if="option == 2">
          <v-container>
            <p class="title" style="color:#2250b3">Mis Comisiones</p>
          </v-container>

          <div
            v-for="(trans, index) in transactions"
            :key="index"
            class=" my-2 rounded-lg"
            style="background:white;"
          >
            <v-row class="my-1">
              <v-col class="py-0" cols="6">
                <v-container style="color:#2250b3" class="caption mb-0 pl-5">
                  <p class="mb-0 font-weight-bold">Comisión</p>
                  <p class="mb-0">Fecha: {{trans.updated_at | toDate}}</p>
                  <p class="mb-0">Hora: {{trans.updated_at | toHour}}</p>
                  <p style="color:#feb62d" class="mb-0 font-weight-bold ">{{trans.wallet_transaction_type.name}}: {{trans.detail}}</p>

                </v-container>
              </v-col>
              <v-col class="text-end py-0" cols="6">
                <v-container style="color:#2250b3" class="pr-5">
                  <p class="font-weight-bold">Total ${{trans.amount}}</p>
                </v-container>
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-if="option == 3">
          <v-container>
            <p class="title" style="color:#2250b3">Mis Retiros</p>
          </v-container>

          <div
            v-for="(drawal, index) in withdrawals"
            :key="index"
            class=" my-2 rounded-lg"
            style="background:white;"
          >
            <v-row class="my-1">
              <v-col class="py-0" cols="6">
                <v-container style="color:#2250b3" class="caption mb-0 pl-5">
                  <p class="mb-0 font-weight-bold">Fondo Retirado</p>
                  <p class="mb-0">Fecha: {{drawal.created_at | toDate}}</p>
                  <p class="mb-0">Hora: {{drawal.created_at | toHour}}</p>
                </v-container>
              </v-col>
              <v-col class="text-end py-0" cols="6">
                <v-container style="color:#2250b3" class="pr-5">
                  <p class="font-weight-bold mb-1">Total ${{drawal.amount}}</p>
                  <v-btn
                    elevation="0"
                    color="#2250b3"
                    style="color:white"
                    rounded
                  >
                    {{drawal.withdrawal_status.name}}
                  </v-btn>                  
                </v-container>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-container>
    </v-dialog>
    <div>
      <div class="mb-n8 ml-n3">
        <v-btn
          text
          color="white"
          block
          large
          class="px-3 d-flex flex-row justify-start align-center"
          @click="$store.commit('setCardWallet', true),getInfoWallet()"
        >
          <v-icon color="#2250b3" class="mr-4">mdi-wallet</v-icon>
          <p style="color: #2250b3" class="mb-0 subtitle-1 text-capitalize">
            Billetera
          </p>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import RetirarFondos from "./Billetera/RetirarFondos.vue";
import {getInfoWallet} from '../../../helpers/api/calls'
export default {
    data() {
        return {
            option: 1,
            wallet:{},
            fondo:'',
            withdrawals:[],
            transactions:[],
        };
    },
    filters: {
        toHour:function(date){
          return moment(date).format('hh:mm A')
        },
        toDate:function(date){
          return moment(date).format('DD-MM-YYYY')
        },
            uppercase: function(v) {
      return v.toUpperCase();
    }
    },

    components: { RetirarFondos },
    methods: {
      async getInfoWallet() {
        const wallet = await getInfoWallet({id: this.$store.state.userData.id})
        // console.log("Walets",wallet)
        this.wallet = wallet.data.wallet
        this.fondo = wallet.data.wallet ? wallet.data.wallet.funds : 0
        this.withdrawals = wallet.data.wallet ? wallet.data.wallet.withdrawals : []
        this.transactions = wallet.data.wallet ? wallet.data.wallet.transactions : []

        // console.log("Probando el wallet: ", this.transactions)
        // console.log("Probando el wallet: ", this.wallet.funds)
        
      }
    },
};
</script>

<style></style>
