<template>
  <v-container fluid>
		<v-card
			style="border: solid 1px #2250b3"
			elevation="0"
		>
			<v-card-title class="my-0 py-1 d-flex justify-space-between" style="background-color: #2250b3">
				<div class="d-flex">
					<p class="white--text mb-0 body-2 font-weight-bold">Preguntas Frecuentes</p>
					<div class="mx-2 d-flex">
						<v-btn @click="updateCreateModalShow = !updateCreateModalShow" icon x-small color="white">
							<v-icon>mdi-plus-circle</v-icon>
						</v-btn>
					</div>
				</div>
				<v-btn @click="showContent = !showContent" icon x-small color="white">
					<v-icon>{{showContent ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="pa-0" v-show="showContent">
				<div class="py-2 px-3" v-if="loading">
					<v-skeleton-loader
						loading
						type="list-item-two-line"
					></v-skeleton-loader>
				</div>
				<v-expansion-panels accordion>
					<v-expansion-panel
						v-for="(faq, index) in faqs"
						:key="index"
					>
						<v-expansion-panel-header class="d-flex justify-space-between py-0" color="#e8e8e8">
							{{ faq.question }}
							<div class="d-flex align-end justify-end">
								<v-btn @click.stop="updateFAQ(faq)" class="mx-0" color="#2250b3" icon><v-icon>mdi-pencil-box</v-icon></v-btn>
								<v-btn @click="delit = true" class="mx-0" color="red" icon><v-icon>mdi-delete</v-icon></v-btn>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div v-html="faq.answer"></div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card-text>
		</v-card>
		<UpdateCreateModal
			v-if="updateCreateModalShow"
			:isNew="isNew"
			:updatingData="updatingData"
			:closeModal="closeUpdateCreateModal"/>
	</v-container>
</template>

<script>
import UpdateCreateModal from './UpdateCreateModal.vue'
export default {
	name: 'FAQAdmin',
	components: {UpdateCreateModal},
  data() {
		return{
			updateCreateModalShow: false,
			isNew: true,
			updatingData: null,
			loading: false,
			showContent: false,
			faqs: [
				{
					question: '¿Pregunta 1?',
					answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc gravida accumsan dui, quis aliquam eros placerat nec. Nam lacinia rutrum libero a congue. Vestibulum dictum viverra laoreet. Nam lacus purus, finibus eu gravida quis, euismod eu sapien. Donec pulvinar egestas consequat. Suspendisse pretium nisl id lectus consectetur lobortis. Aliquam malesuada justo mauris, vitae accumsan arcu convallis at. Integer risus nibh, vehicula maximus mauris vitae, consectetur porta lectus. Aenean sit amet ipsum lorem. In faucibus eleifend diam quis euismod. Cras nisl lectus, mattis at nisi sit amet, placerat porta leo. Mauris et suscipit arcu. Quisque mollis, mauris eget pulvinar ultrices, dolor tortor mollis nulla, viverra luctus turpis nulla id lectus. Quisque blandit consequat ligula, quis consequat velit consequat et. Suspendisse porta vitae leo id viverra. Nullam in hendrerit orci, nec molestie ex. Aliquam a rhoncus est, non vulputate mi.'
				}
			]
		}
	},
	methods: {
		updateFAQ(faq){
			this.updatingData = faq
			this.isNew = false
			this.updateCreateModalShow = true
		},
		closeUpdateCreateModal(){
			this.updateCreateModalShow = false
			this.updatingData = null
			this.isNew = true
		}
	}
}
</script>
<style scoped>
	/deep/ .answer-container p {
		margin-bottom: 0;
    overflow-wrap: break-word;
		white-space: normal;
	}
</style>