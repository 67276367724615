<template>
  <v-container
    class="fondoLogin d-flex align-center"
    style="height: 100%"
    fluid
  >
    <v-row style="z-index:2" justify="center">
      <v-col  cols="12" sm="8" md="5" lg="4">
        <v-card class="rounded-xl" elevation="0" style="background: #F7F6F4">
          <div class="pt-5 pb-5 d-flex justify-center">
            <v-img
              max-width="250"
              eager
              :src="require('../../assets/Click2Call.png')"
            />
          </div>
          <v-container>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="mx-auto"
            style="max-width: 400px"
          >
            <v-text-field
              background-color="white"
              outlined
              v-model="email"
              label="Correo"
              :rules="emailRules"
              color="#022D41"
              hide-details="auto"
              class="mb-4 rounded-lg"
            >
              <template v-slot:prepend-inner>
                <v-icon class="mt-n1" size="30" color="#2250B3"
                  >mdi-account</v-icon
                >
              </template>
            </v-text-field>

            <v-text-field
              background-color="white"
              outlined
              v-model="password"
              label="Contraseña"
              :rules="passwordRules"
              :type="showPass ? 'text' : 'password'"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              color="#022D41"
              hide-details="auto"
              class="login-inputs"
              @click:append="showPass = !showPass"
            >
              <template v-slot:prepend-inner>
                <v-icon class="mt-n1" size="30" color="#2250B3"
                  >mdi-lock-outline</v-icon
                >
              </template>
            </v-text-field>

            <div class="text-center mt-3">
              <p style="color: #2250b3" class="font-weight-Medium">
                Olvide mi contraseña
              </p>
            </div>
            <div>
              <v-alert
              class="text-center"
              color="#2250b3"
              style="color:white"
              :value="alert"
              >
              Email o contraseña incorrectos. Por favor verifica los datos ingresados.
              </v-alert>
            </div>

            <v-tab class="mt-10 pb-10 mx-auto">
              <v-btn
                height="50"
                class="rounded-lg"
                color="#2250B3"
                dark
                block
                depressed
                @click="loginUser"
              >
                <p class="mb-0 body-1">Ingresar</p>
              </v-btn>
            </v-tab>
          </v-form>

          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <div class="sol">
        <v-img
        style="margin-bottom:-40%;margin-left:-30%"
        eager
        :width="$vuetify.breakpoint.mobile ? '350':'800'"
        :src="require('../../assets/Sol.png')"
        ></v-img>
      </div>
  </v-container>
</template>

<script>
import { login } from "../../helpers/api/calls.js";
export default {
  name: "Login",
  data: () => ({
    alert: false,
    login: false,
    valid: true,
    showMessage: false,
    messageColor: "error",
    message: "",
    showPass: false,
    email: "",
    emailRules: [
      (v) => !!v || "Email es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Email debe ser válido",
    ],
    password: "",
    passwordRules: [(v) => !!v || "Contraseña es requerida"],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    loginUser() {
      if (this.$refs.form.validate()) {
        const self = this;
        this.loadingLogin = true;
        login(this.email, this.password)
          .then((resp) => {
            // console.log("resp", resp);
            if(resp.empresa == false){
              this.$router.push({ name: "ValidateServices" });
            }
            else{
              self.$router.push({ name: "DashBoard" });
              // self.$store.commit("setModalLogin", false);
              self.loadingLogin = false;
            }
          })
          .catch((error) => {
            //AVISAR SOBRE STATUS 422 DEBE SER STATUS DE AUTENTICACION
            if (error.response && error.response.status == 422) {
              this.alert = true
              setTimeout(() => {
                this.alert = false;
              }, 6000);
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.fondoLogin {
  /* background-image: url("../../assets/FondoLinea.svg"); */
  background: linear-gradient(#ffd255,#ffd255);
  /* background-color: #fec12d; */
  background-position: center;
  background-size: cover;
  height: auto;
}
.sol{
  position: absolute;
  overflow: hidden;
  z-index: 1;
  bottom: 0;
  left: 0;
}

</style>